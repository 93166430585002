import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './SingleTab.css';

const SingleTab = ({
  children, to, active, datatestid,
}) => (
  <li className="nav-item">
    <Link
      to={to}
      className={`general-single-horizontal-tab ${
        active ? 'single-horizontal-tab-active' : 'text-muted'
      }`}
      data-testid={datatestid}
    >
      {children}
    </Link>
  </li>
);

SingleTab.defaultProps = {
  active: false,
  datatestid: '',
};

SingleTab.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
  datatestid: PropTypes.string,
};

export default SingleTab;
