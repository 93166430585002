import { useState, useEffect, useCallback } from 'react';
import useQueryStringValue from './useQueryStringValue';
import { getLocalStorageValue, setLocalStorageValue } from './localStorage';

function useApplicationStateValue(key, initialValue = null) {
  const [queryStringValue, setQueryStringValue] = useQueryStringValue(key);
  const defaultValue = queryStringValue !== null
    ? queryStringValue : getLocalStorageValue(key) || initialValue;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (queryStringValue !== null && queryStringValue !== value) {
      setValue(queryStringValue);
    }
    // WARNING: React Hook useEffect has a missing dependency: 'value'.
  }, [queryStringValue, key]);  // eslint-disable-line

  useEffect(() => {
    setQueryStringValue(value);
    setLocalStorageValue(key, value);
  }, [value, key, setQueryStringValue]);

  const onSetValue = useCallback(
    (newValue) => {
      if (newValue !== null) {
        setValue(newValue);
      }
    },
    [setValue],
  );

  return [value, onSetValue];
}

export default useApplicationStateValue;
