import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../../resources/assets/search.svg';
import SuggestionListItem from './suggestion-components/SuggestionListItem';
import CurrentFilterItem from './suggestion-components/CurrentFilterItem';
import MType from '../typography/MType';
import {
  getFilterSuggestionsFromList,
  getFilterSuggestionsFromString,
} from '../../../resources/helpers/search';
import styles from './styles.module.scss';

const SuggestionTextBox = ({
  data,
  filters,
  activeFilters,
  setActiveFilters,
}) => {
  const [inputText, setInputText] = useState('');
  const [suggestionList, setSuggestionList] = useState([]);
  const [highlightOption, setHighlightOption] = useState(-1);
  const updateSuggestionsTimeout = useRef();

  const addToFilter = (newFilter) => {
    setInputText('');
    setHighlightOption(-1);
    setActiveFilters((allFilters) => [
      ...allFilters,
      { name: newFilter.filterObj.name, value: newFilter.value },
    ]);
  };

  const removeFromFilter = (content) => {
    setActiveFilters((currentArray) =>
      currentArray.filter((item) => item.value !== content),
    );
  };

  const suggestionsGen = useCallback(() => {
    if (updateSuggestionsTimeout.current) {
      clearTimeout(updateSuggestionsTimeout.current);
      updateSuggestionsTimeout.current = null;
    }

    setHighlightOption(-1);

    if (inputText.length < 3) {
      setSuggestionList([]);
    } else {
      updateSuggestionsTimeout.current = setTimeout(() => {
        if (inputText.length > 2) {
          let suggestionArray = [];
          const lowercaseInput = inputText.toLowerCase();

          filters.forEach((filterObj) => {
            data.forEach((row) => {
              const handlerValue = filterObj.handler(row);
              if (filterObj.list) {
                suggestionArray = getFilterSuggestionsFromList(
                  lowercaseInput,
                  handlerValue,
                  activeFilters,
                  filterObj,
                  suggestionArray,
                );
              } else {
                suggestionArray = getFilterSuggestionsFromString(
                  lowercaseInput,
                  handlerValue,
                  activeFilters,
                  filterObj,
                  suggestionArray,
                  row,
                );
              }
            });
          });
          setSuggestionList(suggestionArray);
        }
        updateSuggestionsTimeout.current = null;
      }, 300);
    }
  }, [inputText, data, filters, activeFilters]);

  const setHighlightOptionFunc = (index) => {
    if (suggestionList.length === 0) {
      return;
    }

    if (index < 0) {
      setHighlightOption(suggestionList.length - 1);
    } else if (index >= suggestionList.length) {
      setHighlightOption(0);
    } else {
      setHighlightOption(index);
    }
  };

  const onBlur = () => {
    setSuggestionList([]);
    setHighlightOption(-1);
  };

  const keydownHandler = (ev) => {
    const { keyCode } = ev;
    if (keyCode === 40) {
      ev.preventDefault();
      setHighlightOptionFunc(highlightOption + 1);
    } else if (keyCode === 38) {
      ev.preventDefault();
      setHighlightOptionFunc(highlightOption - 1);
    } else if (keyCode === 13 && highlightOption !== -1) {
      addToFilter(suggestionList[highlightOption]);
    }
  };

  useEffect(() => {
    suggestionsGen();
  }, [suggestionsGen]);

  const pasteWithCommaHandler = (ev) => {
    const pastedValue = ev.clipboardData.getData('text/plain');
    if (pastedValue && pastedValue.includes(',')) {
      const nameFilter = filters.find((val) => val.name === 'client');
      pastedValue
        .split(',')
        .filter((val) => !!val)
        .map((val) => val.trim())
        .forEach((val) => {
          for (let i = 0; i < data.length; i += 1) {
            const handlerValue = nameFilter.handler(data[i]);
            if (
              handlerValue &&
              handlerValue.toLowerCase() === val.toLowerCase() &&
              !activeFilters.find(
                (filter) =>
                  filter.value === handlerValue &&
                  filter.name === nameFilter.name,
              )
            ) {
              addToFilter({
                value: handlerValue,
                filterObj: nameFilter,
                id: data[i].id,
              });
              break;
            }
          }
        });
    }
  };

  return (
    <div className={styles.suggestionBox}>
      <div className="position-relative d-flex align-items-center fit-content">
        <img
          className="sm-icon low-contrast position-absolute my-auto"
          src={SearchIcon}
          alt="search icon"
        />
        {!!suggestionList.length && (
          <div
            data-testid="suggestion-box"
            className="bg-white border-2 border-light-grey custom-suggestion-box"
          >
            {suggestionList.map((obj, i) => (
              <SuggestionListItem
                key={`suggestionList-${obj.filterObj.name}-${obj.id}`}
                obj={obj}
                index={i}
                inputText={inputText}
                highlightOption={highlightOption}
                setHighlightOptionFunc={setHighlightOptionFunc}
                addToFilter={addToFilter}
              />
            ))}
          </div>
        )}
        <input
          data-testid="suggestion-text-box-input"
          className="border-light-grey border-2 border-rd-2 input-md outline txt-sz-m avenir-medium"
          type="text"
          value={inputText}
          autoComplete="off"
          placeholder="Search client names, package, DSS, etc"
          onBlur={onBlur}
          onChange={(ev) => setInputText(ev.target.value)}
          onPaste={pasteWithCommaHandler}
          onKeyDown={(ev) => keydownHandler(ev)}
          onFocus={suggestionsGen}
        />
      </div>
      {!!activeFilters.length && (
        <div className="mt-4 d-flex flex-wrap">
          {activeFilters.map((filter) => (
            <CurrentFilterItem
              key={`activeFilter${filter.name}${filter.value}`}
              filter={filter}
              removeFromFilter={removeFromFilter}
            />
          ))}
          <button
            data-testid="filters-clear-button"
            type="button"
            className="border-0 bg-white ml-2 mb-1"
            onClick={() => setActiveFilters([])}
          >
            <MType className="text-muted ">Clear</MType>
          </button>
        </div>
      )}
    </div>
  );
};

SuggestionTextBox.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, handler: PropTypes.func }),
  ).isRequired,
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, value: PropTypes.string }),
  ).isRequired,
  setActiveFilters: PropTypes.func.isRequired,
};

export default SuggestionTextBox;
