import React from 'react';
import PropTypes from 'prop-types';

function EditPen(props) {
  const { onClick = () => {}, className = '', fill = 'white' } = props;
  return (
    <svg
      width="24"
      height="24"
      aria-hidden="true"
      viewBox="0 0 512 512"
      data-icon="filter"
      onClick={onClick}
      data-testid="EditPen"
      className={className}
    >
      <path
        fill={fill}
        d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
      ></path>
    </svg>
  );
}

EditPen.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export { EditPen };
