import { format, subDays, parse } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ComparisonCard from 'components/common/cards/ComparisonCard';
import TenantPageBase from 'components/common/page-base/TenantPageBase';
import DateDisplay from 'components/DateDisplay';
import { InfoButton } from 'components/common/Buttons';
import { getPreviousPeriod, getPreviousYear } from 'utils/reporting';
import useApplicationStateValue from 'utils/useApplicationStateValue';
import Modal from 'components/common/Modal';
import DateRangePickerSecond from 'components/common/DateRangePicker';
import ComparisonLinesGraph from 'components/common/ComparisonLinesGraph';
import ComparisonPeriodSelector from 'components/common/ComparisonPeriodSelector';
import { Loader } from 'components/common';
import TotalRow from 'components/common/TotalRow';
import useWebTrafficData from './hooks/useWebTrafficData';
import {
  metricsKeysArray,
  getValueFromWebTrafficData,
  comparisonArray,
  comparisonPeriodOptions,
  graphComparisonPeriodOptions,
  allMetrics,
} from './constants';
import ChannelBreakdownTableColumns from './ChannelBreakdownTable/ChannelBreakdownTableColumns';
import ChannelBreakdownTable from './ChannelBreakdownTable';
import styles from './styles.module.scss';

const OverviewPage = () => {
  const [showModal, setShowModal] = useState(false);

  const [startDate, onSetStartDate] = useApplicationStateValue(
    'startDate',
    format(subDays(Date.now(), 30), 'yyyy-MM-dd'),
  );

  const [showPrevPeriod, onSetShowPrevPeriod] = useApplicationStateValue(
    'showPrevPeriod',
    true,
  );
  const [showPrevYear, onSetShowPrevYear] = useApplicationStateValue(
    'showPrevYear',
    true,
  );
  const [graphTopMetrics, onSetGraphTopMetrics] = useApplicationStateValue(
    'graphTopMetrics',
    'sessions,conversions',
  );
  const [graphBottomMetrics, onSetGraphBottomMetrics] = useApplicationStateValue(
    'graphBottomMetrics',
    'conversionRate,conversions',
  );

  const [endDate, onSetEndDate] = useApplicationStateValue(
    'endDate',
    format(Date.now(), 'yyyy-MM-dd'),
  );
  const [dates, setDates] = React.useState({
    startDate: parse(startDate, 'yyyy-MM-dd', new Date()),
    endDate: parse(endDate, 'yyyy-MM-dd', new Date()),
  });

  const [comparisonPeriodCheckboxValues, setComparisonPeriodCheckboxValues] = React.useState({
    showPrevPeriod,
    showPrevYear,
  });

  const [disabledApplyButton, setDisabledApplyButton] = React.useState();

  const previousYear = useMemo(
    () => getPreviousYear(startDate, endDate),
    [startDate, endDate],
  );
  const previousPeriod = useMemo(
    () => getPreviousPeriod(startDate, endDate),
    [startDate, endDate],
  );

  const { tenantid } = useParams();

  const [comparisonPeriod, setComparisonPeriod] = useState(
    comparisonPeriodOptions[1],
  );

  const [graphComparisonPeriod, setGraphComparisonPeriod] = useApplicationStateValue(
    'graphComparisonPeriod',
    graphComparisonPeriodOptions[0].value,
  );

  const handlePeriodChange = (selectedComparisonPeriod) => {
    if (selectedComparisonPeriod.value !== null) {
      setComparisonPeriod(selectedComparisonPeriod);
    }
  };
  
  const handlePeriodGraphChange = (selectedComparisonPeriod) => {
    if (selectedComparisonPeriod.value !== null) {
      setGraphComparisonPeriod(selectedComparisonPeriod.value);
    }
  };

  const {
    data, isLoading, error, graphData, groupedBy,
  } = useWebTrafficData({
    startDate,
    endDate,
  });

  const handleDateChange = (startDate, endDate) => {
    setDates({
      startDate,
      endDate,
    });
  };

  const handleCheckboxChange = (newCheckboxValues) => {
    setComparisonPeriodCheckboxValues(newCheckboxValues);
  };

  const onDatesApply = () => {
    setShowModal(false);
    onSetStartDate(format(dates.startDate, 'yyyy-MM-dd'));
    onSetEndDate(format(dates.endDate, 'yyyy-MM-dd'));
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    onSetShowPrevPeriod(comparisonPeriodCheckboxValues.showPrevPeriod);
    onSetShowPrevYear(comparisonPeriodCheckboxValues.showPrevYear);
    setComparisonPeriodCheckboxValues({
      showPrevPeriod: comparisonPeriodCheckboxValues.showPrevPeriod,
      showPrevYear: comparisonPeriodCheckboxValues.showPrevYear,
    });
  };

  const handleDateReset = () => {
    setDates({
      startDate: parse(startDate, 'yyyy-MM-dd', new Date()),
      endDate: parse(endDate, 'yyyy-MM-dd', new Date()),
    });
  };

  const handleDatePickerError = () => {
    setDisabledApplyButton(true);
  };

  const handleDatePickerErrorResolve = () => {
    setDisabledApplyButton(false);
  };

  const onMetricChange = ({ topMetrics, bottomMetrics }) => {
    const newTopMetrics = topMetrics.map((m) => m.name).join(',');
    const newBottomMetrics = bottomMetrics.map((m) => m.name).join(',');
    onSetGraphTopMetrics(newTopMetrics);
    onSetGraphBottomMetrics(newBottomMetrics);
  };

  const tableColumns = React.useMemo(
    () => ChannelBreakdownTableColumns(true, comparisonPeriod.value, ''),
    [comparisonPeriod],
  );

  return (
    <>
      <TenantPageBase>
        <div data-testid="Overview" className="main">
          <InfoButton
            label={`${format(
              parse(startDate, 'yyyy-MM-dd', new Date()),
              'MMM dd, yyyy',
            )} - ${format(
              parse(endDate, 'yyyy-MM-dd', new Date()),
              'MMM dd, yyyy',
            )}`}
            onClick={() => setShowModal((prevShowModal) => !prevShowModal)}
          />
          <div>
            <DateDisplay
              previousPeriod={previousPeriod}
              previousYear={previousYear}
            />
          </div>
          <Loader isLoading={isLoading} style={{ marginTop: '10rem' }}>
            <>
              {error && (
                <p className="text-danger">
                  There has been an error while loading the data. Please contact
                  your nearest Bacio person and hold on while we fix it.
                </p>
              )}
              <h2>Website Performance</h2>
              <div
                className={styles.overview}
                data-testid="overview-cards-container"
              >
                {tenantid
                  && !error
                  && !isLoading
                  && metricsKeysArray.map((metric) => (
                    <ComparisonCard
                      title={metric.title}
                      key={`ComparisonCard-${metric.key}`}
                      data={getValueFromWebTrafficData(data.rows, metric.key)}
                      compareArray={comparisonArray(data.rows, metric.key)}
                      increaseIsPositive={metric.increaseIsPositive}
                      dataIsPercentage={metric.dataIsPercentage}
                      dataIsTime={metric.dataIsTime}
                      dataIsDecimal={metric.dataIsDecimal}
                      changeInPercentage={metric.changeInPercentage}
                      monetary={metric.monetary}
                      displayPrevPeriod={showPrevPeriod}
                      displayPrevYear={showPrevYear}
                    />
                  ))}
              </div>
              <div>
                {tenantid && !error && !isLoading && (
                  <div>
                    <h3 className={styles.channelBreakdownTableTitle}>
                      Channel Breakdown
                    </h3>
                    <ComparisonPeriodSelector
                      className={styles.comparisonPeriodSelector}
                      options={comparisonPeriodOptions}
                      value={comparisonPeriod}
                      onChange={(selectedComparePeriod) => handlePeriodChange(selectedComparePeriod)}
                    />
                    <TotalRow
                      data={data.totalRowsData}
                      index="Channel Breakdown"
                      comparisonPeriod={comparisonPeriod.value}
                      groupedBy={groupedBy.value}
                      tableColumns={tableColumns}
                      comparisonPrefixes="supportChange"
                      nonTotalColumns={['channel']}
                    />
                    <ChannelBreakdownTable
                      id="table-ChannelBreakdown"
                      data={data}
                      comparisonPeriod={comparisonPeriod.value}
                      rowsPerPage={5}
                    />
                  </div>
                )}
              </div>
              <div className={styles.detailedMetricsTitleContainer}>
                <h3 className={styles.detailedMetricsTitle}>
                  Detailed Metrics
                </h3>
                <ComparisonPeriodSelector
                  options={graphComparisonPeriodOptions}
                  value={graphComparisonPeriodOptions.find(
                    (opt) => opt.value === graphComparisonPeriod,
                  )}
                  onChange={(selectedComparePeriod) => handlePeriodGraphChange(selectedComparePeriod)}
                />
              </div>
              {!isLoading && (
                <ComparisonLinesGraph
                  data={graphData.rows}
                  topMetrics={graphTopMetrics.split(',')}
                  bottomMetrics={graphBottomMetrics.split(',')}
                  onMetricChange={onMetricChange}
                  chooseMetricsValues={[{title:'Overview',values:allMetrics}]}
                  allMetrics={allMetrics}
                  prevValue={graphComparisonPeriodOptions.find(
                    (opt) => opt.value === graphComparisonPeriod,
                  )}
                />
              )}
            </>
          </Loader>
        </div>
      </TenantPageBase>

      {showModal && (
        <Modal
          title="Select Date Range"
          onClose={() => setShowModal(false)}
          bottomLeftButtons={[
            {
              label: 'Cancel',
              onClick: () => setShowModal(false),
            },
            { label: 'Reset', onClick: handleDateReset },
          ]}
          bottomRightButtons={[
            {
              label: 'Apply',
              onClick: onDatesApply,
              disabled: disabledApplyButton,
            },
          ]}
        >
          <DateRangePickerSecond
            startDate={dates.startDate}
            endDate={dates.endDate}
            onChange={handleDateChange}
            onChangeCheckbox={handleCheckboxChange}
            onError={handleDatePickerError}
            onErrorResolve={handleDatePickerErrorResolve}
            showPrevPeriod={comparisonPeriodCheckboxValues.showPrevPeriod}
            showPrevYear={comparisonPeriodCheckboxValues.showPrevYear}
          />
        </Modal>
      )}
    </>
  );
};

export default OverviewPage;
