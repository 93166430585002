import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from '../../../selectors/StatusSelectors';
import FullPageSpinner from '../../FullPageSpinner';

const tempStyling = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255,255,255,0.5)',
  zIndex: 999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const FullscreenIndicator = ({ actions }) => {
  const isLoading = useSelector(isLoadingSelector(actions));
  const [animSmoothing, setAnimSmoothing] = useState(true);
  const timeoutRef = useRef();

  useEffect(() => {
    if (timeoutRef.current && isLoading) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }

    if (animSmoothing && !isLoading) {
      timeoutRef.current = setTimeout(() => setAnimSmoothing(false), 400);
    } else if (!animSmoothing && isLoading) {
      setAnimSmoothing(true);
    }
  }, [isLoading, animSmoothing]);

  return !animSmoothing ? null : (
    <div style={tempStyling} data-testid="fullscreen-indicator">
      <FullPageSpinner />
    </div>
  );
};

FullscreenIndicator.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FullscreenIndicator;
