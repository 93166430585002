import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

import { sortBy } from '../utils/reporting';

function filterByPeriod(data, period) {
  return data ? data
    .filter((row) => row.date >= period.startDate && row.date <= period.endDate) : [];
}

export default function MetricsOverTime({
  data, period, metrics, comparisonPeriod, isDisplayed
}) {
  const chartData = filterByPeriod(data, period)
    .map((currentItem) => {
      const chartItem = { dateLabel: currentItem.date };
      metrics.forEach((metric) => {
        chartItem[metric.name] = currentItem[metric.name];
        if (comparisonPeriod === 'previousPeriod' && isDisplayed === true) {
          chartItem[`${metric.name}-prev`] = currentItem[`${metric.name}PrevPeriod`];
        }
        if (comparisonPeriod === 'previousYear' && isDisplayed === true) {
          chartItem[`${metric.name}-prev`] = currentItem[`${metric.name}PrevYear`];
        }
      });
      return chartItem;
    }).sort(sortBy('dateLabel'));

  const metricLines = metrics.reduce((lines, metric, index) => {
    const yAxis = index === 1 ? 'right' : 'left';
    const color = index === 1 ? 'DarkOrchid' : 'DarkCyan';
    lines.push(<Line yAxisId={yAxis} type="monotone" dataKey={metric.name} stroke={color} strokeWidth={2.5} />);
    lines.push(<Line yAxisId={yAxis} type="monotone" dataKey={`${metric.name}-prev`} stroke={color} strokeWidth={0.5} />);
    return lines;
  }, []);

  const legendLabelFormatter = (name) => {
    const { label } = metrics.find((m) => m.name === name.replace(/-prev$/, ''));
    if (comparisonPeriod === 'previousPeriod') {
      return name.endsWith('-prev') ? `Prev. Period ${label}` : label;
    }
    if (comparisonPeriod === 'previousYear') {
      return name.endsWith('-prev') ? `Prev. Year ${label}` : label;
    }

    return name.endsWith('-prev') ? `Prev. Period ${label}` : label;
  };

  const tooltipLabelFormatter = (value, name) => {
    const metric = metrics.find((m) => m.name === name.replace(/-prev$/, ''));
    return ['formatter' in metric ? metric.formatter(value) : value, legendLabelFormatter(name)];
  };

  const legend = (
    <div className="row">
      {
      metrics.map((metric, index) => {
        const align = index === 1 ? 'right' : 'left';
        const color = index === 1 ? 'DarkOrchid' : 'DarkCyan';
        return (
          <div className="col" style={{ textAlign: align, color }}>
            <p>
              <svg width="24" height="16">
                <rect width="16" y="-2" height="16" fill={color} />
              </svg>
              <span>{metric.label}</span>
            </p>
          </div>
        );
      })
    }
    </div>
  );

  if (metrics && metrics.length) {
    const firstMetric = metrics[0];
    const secondMetric = metrics.length > 1 ? metrics[1] : null;

    const axes = [
      <YAxis yAxisId="left" tickFormatter={metrics.length && 'formatter' in firstMetric ? firstMetric.formatter : (v) => (v)} />,
    ];

    if (secondMetric) {
      axes.push(
        <YAxis yAxisId="right" orientation="right" tickFormatter={metrics.length && 'formatter' in secondMetric ? secondMetric.formatter : (v) => (v)} />,
      );
    }

    return (
      <ResponsiveContainer id="metrics-over-time" width="100%" height={400}>
        <LineChart data={chartData}>
          <XAxis dataKey="dateLabel" />
          {axes}
          <Tooltip formatter={tooltipLabelFormatter} />
          <Legend verticalAlign="top" content={legend} />
          {metricLines}
        </LineChart>
      </ResponsiveContainer>
    );
  }
  return <span>Select at least one metric.</span>;
}

const periodPropType = PropTypes.shape({
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
});

MetricsOverTime.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ date: PropTypes.string })).isRequired,
  period: periodPropType.isRequired,
  comparisonPeriod: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  metrics: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    aggregationMethod: PropTypes.string,
    formatter: PropTypes.function,
  })).isRequired,
};
