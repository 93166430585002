import React from 'react';
import { Spinner } from 'react-bootstrap';
import './FullPageSpinner.css';

function FullPageSpinner() {
  return (
    <div className="fullPageSpinner d-flex justify-content-center align-items-center">
      <Spinner animation="border" />
    </div>
  );
}

export default FullPageSpinner;
