import { getAccessToken } from './authentication';

export const getAuthHeader = async () => {
  const authToken = await getAccessToken();
  return { Authorization: `Bearer ${authToken}` };
};

export const addParams = (url, startDate, endDate, groupBy = null, dimensions = null) => {
  let params = '';

  if (startDate && endDate) {
    params += `filter[date]=gt,${startDate}&filter[date]=lt,${endDate}`;
  }

  if (groupBy) {
    params += `${params ? '&' : ''}groupBy=${groupBy}`;
  }

  if (dimensions) {
    params += `${params ? '&' : ''}dimensions=${dimensions}`;
  }

  const returnUrl = params ? `${url}?${params}` : url;

  return returnUrl;
};
