export const userActionTypes = {
  fetchUser: 'user/fetchUser',
  fetchUserRequest: 'user/fetchUserRequest',
  fetchUserError: 'user/fetchUserError',
  fetchUserSuccess: 'user/fetchUserSuccess',

  loginUser: 'user/loginUser',
  loginUserRequest: 'user/loginUserRequest',
  loginUserError: 'user/loginUserError',
  loginUserSuccess: 'user/loginUserSuccess',

  signOut: 'user/signOut',
  signOutRequest: 'user/signOutRequest',
  signOutError: 'user/signOutError',
  signOutSuccess: 'user/signOutSuccess',
};

export const tenantActionTypes = {
  fetchAll: 'tenant/fetchAll',
  fetchAllRequest: 'tenant/fetchAllRequest',
  fetchAllError: 'tenant/fetchAllError',
  fetchAllSuccess: 'tenant/fetchAllSuccess',

  fetchTenantRequest: 'tenant/fetchTenantRequest',
  fetchTenantError: 'tenant/fetchTenantError',
  fetchTenantSuccess: 'tenant/fetchTenantSuccess',
  fetchTenant: 'tenant/fetchTenant',

  fetchGroupedTenantsRequest: 'tenant/fetchGroupedTenantsRequest',
  fetchGroupedTenantsError: 'tenant/fetchGroupedTenantsError',
  fetchGroupedTenantsSuccess: 'tenant/fetchGroupedTenantsSuccess',
  fetchGroupedTenants: 'tenant/fetchGroupedTenants',
};

export const campaignsActionTypes = {
  fetchCampaignsRequest: 'campaigns/fetchCampaignsRequest',
  fetchCampaignsError: 'campaigns/fetchCampaignsError',
  fetchCampaignsSuccess: 'campaigns/fetchCampaignsSuccess',
  fetchCampaign: 'campaigns/fetchCampaign',
};
