import React from 'react';
import { getCampaignsReports } from 'api';
import reportTypes from 'api/reportTypes';
import { useParams } from 'react-router-dom';
import { getUserInfo } from 'selectors/UserSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { getCampaignReport } from 'selectors/CampaignSelector';
import { fetchCampaignsAction } from '../../../actions/CampaignsActions';
import { getSumData, getRelativeChange } from '../../../utils/reporting';

const metricSumColumns = [
  'clicks',
  'impressions',
  'spend',
  'calls',
  'conversions',
  'campaignCount',
  'clicksPrevPeriod',
  'clicksPrevYear',
  'impressionsPrevPeriod',
  'impressionsPrevYear',
  'spendPrevPeriod',
  'spendPrevYear',
  'callsPrevPeriod',
  'callsPrevYear',
  'conversionsPrevPeriod',
  'conversionsPrevYear',
  'campaignCountPrevPeriod',
  'campaignCountPrevYear',
];

function getFilteredData(data, item) {
  const column = item;
  const filteredArray = data.filter((element) => element[column] >= 0);
  return filteredArray;
}

function getDataTotals(data) {
  const totals = {};
  if (data.rows.length > 0) {
    metricSumColumns.forEach((item) => {
      totals[item] = getSumData(getFilteredData(data.rows, item), item);
    });
  }

  if (totals.clicks && totals.impressions) {
    totals.clickThroughRate = totals.clicks / totals.impressions;
  }

  if (totals.clicksPrevPeriod && totals.impressionsPrevPeriod) {
    totals.clickThroughRatePrevPeriod = totals.clicksPrevPeriod / totals.impressionsPrevPeriod;
  }

  if (totals.clicksPrevYear && totals.impressionsPrevYear) {
    totals.clickThroughRatePrevYear = totals.clicksPrevYear / totals.impressionsPrevYear;
  }

  if (totals.clickThroughRate && totals.clickThroughRatePrevPeriod) {
    totals.clickThroughRateRelativeChangePrevPeriod = getRelativeChange(
      totals.clickThroughRate,
      totals.clickThroughRatePrevPeriod,
    );
  }

  if (totals.clickThroughRate && totals.clickThroughRatePrevYear) {
    totals.clickThroughRateRelativeChangePrevYear = getRelativeChange(
      totals.clickThroughRate,
      totals.clickThroughRatePrevYear,
    );
  }

  if (totals.spend && totals.clicks) {
    totals.avgCostPerClick = totals.spend / totals.clicks;
  }

  if (totals.spendPrevPeriod && totals.clicksPrevPeriod) {
    totals.avgCostPerClickPrevPeriod = totals.spendPrevPeriod / totals.clicksPrevPeriod;
  }

  if (totals.spendPrevYear && totals.clicksPrevYear) {
    totals.avgCostPerClickPrevYear = totals.spendPrevYear / totals.clicksPrevYear;
  }

  if (totals.avgCostPerClick && totals.avgCostPerClickPrevPeriod) {
    totals.avgCostPerClickRelativeChangePrevPeriod = getRelativeChange(
      totals.avgCostPerClick,
      totals.avgCostPerClickPrevPeriod,
    );
  }

  if (totals.avgCostPerClick && totals.avgCostPerClickPrevYear) {
    totals.avgCostPerClickRelativeChangePrevYear = getRelativeChange(
      totals.avgCostPerClick,
      totals.avgCostPerClickPrevYear,
    );
  }

  if (totals.conversions && totals.clicks) {
    totals.conversionRate = totals.conversions / totals.clicks;
  }

  if (totals.conversionsPrevPeriod && totals.clicksPrevPeriod) {
    totals.conversionRatePrevPeriod = totals.conversionsPrevPeriod / totals.clicksPrevPeriod;
  }

  if (totals.conversionsPrevYear && totals.clicksPrevYear) {
    totals.conversionRatePrevYear = totals.conversionsPrevYear / totals.clicksPrevYear;
  }

  if (totals.conversionRate && totals.conversionRatePrevPeriod) {
    totals.conversionRateRelativeChangePrevPeriod = getRelativeChange(
      totals.conversionRate,
      totals.conversionRatePrevPeriod,
    );
  }

  if (totals.conversionRate && totals.conversionRatePrevYear) {
    totals.conversionRateRelativeChangePrevYear = getRelativeChange(
      totals.conversionRate,
      totals.conversionRatePrevYear,
    );
  }

  if (totals.clicks && totals.clicksPrevPeriod) {
    totals.clicksRelativeChangePrevPeriod = getRelativeChange(
      totals.clicks,
      totals.clicksPrevPeriod,
    );
  }

  if (totals.clicks && totals.clicksPrevYear) {
    totals.clicksRelativeChangePrevYear = getRelativeChange(
      totals.clicks,
      totals.clicksPrevYear,
    );
  }

  if (totals.spend && totals.spendPrevPeriod) {
    totals.spendRelativeChangePrevPeriod = getRelativeChange(
      totals.spend,
      totals.spendPrevPeriod,
    );
  }

  if (totals.spend && totals.spendPrevYear) {
    totals.spendRelativeChangePrevYear = getRelativeChange(
      totals.spend,
      totals.spendPrevYear,
    );
  }

  if (totals.impressions && totals.impressionsPrevPeriod) {
    totals.impressionsRelativeChangePrevPeriod = getRelativeChange(
      totals.impressions,
      totals.impressionsPrevPeriod,
    );
  }

  if (totals.impressions && totals.impressionsPrevYear) {
    totals.impressionsRelativeChangePrevYear = getRelativeChange(
      totals.impressions,
      totals.impressionsPrevYear,
    );
  }

  if (totals.calls && totals.callsPrevPeriod) {
    totals.callsRelativeChangePrevPeriod = getRelativeChange(
      totals.calls,
      totals.callsPrevPeriod,
    );
  }

  if (totals.calls && totals.callsPrevYear) {
    totals.callsRelativeChangePrevYear = getRelativeChange(
      totals.calls,
      totals.callsPrevYear,
    );
  }

  if (totals.conversions && totals.conversionsPrevPeriod) {
    totals.conversionsRelativeChangePrevPeriod = getRelativeChange(
      totals.conversions,
      totals.conversionsPrevPeriod,
    );
  }

  if (totals.conversions && totals.conversionsPrevYear) {
    totals.conversionsRelativeChangePrevYear = getRelativeChange(
      totals.conversions,
      totals.conversionsPrevYear,
    );
  }

  if (totals.campaignCount && totals.campaignCountPrevPeriod) {
    totals.campaignCountRelativeChangePrevPeriod = getRelativeChange(
      totals.campaignCount,
      totals.campaignCountPrevPeriod,
    );
  }

  if (totals.campaignCount && totals.campaignCountPrevYear) {
    totals.campaignCountRelativeChangePrevYear = getRelativeChange(
      totals.campaignCount,
      totals.campaignCountPrevYear,
    );
  }

  return totals;
}

const useCampaignsData = ({
  startDate, endDate, isDataGrouped, groupedBy,
}) => {
  const user = useSelector(getUserInfo);
  const { tenantid } = useParams();
  const [data, setData] = React.useState({
    rows: [],
  });
  const [graphData, setGraphData] = React.useState({
    rows: [],
  });
  const [nonGroupedData, setNonGroupedData] = React.useState({
    rows: [],
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();
  const reduxData = useSelector(
    getCampaignReport(tenantid, 'adNetwork,campaignName'),
  );
  const reduxGraphData = useSelector(getCampaignReport(tenantid, 'date'));
  const reduxGraphDataByPlatform = useSelector(
    getCampaignReport(tenantid, 'platform,date'),
  );
  const reduxTotalRowData = useSelector(getCampaignReport(tenantid, groupedBy));

  React.useEffect(() => {
    if (tenantid && startDate && endDate) {
      setIsLoading(true);
      setError(null);
      Promise.all([
        getCampaignsReports(
          tenantid,
          reportTypes.campaigns.base,
          {
            startDate,
            endDate,
          },
          `${groupedBy},campaignName`,
        ),
        getCampaignsReports(
          tenantid,
          reportTypes.campaigns.base,
          {
            startDate,
            endDate,
          },
          `${groupedBy}`,
        ),
      ])
        .then(() => {
          dispatch(
            fetchCampaignsAction(
              tenantid,
              reportTypes.campaigns.base,
              { startDate, endDate },
              'adNetwork,campaignName',
            ),
          );
          dispatch(
            fetchCampaignsAction(
              tenantid,
              reportTypes.campaigns.base,
              { startDate, endDate },
              'adNetwork',
            ),
          );
          dispatch(
            fetchCampaignsAction(
              tenantid,
              reportTypes.campaigns.base,
              { startDate, endDate },
              'platform',
            ),
          );
          dispatch(
            fetchCampaignsAction(
              tenantid,
              reportTypes.campaigns.base,
              { startDate, endDate },
              'platform,date',
            ),
          );
          dispatch(
            fetchCampaignsAction(
              tenantid,
              reportTypes.campaigns.base,
              { startDate, endDate },
              'date',
            ),
          );
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, [tenantid, user.accessToken, startDate, endDate, dispatch]); // eslint-disable-line

  React.useEffect(() => {
    if (
      tenantid
      && reduxData.data
      && reduxTotalRowData.data
    ) {
      const platformData = reduxData.data.rows.map((item) => ({
        ...item,
        platform: item.adNetwork === 'Facebook' ? 'Facebook' : 'Google',
      }));
      setData({
        ...platformData,
        rows: platformData.sort((a, b) => (a.spend < b.spend ? 1 : -1)),
        totalRowsData: {
          ...reduxTotalRowData.data,
          rows: reduxTotalRowData.data.rows,
        },
      });
      setNonGroupedData({
        ...reduxData.data.rows,
        rows: reduxData.data.rows.sort((a, b) => (a.spend < b.spend ? 1 : -1)),
        totalRowsData: {
          ...reduxData.data,
          rows: [{ ...getDataTotals(reduxData.data), table: 'Campaigns' }],
        },
      });
      setIsLoading(false);
    }
  }, [
    reduxData,
    reduxTotalRowData,
    setData,
    tenantid,
    isDataGrouped,
  ]);

  React.useEffect(() => {
    const transformKeys = (arr, platform) => arr
      .filter((r) => r.platform === platform)
      .reduce((acc, cur) => {
        const newRow = {};

        Object.entries(cur).map((k) => {
          const [key, value] = k;
          newRow[`${cur.platform}-${key}`] = value;
          return null;
        });

        acc.push(newRow);
        return acc;
      }, []);

    if (reduxGraphData.data && reduxGraphDataByPlatform.data) {
      const googleRows = transformKeys(
        reduxGraphDataByPlatform.data.rows,
        'Google',
      );

      const facebookRows = transformKeys(
        reduxGraphDataByPlatform.data.rows,
        'Facebook',
      );

      const platformData = reduxGraphData.data.rows.map((item, i) => ({
        ...item,
        ...facebookRows[i],
        ...googleRows[i],
        date: new Date(`${item.date} 00:00:00`).getTime(),
      }));

      setGraphData({ data: { rows: platformData } });
    }
  }, [reduxGraphData, reduxGraphDataByPlatform]);

  return [data, nonGroupedData, isLoading, error, graphData];
};

export default useCampaignsData;
