import ComparisonCell from 'components/common/table/cells/ComparisonCell';
import { BoldMCellWithToolTip } from 'components/common/table/cells/MTypeCells';
import { formatSeconds, formatCurrency, formatLocalizedNumber } from '../../../utils/reporting';
import { comparisonPeriods } from '../constants';

export const SUPP_PREFIX = 'supportChange';
const PERCENTAGE_STRING = 'percentage';
const ABSOLUTE_STRING = 'absolute';
const PREV_YEAR_STRING = 'PrevYear';
const PREV_PERIOD_STRING = 'PrevPeriod';

const changesSuffix = {
  [PERCENTAGE_STRING + PREV_PERIOD_STRING]: 'RelativeChangePrevPeriod',
  [PERCENTAGE_STRING + PREV_YEAR_STRING]: 'RelativeChangePrevYear',
  [ABSOLUTE_STRING + PREV_PERIOD_STRING]: 'AbsoluteChangePrevPeriod',
  [ABSOLUTE_STRING + PREV_YEAR_STRING]: 'AbsoluteChangePrevYear',
};

/**
 * @param {boolean} isPercentage
 * @param {string} comparisonPeriod
 * @param {string} removeColumns it contents all the keys that we want to remove
 * @returns {array} relevant columns
 */
const ChannelBreakdownTableColumns = (
  isPercentage,
  comparisonPeriod,
  removeColumns,
) => {
  const baseColumns = [
    {
      Header: 'channel',
      label: '',
      accessor: (a) => a.channel,
      Component: BoldMCellWithToolTip,
      sortType: 'basic',
    },
    {
      Header: 'sessions',
      label: 'Sessions',
      accessor: (a) => a.sessions,
      formatting: formatLocalizedNumber,
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'conversions',
      label: 'Conversions',
      accessor: (a) => a.conversions,
      formatting: formatLocalizedNumber,
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'newUsers',
      label: 'New Users',
      accessor: (a) => a.newUsers,
      formatting: formatLocalizedNumber,
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'pageviewsPerSession',
      label: 'Pages / Session',
      accessor: (a) => (a.pageviewsPerSession ? a.pageviewsPerSession.toFixed(2) : 0),
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'pageviews',
      label: 'Pageviews',
      accessor: (a) => a.pageviews,
      formatting: formatLocalizedNumber,
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'bounceRate',
      label: 'Bounce Rate',
      accessor: (a) => `${((a.bounceRate ? a.bounceRate : 0) * 100).toFixed(2)}%`,
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
      supportValues: {
        increaseIsPositive: false,
        monetary: false,
      },
    },
    {
      Header: 'avgSessionDuration',
      label: 'Avg. Session Duration',
      accessor: (a) => formatSeconds(a.avgSessionDuration),
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'conversionRate',
      label: 'Conv. Rate',
      supportValues: {
        increaseIsPositive: true,
        monetary: false,
      },
      accessor: (a) => `${((a.conversionRate ? a.conversionRate : 0) * 100).toFixed(2)}%`,
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'avgDailyCost',
      label: 'Avg Spend / Day',
      supportValues: {
        increaseIsPositive: false,
      },
      accessor: (a) => a.avgDailyCost,
      formatting: (a) => formatCurrency(a),
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'campaignCount',
      label: 'Active Campaigns',
      accessor: (a) => (a.campaignCount ? a.campaignCount : 0),
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
  ].filter((column) => removeColumns !== column.Header);

  const changeColumns = baseColumns
    .map((column) => {
      if (!column.changeColumn) return undefined;

      return {
        Header: `${SUPP_PREFIX}${column.Header}`,
        label: `label${SUPP_PREFIX}${column.Header}`,
        accessor: (a) => a[
          `${column.Header}${
            changesSuffix[
              (isPercentage ? PERCENTAGE_STRING : ABSOLUTE_STRING)
                  + (comparisonPeriod === comparisonPeriods.prevYear
                    ? PREV_YEAR_STRING
                    : PREV_PERIOD_STRING)
            ]
          }`
        ],
        supportColumn: true,
        sortType: 'basic',
      };
    })
    .filter((value) => !!value);

  return [...baseColumns, ...changeColumns];
};

export default ChannelBreakdownTableColumns;
