import React from 'react';
import Select from 'components/common/Select';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function ComparisonPeriodSelector({ className = '', options, value, onChange }) {
  return (
    <div className={cx(styles.comparisonPeriodSelectorContainer, className)}>
      <span>Compare To:</span>
      <Select
        className={styles.comparisonPeriodSelector}
        options={options}
        value={value}
        data-testid="comparisonPeriodSelector"
        onChange={onChange}
      />
    </div>
  );
}

ComparisonPeriodSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default ComparisonPeriodSelector;
