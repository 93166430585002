import ComparisonCell from 'components/common/table/cells/ComparisonCell';
import { BoldMCellWithToolTip } from 'components/common/table/cells/MTypeCells';
import RegularTextCell from 'components/common/table/cells/STypeCells';
import { formatCurrency, formatLocalizedNumber } from 'utils/reporting';

export const SUPP_PREFIX = 'supportChange';
const PERCENTAGE_STRING = 'percentage';
const ABSOLUTE_STRING = 'absolute';
const PREV_YEAR_STRING = 'PrevYear';
const PREV_PERIOD_STRING = 'PrevPeriod';

const changesSuffix = {
  [PERCENTAGE_STRING + PREV_PERIOD_STRING]: 'RelativeChangePrevPeriod',
  [PERCENTAGE_STRING + PREV_YEAR_STRING]: 'RelativeChangePrevYear',
  [ABSOLUTE_STRING + PREV_PERIOD_STRING]: 'AbsoluteChangePrevPeriod',
  [ABSOLUTE_STRING + PREV_YEAR_STRING]: 'AbsoluteChangePrevYear',
};

/**
 * @param {boolean} percentage
 * @param {boolean} prevYear
 * @param {string} selectedColumns
 * @returns {array} relevant columns
 */
const clientsColumns = (percentage, prevYear, selectedColumns) => {
  const columnsToKeep = selectedColumns.split(',');
  const baseColumns = [
    {
      Header: 'clientName',
      label: 'Client',
      accessor: (a) => a.accountName,
      Component: BoldMCellWithToolTip,
      sortType: 'basic',
    },
    {
      Header: 'packages',
      label: 'Packages',
      accessor: (a) => (a.dmPackages ? a.dmPackages.toString().replace(/,/g, ', ') : a.dmPackage),
      Component: RegularTextCell,
    },
    {
      Header: 'activeStatus',
      label: 'Active',
      accessor: (a) => (a.isActive ? 'Active' : 'Inactive'),
      Component: RegularTextCell,
    },
    {
      Header: 'dmStatus',
      label: 'Dm Status',
      accessor: (a) => a.dmStatus,
      Component: RegularTextCell,
    },
    {
      Header: 'contentStrategist',
      label: 'Content Strategist',
      accessor: (a) => a.contentStrategist,
      Component: RegularTextCell,
    },
    {
      Header: 'digitalSuccessSpecialist',
      label: 'DSS',
      accessor: (a) => a.digitalSuccessSpecialist,
      Component: RegularTextCell,
    },
    {
      Header: 'dmMrr',
      label: 'MRR',
      accessor: (a) => a.dmMrr,
      formatting: (a) => formatCurrency(a),
      Component: ComparisonCell,
      sortType: 'basic',
      changeColumn: true,
    },
    {
      Header: '30DayCpl',
      label: 'Cost Per Lead (30 days)',
      accessor: (a) => a['30DayCpl'],
      formatting: (a) => formatCurrency(a),
      supportValues: {
        increaseIsPositive: false,
      },
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: '30DayLeads',
      label: 'Leads (30 days)',
      accessor: (a) => a['30DayLeads'],
      formatting: formatLocalizedNumber,
      supportValues: {
        increaseIsPositive: true,
        monetary: false,
      },
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: '30DayCampaignCount',
      label: 'Campaigns (30 days)',
      accessor: (a) => a['30DayCampaignCount'],
      supportValues: {
        increaseIsPositive: true,
        monetary: false,
      },
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'totalMediaSpend',
      label: 'Total media spend (30 days)',
      accessor: (a) => a.totalMediaSpend,
      formatting: (a) => formatCurrency(a),
      supportValues: {
        increaseIsPositive: false,
        increaseIsNeutral: true,
      },
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'facebookAdsMediaSpend',
      label: 'Facebook spend (30 days)',
      accessor: (a) => a.facebookAdsMediaSpend,
      formatting: (a) => formatCurrency(a),
      supportValues: {
        increaseIsPositive: false,
        increaseIsNeutral: true,
      },
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'googleAdsMediaSpend',
      label: 'Google spend (30 days)',
      accessor: (a) => a.googleAdsMediaSpend,
      formatting: (a) => formatCurrency(a),
      supportValues: {
        increaseIsNeutral: true,
        increaseIsPositive: false,
      },
      Component: ComparisonCell,
      changeColumn: true,
      sortType: 'basic',
    },
    {
      Header: 'conversions',
      label: 'Paid Conversions (30 days)',
      accessor: (a) => a.conversions,
      changeColumn: true,
      sortType: 'basic',
      Component: ComparisonCell,
    },
    {
      Header: 'clickThroughRate',
      label: 'Paid CTR (30 days)',
      accessor: (a) => `${((a.clickThroughRate ? a.clickThroughRate : 0) * 100).toFixed(2)} %`,
      supportValues: {
        increaseIsPositive: true,
        monetary: false,
      },
      sortType: 'basic',
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'conversionRate',
      label: 'Paid Conv. Rate (30 days)',
      supportValues: {
        increaseIsPositive: true,
        monetary: false,
      },
      accessor: (a) => `${((a.conversionRate ? a.conversionRate : 0) * 100).toFixed(2)} %`,
      sortType: 'basic',
      changeColumn: true,
      Component: ComparisonCell,
    },
  ].filter((column) => columnsToKeep.includes(column.Header));

  const changeColumns = baseColumns
    .map((column) => {
      if (!column.changeColumn) return undefined;

      return {
        Header: `${SUPP_PREFIX}${column.Header}`,
        label: `label${SUPP_PREFIX}${column.Header}`,
        accessor: (a) => a[
          `${column.Header}${
            changesSuffix[
              (PERCENTAGE_STRING)
                  + (prevYear ? PREV_YEAR_STRING : PREV_PERIOD_STRING)
            ]
          }`
        ],
        supportColumn: true,
        sortType: 'basic',
      };
    })
    .filter((value) => !!value);

  return [...baseColumns, ...changeColumns];
};

export default clientsColumns;
