import React from 'react';
import PropTypes from 'prop-types';
import './CalloutMetricTotal.css';

export default function CalloutMetricTotal({ total, metric }) {
  return (
    <div>
      <p className="calloutMetricTotal">
        {total}
      </p>
      <p>
        {metric}
      </p>
    </div>
  );
}

CalloutMetricTotal.propTypes = {
  total: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
};
