import React from 'react';
import { parse, format } from 'date-fns';
import { trackGAEvent } from 'utils';
import {
  formatingDates,
  checkFormatingValue,
  dateRangeFunctions,
  getValidDate,
} from '../constants';

export const useDatesValidations = (
  startDate,
  endDate,
  onChange,
  onError,
  onErrorResolve,
  showPrevPeriod,
  showPrevYear,
  onChangeCheckbox,
) => {
  const [dates, setDates] = React.useState([
    {
      startDate,
      endDate,
      key: 'selection',
    },
  ]);

  const [checkBoxValues, setCheckboxValues] = React.useState({
    showPrevPeriod,
    showPrevYear,
  });

  const [selectedFormat, setSelectedFormat] = React.useState(
    formatingDates.FULLMONTH,
  );

  const [inputValues, setInputValues] = React.useState({
    startDate: format(dates[0].startDate, selectedFormat),
    endDate: format(dates[0].endDate, selectedFormat),
  });

  const [errors, setErrors] = React.useState({
    startDate: false,
    endDate: false,
    button: false,
  });

  React.useEffect(() => {
    setInputValues({
      startDate: format(dates[0].startDate, selectedFormat),
      endDate: format(dates[0].endDate, selectedFormat),
    });
  }, [dates, selectedFormat]);

  const [dateRangeSelectedOption, setDateRangeSelectedOption] = React.useState({
    value: 'Custom',
    label: 'Custom',
  });

  React.useEffect(() => {
    setDates([
      {
        startDate,
        endDate,
        key: 'selection',
      },
    ]);
  }, [startDate, endDate]);

  React.useEffect(() => {
    setCheckboxValues({
      showPrevPeriod,
      showPrevYear,
    });
  }, [showPrevPeriod, showPrevYear]);

  React.useEffect(() => {
    if (!errors.startDate && !errors.endDate && !errors.button) onErrorResolve();
    else onError();
  }, [errors, onError, onErrorResolve]);

  const checkIfIsValidDate = React.useCallback(
    (value) => {
      if (
        parse(value, selectedFormat, new Date()).toString() !== 'Invalid Date'
      ) {
        return Boolean(getValidDate(value, selectedFormat));
      }
      return false;
    },
    [selectedFormat],
  );

  React.useEffect(() => {
    if (dates[0].startDate.getTime() <= dates[0].endDate.getTime()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        button: false,
        startDate: !checkIfIsValidDate(inputValues.startDate),
        endDate: !checkIfIsValidDate(inputValues.endDate),
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        button: true,
        startDate: true,
        endDate: true,
      }));
    }
  }, [dates, inputValues, checkIfIsValidDate]);

  const handleChange = (item) => {
    setDateRangeSelectedOption({
      label: 'Custom',
      value: 'Custom',
    });
    setDates([item.selection]);
    onChange(item.selection.startDate, item.selection.endDate);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxValues({
      ...checkBoxValues,
      [event.target.id]: event.target.checked,
    });
  };

  React.useEffect(() => {
    onChangeCheckbox(checkBoxValues);
  }, [checkBoxValues, onChangeCheckbox]);

  const handleDateInput = (literalValue, dateFormat, valueKey) => {
    try {
      const validDate = getValidDate(literalValue, dateFormat);

      if (!validDate) {
        throw new Error('wrong date value');
      }

      handleChange({
        selection: {
          ...dates[0],
          [valueKey]: validDate,
        },
      });

      if (selectedFormat !== dateFormat) {
        setSelectedFormat(dateFormat);
      }

      return setErrors({ ...errors, [valueKey]: false });
    } catch (error) {
      return setErrors({ ...errors, [valueKey]: true });
    }
  };

  const handleInputTextChange = (e) => {
    const {
      target: { name, value },
    } = e;

    if (dateRangeSelectedOption.value !== 'Custom') {
      setDateRangeSelectedOption({
        label: 'Custom',
        value: 'Custom',
      });
    }

    const newFormat = checkFormatingValue(value);

    handleDateInput(value, newFormat, name);

    setInputValues({ ...inputValues, [name]: value });
  };

  const handleChangeRange = (option) => {
    setDateRangeSelectedOption(option);
    if (option.value !== 'Custom') {
      const [newStartDate, newEndDate] = dateRangeFunctions[option.value];
      const item = {
        selection: {
          ...dates[0],
          startDate: newStartDate,
          endDate: newEndDate,
        },
      };
      setDates([item.selection]);
      onChange(item.selection.startDate, item.selection.endDate);
    }
    trackGAEvent({
      event: 'customEvent',
      category: 'Date Range Select',
      action: 'Option Selected',
      label: option.label,
      value: option.value,
    });
  };
  return {
    dates,
    selectedFormat,
    handleInputTextChange,
    handleChange,
    errors,
    handleCheckboxChange,
    checkBoxValues,
    dateRangeSelectedOption,
    handleChangeRange,
  };
};
