import React from 'react';
import ReactDOM from 'react-dom';
import { CloseIcon } from '../icons';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Modal({
  children,
  onClose,
  title = '',
  bottomLeftButtons = [],
  bottomRightButtons = [],
}) {
  return (
    <div
      className={styles.modal}
      onClick={onClose}
      data-testid="modal-background"
    >
      <div className={styles.modalWrapper} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <span className={styles.modalTitle}>{title}</span>
          <button
            onClick={onClose}
            data-testid="modal-close-icon"
            className={styles.modalCloseButton}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={styles.modalContent}>{children}</div>
        <div className={styles.modalFooter}>
          <div className={styles.bottomLeftButtonsContainer}>
            {bottomLeftButtons.map((button, i) => (
              <SecondaryButton
                onClick={button.onClick}
                key={`modal-secondary-button-${i}`}
                label={button.label}
              />
            ))}
          </div>
          <div className={styles.bottomRightButtonsContainer}>
            {bottomRightButtons.map((button, i) => (
              <PrimaryButton
                onClick={button.onClick}
                key={`modal-primary-button-${i}`}
                label={button.label}
                disabled={button.disabled}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ModalPortal({
  children,
  onClose = () => {},
  title = '',
  bottomLeftButtons = [],
  bottomRightButtons = [],
}) {
  return ReactDOM.createPortal(
    <Modal
      children={children}
      onClose={onClose}
      title={title}
      bottomLeftButtons={bottomLeftButtons}
      bottomRightButtons={bottomRightButtons}
    />,
    document.getElementById('root-modal'),
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  bottomLeftButtons: PropTypes.array,
  bottomRightButtons: PropTypes.array,
};

ModalPortal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  bottomLeftButtons: PropTypes.array,
  bottomRightButtons: PropTypes.array,
};

export default ModalPortal;
