import React from 'react';
import PropTypes from 'prop-types';

function Facebook(props) {
  const { onClick = () => {}, className = '' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid="Facebook"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#3C64DD" fillRule="nonzero">
          <g>
            <path
              d="M12 0C5.373 0 0 5.405 0 12.073 0 18.1 4.388 23.094 10.125 24v-8.437H7.078v-3.49h3.047v-2.66c0-3.025 1.792-4.697 4.533-4.697 1.312 0 2.686.236 2.686.236v2.971H15.83c-1.491 0-1.956.93-1.956 1.886v2.264h3.328l-.532 3.49h-2.796V24C19.612 23.094 24 18.1 24 12.073 24 5.405 18.627 0 12 0z"
              transform="translate(-88.000000, -1296.000000) translate(88.000000, 1296.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

Facebook.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export { Facebook };
