import React from 'react';
import XXLType from 'components/common/typography/XXLType';
import styles from './styles.module.scss';

export default function LeadsList() {
  return (
    <div className={styles.iframeHeaders}>
      <XXLType className="main">Leads</XXLType>
      <h2 className="pt-5 pb-4">Leads & CPL Change Summary</h2>
      <p className={styles.iframeBold}>QoQ Comparison</p>
      <iframe
        title="QOQ Quarterly CPL Change Summary"
        src="https://hosted-redash.unionstmedia.com/embed/query/4/visualization/207?api_key=XJ8AxbgCw1MRRP3gMzPtqlaHwXB1wEVygHUtkPxz&"
        width="1366"
        height="600"
      />
      <iframe
        title="QOQ Quarterly Leads Change Summary"
        src="https://hosted-redash.unionstmedia.com/embed/query/4/visualization/208?api_key=XJ8AxbgCw1MRRP3gMzPtqlaHwXB1wEVygHUtkPxz&"
        width="1366"
        height="600"
      />
      <p className={styles.iframeBold}>YoY Comparison</p>
      <iframe
        title=" YOY Quarterly CPL Change Summary"
        src="https://hosted-redash.unionstmedia.com/embed/query/4/visualization/209?api_key=XJ8AxbgCw1MRRP3gMzPtqlaHwXB1wEVygHUtkPxz&"
        width="1366"
        height="600"
      />
      <iframe
        title="YOY Quarterly Leads Change Summary"
        src="https://hosted-redash.unionstmedia.com/embed/query/4/visualization/211?api_key=XJ8AxbgCw1MRRP3gMzPtqlaHwXB1wEVygHUtkPxz&"
        width="1366"
        height="600"
      />
      <h2 className="pt-5 pb-4">Leads and CPL Change by Account</h2>
      <iframe
        title="Summary of Quarterly"
        src="https://hosted-redash.unionstmedia.com/embed/query/3/visualization/215?api_key=WdVrdfL4WlvA2HX8LD8BsIoDXEho4Dtdx7nSuJCy&"
        width="1366"
        height="600"
      />
      <iframe
        title="Details of Quarterly CPL by Account"
        src="https://hosted-redash.unionstmedia.com/embed/query/3/visualization/216?api_key=WdVrdfL4WlvA2HX8LD8BsIoDXEho4Dtdx7nSuJCy&"
        width="1366"
        height="600"
      />
      <h2 className="pt-5 pb-4">Leads Count Details</h2>
      <div className={styles.iframeText}>
        <p>The lead count is calculated from a combination of different data sources. This table breaks down the different aspects of the lead count and calls out the total leads in the "Reported Leads" column</p>
        <p className={styles.iframeBold}> Calculation Breakdown</p>
        <p>Lead count calculation differs based on the Call Tracking setting for clients. See below for details.</p>
        <p className={styles.iframeBoldLarge}> Call Tracking Enabled</p>
        <p>Lead Count = Accelerator Leads + Email Contacts + Facebook Leads</p>
        <p className={styles.iframeBoldLarge}> Call Tracking NOT Enabled.</p>
        <p>Lead Count = Accelerator Leads + Click To Calls + Google Ads Calls + Email Contacts + Facebook Leads</p>
      </div>
      <iframe
        title="Lead Count Breakdown"
        src="https://hosted-redash.unionstmedia.com/embed/query/36/visualization/36?api_key=hpD4K6nL0gturu2OtKSX2ckxhWyJPlcFu4wd2jJZ&p_Date%20Range=d_last_30_days"
        width="1366"
        height="600"
      />
    </div>

  );
}
