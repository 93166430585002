/* eslint-disable import/prefer-default-export */
export const getTenantsList = (state) => state.tenants.tenantsList;

export const getTenantSelector = (tenantId) => (state) => state.tenants[tenantId];

export const getGroupedTenantsSelector = (dimensions) => (state) => (
  state.tenants
      && state.tenants[dimensions]
    ? state.tenants[dimensions]
    : {});
