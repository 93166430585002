import React from 'react';
import styles from './styles.module.scss';
import { CloseIcon } from 'components/common/icons';
import cx from 'classnames';

export default function Drawer({ show = false, onClose, children }) {
  const [showDrawer, setShowDrawer] = React.useState(show);

  React.useEffect(() => {
    setShowDrawer(show);
  }, [show]);

  const closeDrawer = () => {
    onClose();
  };

  return (
    <div className={cx(showDrawer ? styles.show : styles.hide)}>
      <div className={cx(styles.drawerBackground)} onClick={closeDrawer} />
      <div
        className={cx(
          styles.drawer,
          showDrawer ? styles.showDrawer : styles.hideDrawer,
        )}
      >
        <CloseIcon
          stroke="white"
          className={styles.closeIcon}
          onClick={closeDrawer}
        />
        <div className={styles.drawerContent}>{children}</div>
      </div>
    </div>
  );
}
