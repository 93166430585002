import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceArea,
} from 'recharts';
import { InfoButton } from 'components/common/Buttons';
import { format } from 'date-fns';
import { formatSeconds, formatLocalizedNumber } from 'utils/reporting';
import ChooseMetricsModal from './ChooseMetricsModal';
import styles from './styles.module.scss';

const initialState = {
  left: 'dataMin',
  right: 'dataMax',
  top: 'auto',
  bottom: 'auto',
  top2: 'auto',
  bottom2: 'auto',
  animation: true,
  refAreaLeft: '',
  refAreaRight: '',
};

const calculateDirection = (value, referenceLeft) => {
  if (
    window.screen.availWidth / 1.6 <
    parseInt(referenceLeft.replace('px', ''))
  ) {
    return {
      transform: 'translate(-115%, 10%)',
    };
  }
  return {
    left: value,
  };
};

const ComparisonLinesGraph = (props) => {
  const allMetrics = props.allMetrics;
  const { prevValue, onMetricChange } = props;

  const [graphState, setGraphState] = React.useState(initialState);
  const [data, setData] = React.useState(props.data);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [referenceInfo, setReferenceInfo] = React.useState({
    left: '0px',
    date: new Date().getTime(),
    payload: {},
  });
  const [showModal, setShowModal] = React.useState(false);
  const [selectedMetricPosition, setSelectedMetricPosition] =
    React.useState('bottom');
  const [topMetrics, setTopMetrics] = React.useState(
    props.topMetrics.length
      ? [
          {
            ...allMetrics.find((metric) => metric.name === props.topMetrics[0]),
            color: '#de6b20',
          },
          {
            ...allMetrics.find((metric) => metric.name === props.topMetrics[1]),
            color: '#0450b4',
          },
        ]
      : [],
  );
  const [bottomMetrics, setBottomMetrics] = React.useState([
    {
      ...allMetrics.find((metric) => metric.name === props.bottomMetrics[0]),
      color: '#49b423',
    },
    {
      ...allMetrics.find((metric) => metric.name === props.bottomMetrics[1]),
      color: '#8E23B4',
    },
  ]);
  const [newBottomMetrics, setNewBottomMetrics] = React.useState(bottomMetrics);
  const [newTopMetrics, setNewTopMetrics] = React.useState(topMetrics);

  React.useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (position) => {
    setSelectedMetricPosition(position);
    setShowModal(true);
  };

  const onChangeMetricValue = ({ checked, optionSelected }) => {
    if (selectedMetricPosition === 'top') {
      if (checked) {
        const newMetrics = [];

        if (!newTopMetrics[0].name) {
          newMetrics.push({ color: newTopMetrics[0].color, ...optionSelected });
          newMetrics.push(newTopMetrics[1]);
        } else {
          newMetrics.push(newTopMetrics[0]);
          newMetrics.push({ color: newTopMetrics[1].color, ...optionSelected });
        }

        setNewTopMetrics(newMetrics);
      } else {
        setNewTopMetrics([
          ...newTopMetrics.map((value) =>
            value.name === optionSelected.name ? { color: value.color } : value,
          ),
        ]);
      }
    } else {
      if (checked) {
        const newMetrics = [];

        if (!newBottomMetrics[0].name) {
          newMetrics.push({
            color: newBottomMetrics[0].color,
            ...optionSelected,
          });
          newMetrics.push(newBottomMetrics[1]);
        } else {
          newMetrics.push(newBottomMetrics[0]);
          newMetrics.push({
            color: newBottomMetrics[1].color,
            ...optionSelected,
          });
        }

        setNewBottomMetrics(newMetrics);
      } else {
        setNewBottomMetrics([
          ...newBottomMetrics.map((value) =>
            value.name === optionSelected.name ? { color: value.color } : value,
          ),
        ]);
      }
    }
  };

  const applyNewMetrics = () => {
    const topMetricsChanged = [
      { ...newTopMetrics[0], color: topMetrics[0].color },
      { ...newTopMetrics[1], color: topMetrics[1].color },
    ];
    const bottomMetricsChanged = [
      { ...newBottomMetrics[0], color: bottomMetrics[0].color },
      { ...newBottomMetrics[1], color: bottomMetrics[1].color },
    ];

    onMetricChange({
      topMetrics: topMetricsChanged,
      bottomMetrics: bottomMetricsChanged,
    });

    setTopMetrics(topMetricsChanged);
    setBottomMetrics(bottomMetricsChanged);

    setShowModal(false);
  };

  const tickXFormatter = (value) => {
    if (new Date(value).toString() !== 'Invalid Date') {
      return format(new Date(value), 'MMM d');
    }
    return value;
  };

  const tickYFormatter = (value, metric) => {
    const { isPercentage, isDecimal, isTime, isMoney, name } = metric;

    if (!name) {
      return;
    }
    if (!value) {
      if (isMoney) {
        return `$0`;
      }
      return 0;
    }
    if (isPercentage) {
      return `${
        parseFloat(value).toFixed(2) % 1
          ? parseFloat(value).toFixed(2)
          : parseFloat(value).toFixed(0)
      }%`;
    }
    if (isTime) {
      return formatSeconds(value);
    }
    if (isMoney) {
      return `$${value.toFixed(2)}`;
    }
    if (isDecimal) {
      return value.toFixed(2);
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(2) + 'K';
    }
    return value;
  };

  const getAggregatedMetric = (metric) => {
    const {
      isPercentage,
      isDecimal,
      isTime,
      isMoney,
      isMaxMetric,
      isCalculatedMetric,
      name,
    } = metric;
    let total;
    if (isMaxMetric) {
      const values = data.map((d) => d[name]);
      total = Math.max(...values);
    } else if (isCalculatedMetric) {
      const { calculationMetricOne, calculationMetricTwo } = metric;

      const dividend = data.reduce((acc, cur) => {
        if (isDecimal || isPercentage || isTime || isMoney) {
          return acc + parseFloat(cur[calculationMetricOne]);
        }
        return acc + cur[calculationMetricOne];
      }, 0);
      const divisor = data.reduce((acc, cur) => {
        if (isDecimal || isPercentage || isTime || isMoney) {
          return acc + parseFloat(cur[calculationMetricTwo]);
        }
        return acc + cur[calculationMetricTwo];
      }, 0);

      total = dividend / divisor;
      if (isPercentage) {
        return tickYFormatter(total * 100, metric);
      }

      return tickYFormatter(total, metric);
    } else {
      total = data.reduce((acc, cur) => {
        if (isDecimal || isPercentage || isTime || isMoney) {
          return acc + parseFloat(cur[name]);
        }
        return acc + cur[name];
      }, 0);
    }

    if (isDecimal || isPercentage || isTime || isMoney) {
      return tickYFormatter(total / data.length, metric);
    }
    return formatLocalizedNumber(total);
  };

  const checkNumberOfMonths = () => {
    return new Set(data.map((row) => format(new Date(row.date), 'MMM d'))).size;
  };

  const resetMetrics = () => {
    setNewTopMetrics([...topMetrics]);
    setNewBottomMetrics([...bottomMetrics]);
  };

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = graphState;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setGraphState(() => ({
        ...graphState,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    setData(
      data.filter((x) => x.date >= refAreaLeft && x.date <= refAreaRight),
    );

    setGraphState(() => ({
      ...graphState,
      refAreaLeft: '',
      refAreaRight: '',
    }));
  };

  const saveRefAreaLeft = (e) => {
    if (e) setGraphState({ ...graphState, refAreaLeft: e.activeLabel });
  };

  const handleMouseMove = (props) => {
    graphState.refAreaLeft &&
      props &&
      setGraphState({ ...graphState, refAreaRight: props.activeLabel });

    if (!showTooltip) setShowTooltip(true);
    if (!props) return;
    const { chartX = false, activePayload, activeLabel } = props;
    if (chartX && activePayload) {
      setReferenceInfo({
        left: `${chartX}px`,
        payload: activePayload[0].payload,
        date: activeLabel,
      });
    }
  };

  const handleShowTooltip = () => (!showTooltip ? setShowTooltip(true) : null);

  const { left, right, top, bottom, top2, bottom2 } = graphState;

  return (
    <div className={styles.mainWrapper}>
      <div className="basic-shadow">
        <div
          className={`highlight-bar-charts ${styles.comparisonLinesGraphContainer}`}
        >
          <div className={styles.metricsContainer}>
            <div className={styles.graphInfoContainer}>
              <InfoButton
                label="Choose Metrics"
                className={styles.chooseMetricButton}
                onClick={() => openModal('top')}
                withDownArrow={true}
              />
              {topMetrics.map((metric) => {
                return (
                  metric.name && (
                    <div>
                      <div
                        className={styles.metricContainer}
                        key={`metricReference-${metric.name}`}
                      >
                        <div
                          className={styles.dot}
                          style={{ backgroundColor: metric.color }}
                        />
                        <span className={styles.metricLabel}>
                          {metric.label}
                        </span>
                      </div>
                      <span className={styles.metricTotalValue}>
                        {getAggregatedMetric(metric)}
                      </span>
                    </div>
                  )
                );
              })}
            </div>
            <div className={styles.graphInfoContainer}>
              <InfoButton
                label="Choose Metrics"
                className={styles.chooseMetricButton}
                onClick={() => openModal('bottom')}
                withDownArrow={true}
              />
              {bottomMetrics.map((metric) => {
                return (
                  metric.name && (
                    <div>
                      <div
                        className={styles.metricContainer}
                        key={`metricReference-${metric.name}`}
                      >
                        <div
                          className={styles.dot}
                          style={{ backgroundColor: metric.color }}
                        />
                        <span className={styles.metricLabel}>
                          {metric.label}
                        </span>
                      </div>
                      <span className={styles.metricTotalValue}>
                        {getAggregatedMetric(metric)}
                      </span>
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div className={styles.tooltipContainer}>
            {showTooltip && (
              <div
                className={styles.reference}
                style={{
                  left: referenceInfo.left,
                  paddingTop: '3rem',
                }}
              >
                <span
                  className={styles.dateTooltip}
                  style={{ ...calculateDirection('10px', referenceInfo.left) }}
                >
                  {format(new Date(referenceInfo.date), 'MMM d, y')}
                </span>
                <div
                  className={`${styles.tooltipTop} basic-shadow`}
                  style={{ ...calculateDirection('1rem', referenceInfo.left) }}
                >
                  <div className={styles.metricDetails}>
                    {topMetrics.map((metric) => {
                      return (
                        metric.name && (
                          <>
                            <p
                              key={`legend-${metric.name}`}
                              className={styles.tooltipMetric}
                            >
                              <span className={styles.legendSpan}>
                                <strong>
                                  {`${tickYFormatter(
                                    referenceInfo.payload[metric.name],
                                    metric,
                                  )}`}
                                </strong>
                                {` ${metric.label}`}
                              </span>
                            </p>
                            {prevValue.suffix && (
                              <p
                                key={`legend-${metric.name + prevValue.suffix}`}
                                className={styles.tooltipMetric}
                              >
                                <span className={styles.legendSpan}>
                                  <strong>
                                    {`${tickYFormatter(
                                      referenceInfo.payload[
                                        metric.name + prevValue.suffix
                                      ],
                                      metric,
                                    )}`}
                                  </strong>
                                  {` ${prevValue.label} ${metric.label}`}
                                </span>
                              </p>
                            )}
                          </>
                        )
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`${styles.tooltipBottom} basic-shadow`}
                  style={{ ...calculateDirection('1rem', referenceInfo.left) }}
                >
                  <div className={styles.metricDetails}>
                    {bottomMetrics.map((metric) => {
                      return (
                        metric.name && (
                          <>
                            <p
                              key={`legend-${metric.name}`}
                              className={styles.tooltipMetric}
                            >
                              <span className={styles.legendSpan}>
                                <strong>
                                  {`${tickYFormatter(
                                    referenceInfo.payload[metric.name],
                                    metric,
                                  )}`}
                                </strong>
                                {` ${metric.label}`}
                              </span>
                            </p>
                            {prevValue.suffix && (
                              <p
                                key={`legend-${metric.name + prevValue.suffix}`}
                                className={styles.tooltipMetric}
                              >
                                <span className={styles.legendSpan}>
                                  <strong>
                                    {`${tickYFormatter(
                                      referenceInfo.payload[
                                        metric.name + prevValue.suffix
                                      ],
                                      metric,
                                    )}`}
                                  </strong>
                                  {` ${prevValue.label} ${metric.label}`}
                                </span>
                              </p>
                            )}
                          </>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.graphsContainer}>
              <div
                className={styles.graphContainer}
                data-testid="graphContainer"
              >
                <ResponsiveContainer
                  width="100%"
                  height={150}
                  className={styles.responsiveContainer}
                >
                  <LineChart
                    width={800}
                    data={data}
                    onMouseEnter={handleShowTooltip}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={() => setShowTooltip(false)}
                    onMouseDown={saveRefAreaLeft}
                    onMouseUp={zoom}
                    className={
                      checkNumberOfMonths() === 2
                        ? styles.lineChartOnlyOneXValue
                        : styles.lineChart
                    }
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      allowDataOverflow
                      dataKey="date"
                      domain={[left, right]}
                      type="number"
                      tickFormatter={tickXFormatter}
                      tickCount={checkNumberOfMonths()}
                      tickSize={0}
                      paddingTop={3}
                      axisLine={false}
                    />
                    <YAxis
                      allowDataOverflow
                      domain={[bottom, top]}
                      type="number"
                      yAxisId="1"
                      tickSize={0}
                      tickFormatter={(value) =>
                        tickYFormatter(value, topMetrics[0])
                      }
                      tickCount={3}
                      axisLine={false}
                    />
                    <YAxis
                      orientation="right"
                      allowDataOverflow
                      domain={[bottom2, top2]}
                      type="number"
                      yAxisId="2"
                      tickSize={0}
                      tickFormatter={(value) =>
                        tickYFormatter(value, topMetrics[1])
                      }
                      axisLine={false}
                      tickCount={3}
                    />

                    {topMetrics.map((metric, i) => (
                      <Line
                        key={`${metric.name}-line-${i}`}
                        yAxisId={(i + 1).toString()}
                        type="linear"
                        dataKey={metric.name}
                        dot={false}
                        stroke={metric.color}
                        animationDuration={300}
                        strokeWidth={2}
                      />
                    ))}

                    {topMetrics.map((metric, i) => (
                      <Line
                        key={`${metric.name + prevValue.suffix}-line-${i}`}
                        yAxisId={(i + 1).toString()}
                        type="linear"
                        dataKey={metric.name + prevValue.suffix}
                        dot={false}
                        className={styles.prevValueLine}
                        stroke={metric.color}
                        animationDuration={300}
                        strokeWidth={2}
                      />
                    ))}
                    <ReferenceArea
                      yAxisId="1"
                      x1={graphState.refAreaLeft}
                      x2={graphState.refAreaRight}
                      strokeOpacity={0.3}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div
                className={styles.graphContainer}
                data-testid="graphContainer"
              >
                <ResponsiveContainer
                  width="100%"
                  height={150}
                  className={styles.responsiveContainer}
                >
                  <LineChart
                    width={800}
                    data={data}
                    onMouseEnter={handleShowTooltip}
                    onMouseLeave={() => setShowTooltip(false)}
                    onMouseMove={handleMouseMove}
                    onMouseUp={zoom}
                    onMouseDown={saveRefAreaLeft}
                    className={
                      checkNumberOfMonths() === 2
                        ? styles.lineChartOnlyOneXValue
                        : styles.lineChart
                    }
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      allowDataOverflow
                      dataKey="date"
                      domain={[left, right]}
                      type="number"
                      tickFormatter={tickXFormatter}
                      tickCount={checkNumberOfMonths()}
                      tickSize={0}
                      paddingTop={3}
                      axisLine={false}
                    />
                    <YAxis
                      allowDataOverflow
                      domain={[bottom, top]}
                      type="number"
                      yAxisId="1"
                      tickSize={0}
                      tickFormatter={(value) =>
                        tickYFormatter(value, bottomMetrics[0])
                      }
                      tickCount={3}
                      axisLine={false}
                    />
                    <YAxis
                      orientation="right"
                      allowDataOverflow
                      domain={[bottom2, top2]}
                      type="number"
                      yAxisId="2"
                      tickSize={0}
                      tickFormatter={(value) =>
                        tickYFormatter(value, bottomMetrics[1])
                      }
                      axisLine={false}
                      tickCount={3}
                    />
                    {bottomMetrics.map((metric, i) => (
                      <Line
                        key={`${metric.name}-line-${i}`}
                        yAxisId={(i + 1).toString()}
                        type="linear"
                        dataKey={metric.name}
                        dot={false}
                        stroke={metric.color}
                        animationDuration={300}
                        strokeWidth={2}
                      />
                    ))}

                    {bottomMetrics.map((metric, i) => (
                      <Line
                        key={`${metric.name + prevValue.suffix}-line-${i}`}
                        yAxisId={(i + 1).toString()}
                        type="linear"
                        dataKey={metric.name + prevValue.suffix}
                        dot={false}
                        className={styles.prevValueLine}
                        stroke={metric.color}
                        animationDuration={300}
                        strokeWidth={2}
                      />
                    ))}
                    <ReferenceArea
                      yAxisId="1"
                      x1={graphState.refAreaLeft}
                      x2={graphState.refAreaRight}
                      strokeOpacity={0.3}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className={styles.graphActions}>
            <button
              className={`${styles.zoomOutButton} basic-shadow button`}
              onClick={() => setData(props.data)}
            >
              <span className={styles.minusLine} />
            </button>
          </div>
        </div>
      </div>
      <ChooseMetricsModal
        showModal={showModal}
        onClickApply={applyNewMetrics}
        onClickReset={resetMetrics}
        onChangeValue={onChangeMetricValue}
        closeModal={closeModal}
        selectedMetrics={
          selectedMetricPosition === 'top' ? newTopMetrics : newBottomMetrics
        }
        metrics={props.chooseMetricsValues}
      />
    </div>
  );
};

export default ComparisonLinesGraph;
