import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import chevronDown from 'resources/assets/chevron-up.svg';
import MType from 'components/common/typography/MType';
import DropdownFloatMenu from 'components/common/inputs/DropdownFloatMenu/DropdownFloatMenu';
import { callFunctionOnBlur } from 'resources/helpers/browserCompatibility';

const BasicDropdown = ({
  selectedOption, setSelectedOption, options, className,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => callFunctionOnBlur(() => setDropdownOpen(false)), []);

  const setSelectedOptionHandler = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: 'customEvent',
        category: 'Basic Dropdown',
        action: 'Option Selected',
        label: option.text,
        value: option.value,
      });
    }
  };

  return (
    <div
      className={`minw-120 position-relative fit-content ${className}`}
      data-testid="basic-dropdown"
    >
      <button
        type="button"
        className="no-bg p-dropdown minw-120 border-2 border-light-grey border-rd-3 center-children fit-content"
        onClick={(e) => { e.nativeEvent.preventDefault(); setDropdownOpen((prev) => !prev); }}
        onBlur={() => setDropdownOpen(false)}
      >
        <div
          className="flex-g-1 text-left"
          data-testid="basic-dropdown-selected"
        >
          <MType className="pr-2 text-primary-dark-3">
            {selectedOption.text}
          </MType>
        </div>
        <img src={chevronDown} className="sqr-22" alt="" />
      </button>
      {dropdownOpen && (
      <DropdownFloatMenu
        options={options}
        selectedOption={selectedOption}
        setOptionState={setSelectedOptionHandler}
      />
      )}
    </div>
  );
};

BasicDropdown.defaultProps = {
  className: '',
};

BasicDropdown.propTypes = {
  selectedOption: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
  }).isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
};

export default BasicDropdown;
