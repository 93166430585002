import React from 'react';
import PropTypes from 'prop-types';
import './CalloutMetricArrowBox.css';

function DisplayArrow({ percentage, inverseTrend }) {
  let trendModifier = 'neutral';

  if (percentage < 0) {
    trendModifier = 'negative';
  }
  if (percentage > 0) {
    trendModifier = 'positive';
  }

  if (inverseTrend === true && trendModifier !== 'neutral') {
    trendModifier = (trendModifier === 'negative' ? 'positive' : 'negative');
  }

  return (
    <div data-testid="arrowContainer" className={`calloutMetricArrowBox__comparisonPeriod calloutMetricArrowBox__comparisonPeriod--${trendModifier}`}>
      {`${percentage}%`}
      {percentage > 0 && (<p>&uarr;</p>)}
      {percentage < 0 && (<p>&darr;</p>)}
    </div>
  );
}

DisplayArrow.propTypes = {
  percentage: PropTypes.string,
  inverseTrend: PropTypes.bool,
};

DisplayArrow.defaultProps = {
  percentage: '-',
  inverseTrend: false,
};

export default function CalloutMetricArrowBox({ percentage, metricTimeFrame, inverseTrend }) {
  return (
    <div className="calloutMetricArrowBox__comparisonPeriod">
      {' '}

      <div>
        <div className="calloutMetricArrowBox__p">{metricTimeFrame}</div>
        {percentage ? (<DisplayArrow percentage={percentage} inverseTrend={inverseTrend} />) : '-'}
      </div>
    </div>
  );
}

CalloutMetricArrowBox.propTypes = {
  percentage: PropTypes.string,
  metricTimeFrame: PropTypes.string.isRequired,
  inverseTrend: PropTypes.bool,
};

CalloutMetricArrowBox.defaultProps = {
  percentage: null,
  inverseTrend: false,
};
