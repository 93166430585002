import React from 'react';
import { CheckboxWithLabel } from 'components/common';
import Modal from 'components/common/Modal';
import styles from './styles.module.scss';

export default function ChooseMetricsModal({
  showModal,
  closeModal,
  onChangeValue,
  onClickApply,
  onClickReset,
  metrics,
}) {
  return (
    showModal && (
      <Modal
        title="View Settings"
        onClose={closeModal}
        bottomLeftButtons={[
          {
            label: 'Cancel',
            onClick: closeModal,
          },
          { label: 'Reset', onClick: onClickReset },
        ]}
        bottomRightButtons={[
          {
            label: 'Apply',
            onClick: onClickApply,
            disabled: metrics.length === 0,
          },
        ]}
      >
        <div>
          <h3 className={styles.chooseMetricsTitle}>Select Columns </h3>
          <div className={styles.chooseMetrics}>
            {metrics.map((option) => {
              return (
                <CheckboxWithLabel
                  id={`${option.name}-checkbox`}
                  isChecked={false}
                  label={option.label}
                  checked={option.isChecked}
                  onChange={(e) => {
                    onChangeValue({
                      checked: e.target.checked,
                      optionSelected: option,
                    });
                  }}
                  data-testid={`${option.name}-checkbox`}
                />
              );
            })}
          </div>
        </div>
      </Modal>
    )
  );
}
