import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { subDays, format } from 'date-fns';

import { useSelector } from 'react-redux';
import ComparisonPeriodCheckbox from '../../components/ComparisonPeriodCheckbox';
import reportTypes from '../../api/reportTypes';
import getReportData from '../../api/getReports';
import RestrictedAccessPage from '../../components/RestrictedAccessPage';
import DateRangePicker from '../../components/DateRangePicker';
import MetricsOverTime from '../../components/MetricsOverTime';
import PeriodSelector from '../../components/PeriodSelector';
import MetricsComparisonPicker from '../../components/MetricsComparisionPicker';

import { userHasAccess } from '../../utils/authorization';
import {
  getPreviousPeriod,
  getPreviousYear,
  filterDataByChannel,
  groupByDate,
} from '../../utils/reporting';
import useApplicationStateValue from '../../utils/useApplicationStateValue';
import { Loader } from 'components/common';

import './index.css';
import OrganicOverview from './OrganicOverview';
import DateDisplay from '../../components/DateDisplay';
import TenantPageBase from '../../components/common/page-base/TenantPageBase';
import { getUserInfo } from '../../selectors/UserSelectors';
import { availableMetrics } from './constants';

export default function Organic() {
  const user = useSelector(getUserInfo);
  const [organicPerformanceData, setOrganicPerformanceData] = useState({
    rows: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [startDate, onSetStartDate] = useApplicationStateValue(
    'startDate',
    format(subDays(Date.now(), 30), 'yyyy-MM-dd'),
  );
  const [endDate, onSetEndDate] = useApplicationStateValue(
    'endDate',
    format(Date.now(), 'yyyy-MM-dd'),
  );

  const previousYear = useMemo(
    () => getPreviousYear(startDate, endDate),
    [startDate, endDate],
  );
  const previousPeriod = useMemo(
    () => getPreviousPeriod(startDate, endDate),
    [startDate, endDate],
  );

  const { tenantid } = useParams();
  const [metricOne, setMetricOne] = useApplicationStateValue(
    'metricOne',
    'sessions',
  );
  const [metricTwo, setMetricTwo] = useApplicationStateValue('metricTwo');
  const [comparisonPeriod, setComparisonPeriod] = useApplicationStateValue(
    'comparisonPeriod',
    'previousYear',
  );
  const [isComparisonPeriodDisplayed, setIsComparisonPeriodDisplayed] =
    useApplicationStateValue('isComparisonPeriodDisplayed', true);

  const toggleComparisonPeriodDisplay = () => {
    const toggle = !isComparisonPeriodDisplayed;
    setIsComparisonPeriodDisplayed(toggle);
  };

  const handlePeriodChange = (selectedComparisonPeriod) => {
    if (selectedComparisonPeriod.value !== null) {
      setComparisonPeriod(selectedComparisonPeriod.value);
    }
  };

  useEffect(() => {
    if (tenantid && startDate && endDate) {
      setIsLoading(true);
      setError(null);
      getReportData(
        tenantid,
        reportTypes.websitePerformanceReports.websiteTraffic,
        { startDate, endDate },
      )
        .then((response) => {
          const organicData = response.data;
          organicData.rows = filterDataByChannel(
            response.data.rows,
            'Organic Search',
          );
          setOrganicPerformanceData(organicData);
        })
        .then(() => setIsLoading(false))
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }

    return () => {
      // TODO: Cancel API request to prevent memory leak
      // https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect
    };
  }, [tenantid, user.accessToken, startDate, endDate, previousYear.startDate]);

  const dailyOrganicData = groupByDate(organicPerformanceData.rows).map(
    (item) => ({
      ...item,
      bounceRate: item.sessions > 0 ? item.bounces / item.sessions : 0,
      pageviewsPerSession:
        item.sessions > 0 ? item.pageviews / item.sessions : 0,
      avgSessionDuration:
        item.sessions > 0 ? item.sessionDuration / item.sessions : 0,
    }),
  );

  const onMetricsSelected = useCallback(
    (firstMetric, secondMetric) => {
      setMetricOne(firstMetric.name);
      if (secondMetric) setMetricTwo(secondMetric.name);
    },
    [setMetricOne, setMetricTwo],
  );

  const onSelectedDateRangeChange = useCallback(
    (dates) => {
      const { start, end } = dates;
      if (start !== null) {
        onSetStartDate(format(start, 'yyyy-MM-dd'));
      }

      if (end !== null) {
        onSetEndDate(format(end, 'yyyy-MM-dd'));
      }
    },
    [onSetStartDate, onSetEndDate],
  );

  const period = useMemo(() => ({ startDate, endDate }), [startDate, endDate]);
  const comparisonMetrics = useMemo(() => {
    if (availableMetrics.filter((e) => e.name === metricOne).length === 0) {
      setMetricOne(availableMetrics[0].name);
    }
    const findMetricByName = (name) =>
      availableMetrics.find((item) => item.name === name);

    const metrics = [findMetricByName(metricOne)];
    if (metricTwo) metrics.push(findMetricByName(metricTwo));
    return metrics.filter((metric) => !!metric);
  }, [metricOne, metricTwo, setMetricOne]);

  if (userHasAccess(user)) {
    return (
      <TenantPageBase>
        <div data-testid="Organic">
          <h2>Organic</h2>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={onSelectedDateRangeChange}
          />
          <div>
            <DateDisplay
              previousPeriod={previousPeriod}
              previousYear={previousYear}
            />
          </div>
          <Loader isLoading={isLoading} style={{ marginTop: '10rem' }}>
            <>
              {error && (
                <p className="text-danger">
                  There has been an error while loading the data. Please contact
                  your nearest Bacio person and hold on while we fix it.
                </p>
              )}
              {tenantid && !error && !isLoading && (
                <>
                  <div>
                    <OrganicOverview
                      data={organicPerformanceData}
                      period={period}
                    />
                    <div className="container graph">
                      <PeriodSelector
                        onChange={(selectedComparePeriod) =>
                          handlePeriodChange(selectedComparePeriod)
                        }
                        selectedComparisonPeriod={comparisonPeriod}
                      />
                      <ComparisonPeriodCheckbox
                        isComparisonPeriodDisplayed={
                          isComparisonPeriodDisplayed
                        }
                        onChange={() => toggleComparisonPeriodDisplay()}
                      />
                      <div className="row">
                        <MetricsComparisonPicker
                          availableMetrics={availableMetrics}
                          selectedMetrics={comparisonMetrics}
                          onChange={onMetricsSelected}
                        />
                      </div>
                      <div className="row">
                        <MetricsOverTime
                          data={dailyOrganicData}
                          period={period}
                          metrics={comparisonMetrics}
                          comparisonPeriod={comparisonPeriod}
                          isDisplayed={isComparisonPeriodDisplayed}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </Loader>
        </div>
      </TenantPageBase>
    );
  }
  return <RestrictedAccessPage />;
}
