import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

function PrimaryButton({
  onClick,
  label,
  disabled,
  icon,
  className = '',
}) {
  return (
    <button
      disabled={disabled}
      className={cx(styles.primaryButton, className)}
      onClick={onClick}
      type="button"
      data-testid="primary-button"
    >
      {icon ? icon : null}
      {label}
    </button>
  );
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isIconDisplayed: PropTypes.bool,
  className: PropTypes.string,
};

export { PrimaryButton };
