import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { subDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import MetricTable from '../../components/common/MetricTable';
import BiaxialBarChart from '../../components/BiaxialBarChart';
import SimpleBarChart from '../../components/SimpleBarChart';
import PeriodSelector from '../../components/PeriodSelector';
import { getPreviousPeriod, getPreviousYear } from '../../utils/reporting';
import { userHasAccess } from '../../utils/authorization';
import formatBarChartData from './groupedLeadsBarChart';
import DateRangePicker from '../../components/DateRangePicker';
import DateDisplay from '../../components/DateDisplay';
import useApplicationStateValue from '../../utils/useApplicationStateValue';
import groupCPLData from './groupCPLData';
import './index.css';
import TenantPageBase from '../../components/common/page-base/TenantPageBase';
import reportTypes from '../../api/reportTypes';
import getReportData from '../../api/getReports';
import {
  monthlyLeadsColumns,
  costPerLeadColumns,
  costPerLeadMetrics,
  quarterlyLeadsColumns,
  monthlyBarChartMetrics,
  quarterlyBarChartMetrics,
} from './tableConfigs';
import { Loader } from 'components/common';
import { getUserInfo } from '../../selectors/UserSelectors';

export default function Leads() {
  const user = useSelector(getUserInfo);
  const [monthlyLeadsData, setMonthlyLeadsData] = useState({ rows: [] });
  const [quartelyData, setQuartelyData] = useState({ rows: [] });
  const [monthlyCostPerLeadData, setMonthlyCostPerLeadData] = useState({
    rows: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { tenantid } = useParams();

  const [startDate, onSetStartDate] = useApplicationStateValue(
    'startDate',
    format(subDays(Date.now(), 30), 'yyyy-MM-dd'),
  );
  const [endDate, onSetEndDate] = useApplicationStateValue(
    'endDate',
    format(Date.now(), 'yyyy-MM-dd'),
  );
  const period = useMemo(() => ({ startDate, endDate }), [startDate, endDate]);

  const previousYear = useMemo(
    () => getPreviousYear(startDate, endDate),
    [startDate, endDate],
  );
  const previousPeriod = useMemo(
    () => getPreviousPeriod(startDate, endDate),
    [startDate, endDate],
  );
  const [comparisonPeriod, setComparisonPeriod] = useApplicationStateValue(
    'comparisonPeriod',
    'previousYear',
  );
  const [costPerLeadData, setCostPerLeadData] = useState({ rows: [] });

  const handlePeriodChange = (selectedComparisonPeriod) => {
    if (selectedComparisonPeriod.value !== null) {
      setComparisonPeriod(selectedComparisonPeriod.value);
    }
  };

  useEffect(() => {
    if (tenantid && startDate && endDate) {
      setIsLoading(true);
      setError(null);

      const costPerLeadPromise = getReportData(
        tenantid,
        reportTypes.leadsReports.costPerLead,
        { startDate, endDate },
      );

      const monthlyLeadDataPromise = getReportData(
        tenantid,
        reportTypes.leadsReports.monthlyLeadsGenerated,
      );

      const monthlyCostPerLeadPromise = getReportData(
        tenantid,
        reportTypes.leadsReports.costPerLeadByMonth,
        { startDate, endDate },
      );

      const quarterlyCostPerLeadPromise = getReportData(
        tenantid,
        reportTypes.leadsReports.quarterlyLeads,
      );

      Promise.all([
        costPerLeadPromise,
        monthlyLeadDataPromise,
        monthlyCostPerLeadPromise,
        quarterlyCostPerLeadPromise,
      ])
        .then((values) => {
          setCostPerLeadData(values[0].data);
          setMonthlyLeadsData(values[1].data);
          setMonthlyCostPerLeadData(values[2].data);
          setQuartelyData(values[3].data);
        })
        .then(() => setIsLoading(false))
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }

    return () => {};
  }, [tenantid, user.accessToken, startDate, endDate]);

  const onSelectedDateRangeChange = useCallback(
    (dates) => {
      const { start, end } = dates;
      if (start !== null) {
        onSetStartDate(format(start, 'yyyy-MM-dd'));
      }

      if (end !== null) {
        onSetEndDate(format(end, 'yyyy-MM-dd'));
      }
    },
    [onSetStartDate, onSetEndDate],
  );

  const currentComparisonPeriod =
    comparisonPeriod === 'previousYear' ? previousYear : previousPeriod;
  const groupedCPLData =
    groupCPLData(
      costPerLeadData.rows,
      period,
      comparisonPeriod,
      currentComparisonPeriod,
    ) || [];

  const barChartCPLData = groupedCPLData.map((item) => ({
    xAxis: item.period,
    leftYAxis: item.cpl,
    rightYAxis: item.leadCount,
  }));

  const monthlyBarChartData = formatBarChartData(
    monthlyCostPerLeadData.rows,
    ['salesforceAccountId', 'accountName', 'dateGroup'],
    'dateGroup',
  );

  const filteredByDateRangeLeadData = quartelyData.rows.filter(
    (row) => row.dateGroup >= startDate && row.dateGroup <= endDate,
  );
  const quarterlyBarChartData = formatBarChartData(
    filteredByDateRangeLeadData,
    ['salesforceAccountId', 'accountName', 'dateGroup', 'year'],
    'dateGroup',
  );
  if (userHasAccess(user)) {
    return (
      <TenantPageBase>
        <div data-testid="Leads">
          <h2>Leads</h2>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={onSelectedDateRangeChange}
          />
          <div>
            <DateDisplay
              previousPeriod={previousPeriod}
              previousYear={previousYear}
            />
          </div>
          <Loader isLoading={isLoading} style={{ marginTop: '10rem' }}>
            <>
              {error && (
                <p className="text-danger">
                  There has been an error while loading the data.
                </p>
              )}
              {tenantid &&
                !error &&
                !isLoading &&
                groupedCPLData &&
                monthlyLeadsData && (
                  <>
                    <div data-testid="leads-content-area" className="container">
                      <PeriodSelector
                        onChange={(selectedComparisonPeriod) =>
                          handlePeriodChange(selectedComparisonPeriod)
                        }
                        selectedComparisonPeriod={comparisonPeriod}
                      />
                      <div className="row">
                        <MetricTable
                          rawData={groupedCPLData}
                          rawColumns={costPerLeadColumns}
                          defaultSort={[{ id: 'Summary', desc: true }]}
                        />
                      </div>
                      <div className="container graph">
                        <div className="row">
                          <BiaxialBarChart
                            data={barChartCPLData}
                            metrics={costPerLeadMetrics}
                            leftYAxisName="CPL"
                            rightYAxisName="Leads"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <h3>Monthly Leads Generated</h3>
                        <div className="container graph">
                          <div className="row">
                            <SimpleBarChart
                              data={monthlyBarChartData.data}
                              barData={monthlyBarChartData.bars}
                              metrics={monthlyBarChartMetrics}
                              xAxisKey="dateGroup"
                              barKey="leadCount"
                            />
                          </div>
                        </div>
                        <MetricTable
                          rawData={monthlyLeadsData.rows}
                          rawColumns={monthlyLeadsColumns}
                          defaultSort={[{ id: 'Month', desc: true }]}
                          isPaginationEnabled
                        />
                      </div>
                      <div className="row">
                        <h3 className="mt-5 mb-2">Quarterly Leads Generated</h3>
                        <div className="container graph">
                          <div className="row">
                            <SimpleBarChart
                              data={quarterlyBarChartData.data}
                              barData={quarterlyBarChartData.bars}
                              metrics={quarterlyBarChartMetrics}
                              xAxisKey="dateGroup"
                              barKey="leadCount"
                            />
                          </div>
                        </div>
                        <MetricTable
                          rawData={quartelyData.rows}
                          rawColumns={quarterlyLeadsColumns}
                          defaultSort={[{ id: 'Quarter', desc: true }]}
                          isPaginationEnabled
                        />
                      </div>
                    </div>
                  </>
                )}
            </>
          </Loader>
        </div>
      </TenantPageBase>
    );
  }
}
