/* eslint-disable import/prefer-default-export */
import { getSingleTenant } from '../api/getTenants';
import { getGlobalReport, getGroupedTenants } from '../api/getGlobalReport';
import { tenantActionTypes } from './ActionTypes';

const fetchAllRequest = () => ({
  type: tenantActionTypes.fetchAllRequest,
  payload: null,
});

const fetchAllError = (error) => ({
  type: tenantActionTypes.fetchAllError,
  payload: { error },
});

const fetchAllSuccess = (tenantsData) => ({
  type: tenantActionTypes.fetchAllSuccess,
  payload: tenantsData,
});

export const fetchAllTenantsAction = () => async (dispatch) => {
  dispatch(fetchAllRequest());
  try {
    const tenantsData = await getGlobalReport();
    dispatch(fetchAllSuccess(tenantsData.data.rows));
  } catch (error) {
    dispatch(fetchAllError(error.message));
  }
};

const fetchTenantRequest = () => ({
  type: tenantActionTypes.fetchTenantRequest,
  payload: null,
});

const fetchTenantError = (error) => ({
  type: tenantActionTypes.fetchTenantError,
  payload: { error },
});

const fetchTenantSuccess = (tenantsData) => ({
  type: tenantActionTypes.fetchTenantSuccess,
  payload: tenantsData,
});

export const fetchTenantAction = (tenantId) => async (dispatch) => {
  dispatch(fetchTenantRequest());
  try {
    const tenantData = await getSingleTenant(tenantId);
    dispatch(fetchTenantSuccess(tenantData.data));
  } catch (error) {
    dispatch(fetchTenantError(error.message));
  }
};

const fetchGroupedTenantsRequest = () => ({
  type: tenantActionTypes.fetchGroupedTenantsRequest,
  payload: null,
});

const fetchGroupedTenantsError = (error) => ({
  type: tenantActionTypes.fetchGroupedTenantsError,
  payload: { error },
});

const fetchGroupedTenantsSuccess = (tenantsData) => ({
  type: tenantActionTypes.fetchGroupedTenantsSuccess,
  payload: tenantsData,
});

export const fetchGroupedTenantsAction = (
  dimensions,
) => async (dispatch) => {
  dispatch(fetchGroupedTenantsRequest());
  try {
    const tenantData = await getGroupedTenants(dimensions);
    dispatch(
      fetchGroupedTenantsSuccess(
        { [dimensions]: { data: tenantData.data }, id: dimensions },
      ),
    );
  } catch (error) {
    dispatch(fetchGroupedTenantsError(error.message));
  }
};
