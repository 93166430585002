import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import cx from 'classnames';

function InfoButton({
  onClick = () => {},
  label,
  withDownArrow = false,
  className = '',
}) {
  return (
    <button
      className={cx(styles.infoButton, className)}
      onClick={onClick}
      data-testid="info-button"
    >
      <span>{label}</span>
      {withDownArrow && <span className={styles.downArrow} />}
    </button>
  );
}

InfoButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  withDownArrow: PropTypes.bool,
  className: PropTypes.string,
};

export { InfoButton };
