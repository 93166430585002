import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Tooltip({ children, content = '' }) {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const changeTooltipState = (newValue) => {
    setShowTooltip(newValue);
  };

  return (
    <div
      onMouseOver={() => changeTooltipState(true)}
      onMouseLeave={() => changeTooltipState(false)}
      className={styles.tooltipContainer}
      data-testid="tooltipContainer"
    >
      {children}
      {showTooltip && (
        <div className={styles.tooltip} data-testid="tooltip">
          {content}
        </div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  content: PropTypes.string,
};

export default Tooltip;
