import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { format, parse } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import './DateRangePicker.css';

function DateRangePicker({ startDate, endDate, onChange }) {
  const [selectedStartDate, setSelectedStartDate] = useState(parse(startDate, 'yyyy-MM-dd', new Date()));
  const [selectedEndDate, setSelectedEndDate] = useState(parse(endDate, 'yyyy-MM-dd', new Date()));
  const onDateChange = (dates) => {
    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);
    if (start && end) {
      onChange({ start, end });
    }
  };

  const onClickOutside = () => {
    if (!selectedEndDate && selectedStartDate) {
      setSelectedEndDate(selectedStartDate);
      onChange({ start: selectedStartDate, end: selectedStartDate });
    }
  };

  return (
    <DatePicker
      selected={selectedEndDate}
      onChange={onDateChange}
      onClickOutside={onClickOutside}
      startDate={selectedStartDate}
      endDate={selectedEndDate}
      className="datePicker__overview"
      shouldCloseOnSelect={false}
      selectsRange
      allowSameDay
      todayButton="Today"
      value={`${format(selectedStartDate, 'MMM dd, yyyy')} - ${format(selectedEndDate || selectedStartDate, 'MMM dd, yyyy')}`}
    />
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateRangePicker;
