import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MType from '../../typography/MType';

// TO-DO: ICON SUPPORT TO BE ADDED
const BasicButton = ({
  children, to, onClick, className, secondary, ...props
}) => {
  if (!to && !onClick) throw new Error('Button requires a to or an onClick attribute');

  const linkWrapper = (innerArea) => (
    <Link to={to} className="no-underline" data-testid="basic-button-a">
      {innerArea}
    </Link>
  );

  const localButton = (
    <button
      data-testid={props['data-testid']}
      onClick={onClick}
      type="button"
      className={`outline beac-button center-children border-rd-2 px-3 ${!secondary ? 'beac-button-primary' : 'beac-button-secondary'} ${className}`}
    >
      <MType>
        {children}
      </MType>
    </button>
  );

  return to ? linkWrapper(localButton) : localButton;
};

BasicButton.defaultProps = {
  children: undefined,
  to: undefined,
  onClick: undefined,
  className: '',
  secondary: false,
};

BasicButton.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  secondary: PropTypes.bool,
};

export default BasicButton;
