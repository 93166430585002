import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getGroupedTenants } from '../../../api/getGlobalReport';
import { fetchGroupedTenantsAction } from '../../../actions/TenantActions';
import { getSumData, getRelativeChange } from '../../../utils/reporting';

function getMetricColumns(columns) {
  const metricColumns = [
    'clicks',
    'clicksPrevYear',
    'impressions',
    'impressionsPrevYear',
    'conversions',
    'conversionsPrevYear',
    'cost',
    'costPrevYear',
  ];

  const columnsToIgnore = [
    'clientName',
    'activeStatus',
    'contentStrategist',
    'dmStatus',
    'packages',
    'digitalSuccessSpecialist',
    'clickThroughRate',
    'clickThroughRatePrevYear',
  ];
  columns.forEach((metric) => {
    if (!columnsToIgnore.includes(metric)) {
      metricColumns.push(metric);
      metricColumns.push(`${metric}PrevYear`);
    }
  });
  return metricColumns;
}

function getFilteredData(data, item) {
  const column = item;
  const filteredArray = data.filter((element) => element[column] >= 0);
  return filteredArray;
}

function getDataTotals(data, metricSumColumns) {
  const totals = {};
  if (data && data.length > 0) {
    metricSumColumns.forEach((item) => {
      totals[item] = getSumData(getFilteredData(data, item), item);
    });
  }

  if (totals.dmMrr && totals.dmMrrPrevYear) {
    totals.dmMrrRelativeChangePrevYear = getRelativeChange(totals.dmMrr, totals.dmMrrPrevYear);
  }

  if (totals.clicks && totals.impressions) {
    totals.clickThroughRate = totals.clicks / totals.impressions;
  }

  if (totals['30DayLeads'] && totals['30DayLeadsPrevYear']) {
    totals['30DayLeadsRelativeChangePrevYear'] = getRelativeChange(totals['30DayLeads'], totals['30DayLeadsPrevYear']);
  }

  if (totals['30DayCampaignCount'] && totals['30DayCampaignCountPrevYear']) {
    totals['30DayCampaignCountRelativeChangePrevYear'] = getRelativeChange(totals['30DayCampaignCount'], totals['30DayCampaignCountPrevYear']);
  }

  if (totals.totalMediaSpend && totals.totalMediaSpendPrevYear) {
    totals.totalMediaSpendRelativeChangePrevYear = getRelativeChange(
      totals.totalMediaSpend,
      totals.totalMediaSpendPrevYear,
    );
  }

  if (totals.googleAdsMediaSpend && totals.googleAdsMediaSpendPrevYear) {
    totals.googleAdsMediaSpendRelativeChangePrevYear = getRelativeChange(
      totals.googleAdsMediaSpend,
      totals.googleAdsMediaSpendPrevYear,
    );
  }

  if (totals.facebookAdsMediaSpend && totals.facebookAdsMediaSpendPrevYear) {
    totals.facebookAdsMediaSpendRelativeChangePrevYear = getRelativeChange(
      totals.facebookAdsMediaSpend,
      totals.facebookAdsMediaSpendPrevYear,
    );
  }

  if (totals.clicksPrevYear && totals.impressionsPrevYear) {
    totals.clickThroughRatePrevYear = totals.clicksPrevYear / totals.impressionsPrevYear;
  }

  if (totals.clickThroughRate && totals.clickThroughRatePrevYear) {
    totals.clickThroughRateRelativeChangePrevYear = getRelativeChange(
      totals.clickThroughRate,
      totals.clickThroughRatePrevYear,
    );
  }

  if (totals.conversions && totals.clicks) {
    totals.conversionRate = totals.conversions / totals.clicks;
  }

  if (totals.conversionsPrevYear && totals.clicksPrevYear) {
    totals.conversionRatePrevYear = totals.conversionsPrevYear / totals.clicksPrevYear;
  }

  if (totals.conversionRate && totals.conversionsPrevYear) {
    totals.conversionRateRelativeChangePrevYear = getRelativeChange(
      totals.conversionRate,
      totals.conversionsPrevYear,
    );
  }

  if (totals['30DayLeads'] && totals.dmMrr && totals.totalMediaSpend) {
    totals['30DayCpl'] = (totals.dmMrr + totals.totalMediaSpend) / totals['30DayLeads'];
  }

  if (totals['30DayLeadsPrevYear'] && totals.dmMrrPrevYear && totals.totalMediaSpendPrevYear) {
    totals['30DayCplPrevYear'] = (totals.dmMrrPrevYear + totals.totalMediaSpendPrevYear) / totals['30DayLeadsPrevYear'];
  }

  if (totals['30DayLeads'] && totals['30DayLeadsPrevYear']) {
    totals['30DayCplRelativeChangePrevYear'] = getRelativeChange(
      totals['30DayCpl'],
      totals['30DayCplPrevYear'],
    );
  }

  if (totals.conversions && totals.conversionsPrevYear) {
    totals.conversionsRelativeChangePrevYear = getRelativeChange(
      totals.conversions,
      totals.conversionsPrevYear,
    );
  }

  return totals;
}

const useClientData = (columns, groupBy, data = {}) => {
  const metricSumColumns = getMetricColumns(columns);
  const dispatch = useDispatch();
  const totals = useMemo(
    () => getDataTotals(data, metricSumColumns),
    [data, metricSumColumns],
  );
  const totalsData = {
    totalRowsData: {
      rows: [{ ...totals, table: 'Clients' }],
    },
  };
  useEffect(() => {
    Promise.all([
      getGroupedTenants('contentStrategist'),
      getGroupedTenants('digitalSuccessSpecialist'),
      getGroupedTenants('dmPackage'),

    ])
      .then(() => {
        dispatch(fetchGroupedTenantsAction('contentStrategist'));
        dispatch(fetchGroupedTenantsAction('digitalSuccessSpecialist'));
        dispatch(fetchGroupedTenantsAction('dmPackage'));
      });
  }, [dispatch]);

  return totalsData;
};

export default useClientData;
