import Axios from 'axios';
import { getAuthHeader } from './reqHelpers';

class AxiosClient {
  constructor() {
    this.client = Axios.create();
  }

  async get(url) {
    const headers = await getAuthHeader();
    return this.client.get(url, { headers });
  }
}

const axiosClient = new AxiosClient();

export default axiosClient;
