const reportKey = {
  websiteTraffic: 'website-traffic',
};

export const websitePerformanceReqTypes = {
  websiteTraffic: {
    base: reportKey.websiteTraffic,
  },
};

export default websitePerformanceReqTypes;
