import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default function BiaxialBarChart({
  data, metrics, leftYAxisName, rightYAxisName,
}) {
  const tooltipLabelFormatter = (value, name) => {
    const metric = metrics.find((m) => m.label === name);
    return ['formatter' in metric ? metric.formatter(Number(value)) : value, name];
  };

  return (
    <ResponsiveContainer id="biaxial-bar-chart" width="100%" height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xAxis" />
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
        <Tooltip formatter={tooltipLabelFormatter} />
        <Legend />
        <Bar yAxisId="left" name={leftYAxisName} dataKey="leftYAxis" fill="#8884d8" />
        <Bar yAxisId="right" name={rightYAxisName} dataKey="rightYAxis" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
}

BiaxialBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    xAxis: PropTypes.string.isRequired,
    leftYAxis: PropTypes.number.isRequired,
    rightYAxis: PropTypes.number.isRequired,
  })).isRequired,
  metrics: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    aggregationMethod: PropTypes.string,
    formatter: PropTypes.function,
  })).isRequired,
  leftYAxisName: PropTypes.string.isRequired,
  rightYAxisName: PropTypes.string.isRequired,
};
