import {
  format,
  subYears,
  subDays,
  differenceInDays,
  parse,
  parseISO,
  getQuarter,
} from 'date-fns';

const COMPARISON_PERIOD_TYPES = {
  previousPeriod: 'previousPeriod',
  previousYear: 'previousYear',
};

/**
 * Retrieves a number that represents the percentage
 * change from the previousValue to currentValue
 *
 * ## Sample Usage:
 *
 * `getPercentageChange(120, 100)` returns `20`
 * @param {number} currentValue
 * @param {number} previousValue
 */
function getPercentageChange(currentValue, previousValue) {
  if (previousValue === 0) {
    if (currentValue === 0) {
      return 0;
    }

    return 100;
  }

  return ((currentValue - previousValue) / previousValue) * 100;
}

/**
 * TO-DOs
 * - Write unit test
 * - Swap this method for getPercentageChange where appropriate
 * Retrieves a number that represents the change
 * from the previousValue to currentValue
 *
 * ## Sample Usage:
 *
 * `getChange(246, 413)` returns `-40.44`
 * @param {number} currentValue
 * @param {number} previousValue
 */
function getChange(currentValue, previousValue) {
  if (currentValue === 0 && previousValue > 0) {
    return -0.1;
  }
  if (previousValue === 0) {
    if (currentValue === 0) {
      return 0;
    }
    return 0.1;
  }

  return (currentValue - previousValue) / previousValue;
}

/**
 * Formats a Date object as a ISO8601 Complete Date
 * 'YYYY-MM-DD'
 *
 * ## Sample Usage:
 *
 * `formatAsISO8601CompleteDate(new Date(2019, 01, 01))` returns `'2019-01-01'`
 * @param {Date} date
 */
function formatAsISO8601CompleteDate(date) {
  return date.toISOString().slice(0, 10);
}

/**
 * Returns the corresponding previous period of time.
 *
 * ## Sample Usage:
 * `getPreviousPeriod('2020-01-01', '2020-01-31')` returns
 * `{startDate: '2019-12-01', endDate: '2019-12-31' }`
 * @param {string} startDateString formatted as 'YYYY-MM-DD'
 * @param {string} endDateString formatted as 'YYYY-MM-DD'
 */
function getPreviousPeriod(startDateString, endDateString) {
  const startDate = parse(startDateString, 'yyyy-MM-dd', new Date());
  const endDate = parse(endDateString, 'yyyy-MM-dd', new Date());
  const delta = differenceInDays(endDate, startDate);

  return {
    startDate: format(subDays(startDate, delta + 1), 'yyyy-MM-dd'),
    endDate: format(subDays(startDate, 1), 'yyyy-MM-dd'),
  };
}

/**
 * Returns the corresponding period of time of last year.
 *
 * ## Sample Usage:
 * `getPreviousYear('2020-01-01', '2020-01-31')` returns
 * `{startDate: '2019-01-01', endDate: '2019-01-31' }`
 * @param {string} startDateString formatted as 'YYYY-MM-DD'
 * @param {string} endDateString formatted as 'YYYY-MM-DD'
 */
function getPreviousYear(startDateString, endDateString) {
  return {
    startDate: format(
      subYears(parse(startDateString, 'yyyy-MM-dd', new Date()), 1),
      'yyyy-MM-dd',
    ),
    endDate: format(
      subYears(parse(endDateString, 'yyyy-MM-dd', new Date()), 1),
      'yyyy-MM-dd',
    ),
  };
}

/**
 * Returns the corresponding day of last year.
 *
 * ## Sample Usage:
 * `getPreviousYear('2020-01-01')` returns
 * `'2019-01-01'`
 * @param {string} dateString formatted as 'YYYY-MM-DD'
 */
function getPreviousYearDate(dateString) {
  const prevYearDate = new Date(Date.parse(dateString));

  const previousYearDate = new Date(
    Date.UTC(
      prevYearDate.getUTCFullYear() - 1,
      prevYearDate.getUTCMonth(),
      prevYearDate.getUTCDate(),
    ),
  );

  return formatAsISO8601CompleteDate(previousYearDate);
}

/**
 * Format seconds in a way of mm:ss
 *
 * @param seconds
 */
function formatSeconds(seconds) {
  if (typeof seconds !== 'number') {
    return '-';
  }
  const m = parseInt(seconds / 60, 0);
  const s = parseInt(seconds % 60, 0);

  return `${m < 9 ? '0' : ''}${m}:${s < 9 ? '0' : ''}${s}`;
}

/**
 * Format a number as a decimal value
 *
 * @param {number} value
 * @param {number} numberOfDecimalPlaces defaults to 1
 */
function formatDecimalValue(value, numberOfDecimalPlaces = 1) {
  if (typeof value !== 'number') {
    return '-';
  }

  return value.toFixed(numberOfDecimalPlaces);
}

function groupByDate(data) {
  if (!data || data.length === 0) {
    return [];
  }
  const rowKeys = Object.keys(data[0]);
  const groupedByDate = data.reduce((acc, value) => {
    if (!acc[value.date]) {
      acc[value.date] = [];
    }

    acc[value.date].push(value);

    return acc;
  }, {});

  const result = [];
  const groupedByDateEntries = Object.entries(groupedByDate);
  for (let i = 0; i < groupedByDateEntries.length; i += 1) {
    const key = groupedByDateEntries[i][0];
    if (Object.prototype.hasOwnProperty.call(groupedByDate, key)) {
      const groupedValues = groupedByDateEntries[i][1];
      const aggregatedValue = { date: key };
      rowKeys.forEach((rowKey) => {
        if (rowKey !== 'date') {
          aggregatedValue[rowKey] = groupedValues.reduce(
            (acc, value) => acc + value[rowKey],
            0,
          );
        }
      });

      result.push(aggregatedValue);
    }
  }

  return result;
}

/**
 * Returns data filtered by a date range
 * @param {*} data list of dicts with a 'date' field available for each record
 * @param {*} period dict specifying startDate and endDate
 */
function filterByPeriod(data, period) {
  return data
    ? data.filter(
      (row) => row.date >= period.startDate && row.date <= period.endDate,
    )
    : [];
}

/**
 * Returns a simple compare function that defines an ascending sort order
 * by the property passed in by name. This function can be passed in to
 * [sort an array](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort).
 *
 * ## Sample Usage:
 * ```
 * let items = [{name: 'Michael'}, {name: 'Smara'}, {name: 'Andrew'}, {name: 'Lally'}]
 * items.sort(sortBy('name))
 * ```
 * will yield the following array:
 * ```
 * [{name: 'Andrew'}, {name: 'Lally'}, {name: 'Michael'}, {name: 'Smara'}]
 * ```
 * @param {string} name The name of the field to compare
 */
function sortBy(name) {
  return (a, b) => {
    if (a[name] < b[name]) {
      return -1;
    }

    if (a[name] > b[name]) {
      return 1;
    }

    return 0;
  };
}

/**
 * Format a percentage value such as 0.5 and 50%
 * @param {number} value
 */
function formatPercentage(value, numberOfDecimalPlaces = 1) {
  // eslint-disable-next-line no-restricted-globals
  if (typeof value !== 'number' || value === Infinity || isNaN(value)) {
    return '-';
  }

  let fractionDigits = numberOfDecimalPlaces;
  const percentageValue = value * 100;
  if (Math.floor(percentageValue) === percentageValue) {
    fractionDigits = 0;
  }

  if (value === 100) {
    return `${value.toFixed(fractionDigits)}%`;
  }

  return `${percentageValue.toFixed(fractionDigits)}%`;
}

/**
 * Returns a language representation of the provided date
 *
 * @param {string} date formatted as 'YYYY-MM-DD'
 * ## Sample Usage:
 * `formatSelectedDate('2020-05-13')` returns `May 13, 2020`
 */
function formatSelectedDate(date) {
  const utcDayDateTime = new Date(date).toUTCString();
  const utcDateTime = utcDayDateTime.split(',')[1];
  const splitUtcDateTime = utcDateTime.split(' ');
  const month = splitUtcDateTime[2];
  const day = splitUtcDateTime[1];
  const year = splitUtcDateTime[3];
  return `${month} ${day}, ${year}`;
}

/**
 * Gets the sum of metric over the period of time.
 *
 * getAvgDataOverPeriodOfTime([
 *     { date: '2020-01-01', sessions: 100 },
 *     { date: '2020-01-02', sessions: 100 },
 *     { date: '2020-01-03', sessions: 100 },
 * ],
 * '2020-01-01',
 * '2020-01-02',
 * 'sessions')
 *
 * @param rows
 * @param startDate
 * @param endDate
 * @param metric
 * @returns {*}
 */
function getSumDataOverPeriodOfTime(rows, startDate, endDate, metric) {
  if (rows && metric) {
    const filteredRows = rows.filter(
      (row) => row.date
        && row.date >= startDate
        && row.date <= endDate
        && metric in row,
    );

    if (filteredRows.length) {
      return filteredRows.reduce(
        (total, currentRow) => total + currentRow[metric],
        0,
        0,
      );
    }
  }

  return null;
}

/**
 * Returns the sum of metric.
 *
 * @param rows
 * @param metric
 * @returns {*}
 */
function getSumData(rows, metric) {
  if (rows && metric) {
    if (rows.length) {
      return rows.reduce(
        (total, currentRow) => total + currentRow[metric],
        0,
        0,
      );
    }
  }

  return null;
}

/**
 * Gets the average of metric over period of time.
 *
 * getAvgDataOverPeriodOfTime([
 *     { date: '2020-01-01', sessions: 100 },
 *     { date: '2020-01-02', sessions: 100 },
 *     { date: '2020-01-03', sessions: 100 },
 * ],
 * '2020-01-01',
 * '2020-01-02',
 * 'sessions')
 *
 * @param rows
 * @param startDate
 * @param endDate
 * @param metric
 *
 * @returns {number}
 */
function getAvgDataOverPeriodOfTime(rows, startDate, endDate, metric) {
  if (rows && metric) {
    const filteredRows = rows.filter(
      (row) => row.date
        && row.date >= startDate
        && row.date <= endDate
        && metric in row,
    );

    if (filteredRows.length) {
      const sum = filteredRows.reduce(
        (total, currentRow) => total + currentRow[metric],
        0,
        0,
      );
      return sum / filteredRows.length;
    }
  }

  return null;
}

/**
 * Gets the ration of metricA and metricB over period of time. In other words it follows
 * formula: sum(metricA)/sum(metricB).
 *
 * getAvgDataOverPeriodOfTime([
 *     { date: '2020-01-01', pageViews: 123, sessions: 100 },
 *     { date: '2020-01-02', pageViews: 123, sessions: 100 },
 *     { date: '2020-01-03', pageViews: 123, sessions: 100 },
 * ],
 * '2020-01-01',
 * '2020-01-02',
 * 'pageViews',
 * 'sessions')
 *
 * @param rows
 * @param startDate
 * @param endDate
 * @param metricA
 * @param metricB
 *
 * @returns {number}
 */
function getRatioDataOverPeriodOfTime(
  rows,
  startDate,
  endDate,
  metricA,
  metricB,
) {
  if (rows && metricA && metricB) {
    const filteredRows = rows.filter(
      (row) => row.date
        && row.date >= startDate
        && row.date <= endDate
        && metricA in row
        && metricB in row,
    );

    if (filteredRows.length) {
      const sumMetricA = filteredRows.reduce(
        (total, currentRow) => total + currentRow[metricA],
        0,
        0,
      );

      const sumMetricB = filteredRows.reduce(
        (total, currentRow) => total + currentRow[metricB],
        0,
        0,
      );

      return sumMetricA / sumMetricB;
    }
  }

  return null;
}

/**
 * Filters data by channel
 *
 * @param rows
 * @param channel
 * @returns {*}
 */
function filterDataByChannel(rows, channel) {
  if (rows) {
    const filteredRows = rows.filter((row) => row.channel === channel);
    if (filteredRows.length) {
      return filteredRows;
    }
  }

  return [];
}

/**
 * Format a currency value 9.488 => $9.48
 * @param {number} value
 */
function formatCurrency(value) {
  if (typeof value !== 'number') {
    return '-';
  }

  let fractionDigits = 2;
  if (Math.floor(value) === value) {
    fractionDigits = 0;
  }

  const roundedValue = formatDecimalValue(value, fractionDigits);
  return `$${roundedValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}`;
}

/**
 * Format a ISO8601 date string as a month 2016-09-01T00:00:00 => Sept 2016
 * @param {string} dateString
 */
function formatMonthAbbrevYear(dateString) {
  return format(parseISO(dateString), 'MMM yyyy');
}

/**
 * Format a ISO8601 date string as a month 2016-09-01T00:00:00 => 2016-09
 * @param {string} dateString
 */
function formatYearMonth(dateString) {
  return format(parseISO(dateString), 'yyyy-MM');
}

/**
 * Format a ISO8601 date string as a month 2016-09-01T00:00:00 => 2016-09
 * @param {string} dateString
 */
function formatMonthAbbrev(dateString) {
  if (parseISO(dateString).toString() === 'Invalid Date') {
    return 'Invalid Date';
  }
  return format(parseISO(dateString), 'MMM');
}

/**
 * Format a ISO8601 date string as a month 2016-09-01T00:00:00 => 2016
 * @param {*} dateString
 */
function formatYear(dateString) {
  return format(parseISO(dateString), 'yyyy');
}

/**
 * Format a date string as 2016-09-01 => May 09, 2016
 * @param {string} dateString
 */
function formatMonthAbbrevDayYear(dateString) {
  return format(parse(dateString, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy');
}

/**
 * Format a date string as 2020-10-01 => Quarter 4
 * @param {string} dateString
 */
function formatDateAsQuarter(dateString) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  const quarter = getQuarter(date);
  return `Quarter ${quarter}`;
}

/**
 * Formats a number as in the user's locale
 * @param {Number} value
 */
function formatLocalizedNumber(value) {
  if (typeof value !== 'number') {
    return '-';
  }

  return value ? value.toLocaleString() : '-';
}
/**
 * Calculates relative change given current
 * and comparison period
 * @param {int} currentValue
 * @param {int} previousValue
 */
function getRelativeChange(currentValue, previousValue) {
  if (typeof currentValue !== 'number' || typeof previousValue !== 'number') {
    return '-';
  }
  if (currentValue > 0 && previousValue === 0) {
    return 1;
  }

  if ((currentValue - previousValue) !== 0) { return (currentValue / previousValue) - 1; }

  return 0;
}

export {
  COMPARISON_PERIOD_TYPES,
  filterByPeriod,
  filterDataByChannel,
  formatAsISO8601CompleteDate,
  formatCurrency,
  formatDecimalValue,
  formatLocalizedNumber,
  formatMonthAbbrev,
  formatMonthAbbrevYear,
  formatMonthAbbrevDayYear,
  formatYear,
  formatYearMonth,
  formatPercentage,
  formatSeconds,
  formatSelectedDate,
  getChange,
  getPercentageChange,
  getPreviousPeriod,
  getPreviousYear,
  sortBy,
  getAvgDataOverPeriodOfTime,
  getPreviousYearDate,
  getRatioDataOverPeriodOfTime,
  getSumData,
  getSumDataOverPeriodOfTime,
  groupByDate,
  formatDateAsQuarter,
  getRelativeChange,
};
