import { formatMonthAbbrevDayYear } from '../../utils/reporting';

/**
 * Returns array of objects - one containing current CPL data
 * and the other containing data from comparison period
 *
 * @param costPerLeadData
 * @param periodKey
 * @param currentComparisonPeriod
 */
export default function groupCPLData(
  costPerLeadData, period, comparisonPeriod, currentComparisonPeriod,
) {
  if (costPerLeadData.length === 1) {
    const groupedCPLData = { rows: [] };
    const periodKey = comparisonPeriod === 'previousYear' ? 'prevYear' : 'prevPeriod';
    const lowerCaseFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1);
    const comparisonData = {
      period: `${formatMonthAbbrevDayYear(currentComparisonPeriod.startDate)} - ${formatMonthAbbrevDayYear(currentComparisonPeriod.endDate)}`,
    };
    for (let i = 0; i < costPerLeadData.length; i += 1) {
      const item = { ...costPerLeadData[i] };
      Object.keys(item).forEach((key) => {
        if (key.includes(periodKey)) {
          comparisonData[lowerCaseFirstLetter(key.replace(periodKey, ''))] = item[key];
        }
      });
    }
    groupedCPLData.rows.push(costPerLeadData[0], comparisonData);
    groupedCPLData.rows[0].period = `${formatMonthAbbrevDayYear(period.startDate)} - ${formatMonthAbbrevDayYear(period.endDate)}`;
    return groupedCPLData.rows;
  }
  return null;
}
