/* eslint-disable import/prefer-default-export */

export const callFunctionOnBlur = (func, allBrowsers) => {
  if (allBrowsers || !(/(Chrome|Edg)/.test(window.navigator.userAgent))) {
    const blurHandler = (e) => setImmediate(() => {
      if (!e.defaultPrevented) {
        func();
      }
    });
    document.body.addEventListener('click', blurHandler);
    return () => {
      document.body.removeEventListener('click', blurHandler);
    };
  }
  return undefined;
};
