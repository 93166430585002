import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DropdownFloatingMenuItem from './DropdownFloatingMenuItem';
import cx from 'classnames';
import styles from './styles.module.scss';

const DropdownFloatMenu = ({ options, selectedOption, setOptionState }) => {
  const dropdownParent = useRef();
  const [slideDown, setSlideDown] = useState(true);

  const getPositionRatio = useCallback(() => {
    if (dropdownParent.current) {
      const { y: distanceFromTop } =
        dropdownParent.current.getBoundingClientRect();
      return distanceFromTop / window.innerHeight;
    }
    return 1;
  }, []);

  const scrollHandler = useCallback(() => {
    if (getPositionRatio() > 0.75) {
      setSlideDown(false);
    } else {
      setSlideDown(true);
    }
  }, [getPositionRatio]);

  useEffect(() => {
    scrollHandler();
    document.addEventListener('scroll', scrollHandler);

    return document.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
    <div
      data-testid="basic-dropdown-options"
      className={cx(
        `border-2 border-light-grey border-rd-3 position-absolute bg-white`,
        slideDown ? 'slide-down' : 'slide-up',
        styles.floatingMenuContainer,
      )}
      ref={dropdownParent}
      style={{
        padding: 4,
        zIndex: 9,
      }}
    >
      <div className={styles.sortBy}>Sort By</div>
      {options.map((option, index) => (
        <DropdownFloatingMenuItem
          key={option.value}
          option={option}
          selected={selectedOption && option.value === selectedOption.value}
          last={index === options.length - 1}
          setState={setOptionState}
        />
      ))}
    </div>
  );
};

DropdownFloatMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      onClick: PropTypes.func,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  selectedOption: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
  }),
  setOptionState: PropTypes.func,
};

DropdownFloatMenu.defaultProps = {
  selectedOption: undefined,
  setOptionState: () => undefined,
};

export default DropdownFloatMenu;
