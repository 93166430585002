import React from 'react';
import PropTypes from 'prop-types';

function YouTube(props) {
  const { onClick = () => {}, className = '' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
      onClick={onClick}
      data-testid="YouTube"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#EB4335"
              d="M6.41 0h11.18c2.23 0 3.037.232 3.852.668.815.436 1.454 1.075 1.89 1.89.436.815.668 1.623.668 3.852v5.18c0 2.23-.232 3.037-.668 3.852-.436.815-1.075 1.454-1.89 1.89-.815.436-1.623.668-3.852.668H6.41c-2.23 0-3.037-.232-3.852-.668-.815-.436-1.454-1.075-1.89-1.89C.232 14.627 0 13.82 0 11.59V6.41c0-2.23.232-3.037.668-3.852.436-.815 1.075-1.454 1.89-1.89C3.373.232 4.18 0 6.41 0z"
              transform="translate(-220.000000, -1299.000000) translate(220.000000, 1299.000000)"
            />
            <path
              fill="#FFF"
              d="M13 5L17 13 9 13z"
              transform="translate(-220.000000, -1299.000000) translate(220.000000, 1299.000000) translate(13.000000, 9.000000) rotate(-270.000000) translate(-13.000000, -9.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

YouTube.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export { YouTube };
