import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MType from '../../typography/MType';
import XSType from '../../typography/XSType';

const SuggestionListItem = ({
  obj, index: i, highlightOption, setHighlightOptionFunc, addToFilter, inputText,
}) => {
  const [highlightedArray, setHighlightedArray] = useState([]);

  useEffect(() => {
    const regex = new RegExp(`^(.*)(${inputText})(.*)`, 'i');
    const regexArray = regex.exec(obj.value);
    setHighlightedArray(regexArray || []);
  }, [inputText, obj.value]);

  return highlightedArray.length > 0 && (
    <button
      type="button"
      data-testid="suggestion-list"
      className={`py-1 px-3 center-children border-0 text-left w-100 border-rd-2 ${
        i === highlightOption ? 'low-opacity-primary' : 'bg-white'}`}
      onMouseEnter={() => setHighlightOptionFunc(i)}
      onMouseDown={(ev) => ev.preventDefault()}
      onClick={() => addToFilter(obj)}
    >
      <div
        style={{ textDecoration: i === highlightOption ? 'underline' : 'none' }}
        className="txt-sz-15 w-100 py-2 text-muted"
      >
        <MType light className="d-inline">
          {highlightedArray[1]}
        </MType>
        <MType className="text-dark d-inline">
          {highlightedArray[2]}
        </MType>
        <MType light className="d-inline">
          {highlightedArray[3]}
        </MType>
      </div>
      <XSType
        style={{ marginLeft: 'auto' }}
        className="text-muted text-uppercase"
      >
        {obj.filterObj.name}
      </XSType>
    </button>
  );
};

SuggestionListItem.propTypes = {
  obj: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    filterObj: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
  highlightOption: PropTypes.number.isRequired,
  setHighlightOptionFunc: PropTypes.func.isRequired,
  addToFilter: PropTypes.func.isRequired,
  inputText: PropTypes.string.isRequired,
};

export default SuggestionListItem;
