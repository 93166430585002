import React from 'react';
import PropTypes from 'prop-types';
import SingleTab from './SingleTab';
import SinglePill from './SinglePill';

const HorizontalMenu = ({ tabs, pill, className }) => {
  if (!tabs.length) {
    return null;
  }

  const Component = pill ? SinglePill : SingleTab;

  return (
    <ul className={`nav ${className}`}>
      {tabs.map((tab) => (
        <Component
          key={tab.name}
          to={tab.to}
          active={tab.active}
          datatestid={tab.testId}
        >
          {tab.name}
        </Component>
      ))}
    </ul>
  );
};

HorizontalMenu.defaultProps = {
  tabs: [],
  pill: false,
  className: '',
};

HorizontalMenu.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.string,
    active: PropTypes.bool,
    testId: PropTypes.string,
  })),
  pill: PropTypes.bool,
  className: PropTypes.string,
};

export default HorizontalMenu;
