import axiosClient from './axiosClient';

export async function getCampaignsReports(tenantId, reportType, dates, dimensions) {
  const url = `${process.env.REACT_APP_REPORTING_API_BASE_URL}/v1/tenants/${tenantId}/reports/${reportType}?filter[date]=gt,${dates.startDate}&filter[date]=lt,${dates.endDate}&dimensions=${dimensions}`;

  return axiosClient.get(url);
}

export async function getCampaignsByChannel(tenantId, reportType, dates) {
  const url = `${process.env.REACT_APP_REPORTING_API_BASE_URL}/v1/tenants/${tenantId}/reports/${reportType}?filter[date]=gt,${dates.startDate}&filter[date]=lt,${dates.endDate}&dimensions=channel`;

  return axiosClient.get(url);
}

export async function getCampaignsByDate(tenantId, reportType, dates) {
  const url = `${process.env.REACT_APP_REPORTING_API_BASE_URL}/v1/tenants/${tenantId}/reports/${reportType}?filter[date]=gt,${dates.startDate}&filter[date]=lt,${dates.endDate}&dimensions=date`;

  return axiosClient.get(url);
}
