import React from 'react';
import PropTypes from 'prop-types';

function GoogleSearch(props) {
  const { onClick = () => {}, className='' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      onClick={onClick}
      data-testid="GoogleSearch"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#4285F4"
              fillRule="nonzero"
              d="M16.474 13.853L23.038 20.416 20.916 22.538 14.353 15.974z"
              transform="translate(-176.000000, -1296.000000) translate(176.000000, 1296.000000)"
            />
            <path
              fill="#32A450"
              d="M18.977 9.477c0 4.97-4.03 9-9 9-3.397 0-6.355-1.882-7.887-4.661l2.629-1.447c1.021 1.853 2.993 3.108 5.258 3.108 3.314 0 6-2.686 6-6h3z"
              transform="translate(-176.000000, -1296.000000) translate(176.000000, 1296.000000)"
            />
            <path
              fill="#FBBC05"
              d="M2.202 4.941l2.695 1.342c-.583.925-.92 2.02-.92 3.194 0 1.212.36 2.34.977 3.283l-2.699 1.343C1.444 12.75.977 11.168.977 9.477c0-1.536.385-2.982 1.063-4.247l.162-.289z"
              transform="translate(-176.000000, -1296.000000) translate(176.000000, 1296.000000)"
            />
            <path
              fill="#EB4335"
              d="M15.977 9.477c0-3.314-2.686-6-6-6-2.073 0-3.9 1.05-4.978 2.649l-.102.157-2.695-1.342C3.763 2.27 6.661.477 9.977.477c4.97 0 9 4.03 9 9h-3z"
              transform="translate(-176.000000, -1296.000000) translate(176.000000, 1296.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

GoogleSearch.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export { GoogleSearch };
