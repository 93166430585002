const clientPageUrls = {
  clientList: '/clients',
  leadsList: '/leads',
  benchmarking: '/benchmarking',
  seo: '/seo',
  healthCheck: '/health-check',
  general: '/clients/:tenantid/:page/:tab?',
  landing: '/clients/:tenantid/',
  overview: '/clients/:tenantid/overview/',
  overviewOrganic: '/clients/:tenantid/overview/organic',
  organic: '/clients/:tenantid/organic/',
  leads: '/clients/:tenantid/leads/',
  campaigns: '/clients/:tenantid/campaigns/',
};

export default clientPageUrls;
