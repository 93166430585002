import React from 'react';
import PropTypes from 'prop-types';
import MType from '../../typography/MType';

const DropdownFloatingMenuItem = ({
  option,
  selected,
  last,
  setState,
}) => {
  const isSelected = selected || option.selected;

  return (
    <button
      type="button"
      data-testid="dropdown-floating-menu-item"
      disabled={isSelected}
      className={`${isSelected ? 'bg-primary text-white' : 'bg-white'} border-rd-2 low-opacity-primary-hover`}
      style={{
        marginBottom: last ? 0 : 2, padding: '6px 9px 4px', border: 'none', width: '100%', textAlign: 'left',
      }}
      onMouseDown={(e) => e.nativeEvent.preventDefault()}
      onClick={(e) => {
        e.nativeEvent.preventDefault();
        if (option.onClick) option.onClick();
        else setState(option);
      }}
    >
      <MType light>
        {option.text}
      </MType>
    </button>
  );
};

DropdownFloatingMenuItem.propTypes = {
  option: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
  }).isRequired,
  selected: PropTypes.bool,
  last: PropTypes.bool,
  setState: PropTypes.func,
};

DropdownFloatingMenuItem.defaultProps = {
  selected: false,
  last: false,
  setState: () => undefined,
};

export default DropdownFloatingMenuItem;
