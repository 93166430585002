export const groupedByOptions = [
  { value: 'None', label: 'None' },
  { value: 'adNetwork', label: 'Ad Network' },
  { value: 'platform', label: 'Platform' },
];

export const comparisonPeriods = {
  prevYear: 'Previous Year',
  prevPeriod: 'Previous Period',
};

export const comparisonPeriodOptions = [
  { value: comparisonPeriods.prevYear, label: 'Previous Year' },
  { value: comparisonPeriods.prevPeriod, label: 'Previous Period' },
];

export const graphComparisonPeriodOptions = [
  { value: 'None', label: 'None' },
  {
    value: comparisonPeriods.prevYear,
    label: 'Previous Year',
    suffix: 'PrevYear',
  },
  {
    value: comparisonPeriods.prevPeriod,
    label: 'Previous Period',
    suffix: 'PrevPeriod',
  },
];

export const allMetrics = [
  {
    label: 'Spend',
    name: 'spend',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: true,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Impressions',
    name: 'impressions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Avg. CPC',
    name: 'avgCostPerClick',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: true,
    isMaxMetric: false,
    isCalculatedMetric: true,
  },
  {
    label: 'Clicks',
    name: 'clicks',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'CTR',
    name: 'clickThroughRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
  },
  {
    label: 'Conversions',
    name: 'conversions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Conv. Rate',
    name: 'conversionRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
  },
  {
    label: 'Calls',
    name: 'calls',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
];

export const allGoogleMetrics = [
  {
    label: 'Google Spend',
    name: 'Google-spend',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: true,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Google Impressions',
    name: 'Google-impressions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Google Avg. CPC',
    name: 'Google-avgCostPerClick',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: true,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Google Clicks',
    name: 'Google-clicks',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Google CTR',
    name: 'Google-clickThroughRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
  },
  {
    label: 'Google Conversions',
    name: 'Google-conversions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Google Conv. Rate',
    name: 'Google-conversionRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
  },
  {
    label: 'Google Calls',
    name: 'Google-calls',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
];

export const allFacebookMetrics = [
  {
    label: 'Facebook Spend',
    name: 'Facebook-spend',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: true,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Facebook Impressions',
    name: 'Facebook-impressions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Facebook Avg. CPC',
    name: 'Facebook-avgCostPerClick',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: true,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Facebook Clicks',
    name: 'Facebook-clicks',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Facebook CTR',
    name: 'Facebook-clickThroughRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
  },
  {
    label: 'Facebook Conversions',
    name: 'Facebook-conversions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Facebook Conv. Rate',
    name: 'Facebook-conversionRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
  },
  {
    label: 'Facebook Calls',
    name: 'Facebook-calls',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
];
