import clientPageUrls from '../routes/client';

export const clientTabPath = (path) => (id) => path.replace(':tenantid', id);

export const tenantPrimaryTabs = [
  {
    name: 'Overview',
    pathName: 'overview',
    path: clientTabPath(clientPageUrls.overview),
  },
  {
    name: 'Organic',
    pathName: 'organic',
    path: clientTabPath(clientPageUrls.organic),
  },
  {
    name: 'Leads',
    pathName: 'leads',
    path: clientTabPath(clientPageUrls.leads),
  },
  {
    name: 'Campaigns',
    pathName: 'campaigns',
    path: clientTabPath(clientPageUrls.campaigns),
  },
];

export default tenantPrimaryTabs;
