/* eslint-disable no-return-await */
import reportsUrls from '../resources/api-routes/reports';
import axiosClient from './axiosClient';

export const getGlobalReport = async () => {
  const url = process.env.REACT_APP_REPORTING_API_BASE_URL + reportsUrls.global;
  return await axiosClient.get(url);
};

export async function getGroupedTenants(dimensions) {
  const url = `${process.env.REACT_APP_REPORTING_API_BASE_URL}/v1/tenants/reports/accounts?dimensions=${dimensions}`;
  return await axiosClient.get(url);
}
