import React, { useState } from 'react';
import { EditPen } from 'components/common/icons';
import Modal from 'components/common/Modal';
import { TextInput } from 'components/common/inputs';
import cx from 'classnames';
import styles from './styles.module.scss';

const TextualizedFilters = ({ filteredBy, clientsNumber, onFiltersChange, oldFilters }) => {
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState(oldFilters);
  const { daysActive, cpl, leads } = filters;
  const closeModal = () => {
    setShowModal(false);
  };

  const onClickReset = () => {
    setFilters(oldFilters)
  };

  const onClickApply = () => {
    setShowModal(false);
    onFiltersChange(filters);
  };

  const handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;

    const newValue = isNaN(value) ? 0 : value;

    setFilters({
      ...filters,
      [name]: newValue,
    });
  };

  if (filteredBy.value === 'PoorSpendPerformance') {
    return (
      <div
        className={cx(
          styles.textedFilters,
          'bg-primary text-white center-children pl-3 pr-2 txt-sz-13 sm-pill fit-content mr-4 mb-2',
        )}
      >
        <div className={styles.filtersTextContainer}>
          <div>
            Showing <strong>{clientsNumber}</strong> clients where{' '}
            <strong>&lt; {leads} Leads</strong> and
            <strong> CPL &gt; ${cpl}</strong> and
            <strong> Days Active &gt; {daysActive}</strong>
          </div>
        </div>
        <EditPen
          className={styles.editPen}
          onClick={() => {
            setShowModal(true);
          }}
        />
        {showModal && (
          <Modal
            title="Edit Filter"
            onClose={closeModal}
            bottomLeftButtons={[
              {
                label: 'Cancel',
                onClick: closeModal,
              },
              { label: 'Reset', onClick: onClickReset },
            ]}
            bottomRightButtons={[
              {
                label: 'Apply',
                onClick: onClickApply,
              },
            ]}
          >
            <div className={styles.modalContent}>
              <h2 className={styles.filterTitle}>Poor Spend Performance</h2>
              <div className={styles.filterDescription}>
              Showing clients where{' '}
                <strong>&lt; {leads} Leads</strong> and{' '}
                <strong> CPL &gt; ${cpl}</strong> and
                <strong> Days Active &gt; {daysActive}</strong>
              </div>
              <div className={styles.filterInputsContainer}>
                <div className={styles.filterInputContainer}>
                  <div className={styles.filterInputTitle}>Max Leads # </div>
                  <TextInput
                    className={styles.filterInput}
                    name="leads"
                    value={filters.leads}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.filterInputContainer}>
                  <div className={styles.filterInputTitle}>
                    Min CPL ($)
                  </div>
                  <div>
                    <TextInput
                      className={styles.filterInput}
                      name="cpl"
                      value={filters.cpl}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className={styles.filterInputContainer}>
                  <div className={styles.filterInputTitle}>Min Days Active</div>
                  <TextInput
                    className={styles.filterInput}
                    name="daysActive"
                    value={filters.daysActive}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
  return null;
};

export default TextualizedFilters;
