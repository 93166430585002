export function groupAndOrderTableDataNames(groupedBy, dataArray) {
  if (groupedBy.value !== 'None') {
    const newGroupTableData = dataArray.reduce((acc, cur) => {
      const key = !cur[groupedBy.value] || cur[groupedBy.value].length === 0
        ? `No ${groupedBy.label}`
        : cur[groupedBy.value];

      if (Array.isArray(key)) {
        acc.push(...key);
      } else {
        acc.push(key);
      }

      return acc;
    }, []);

    const orderedTableData = [...new Set(newGroupTableData)].sort((a, b) => {
      if (a === `No ${groupedBy.label}`) {
        return 1;
      }
      if (b === `No ${groupedBy.label}`) {
        return -1;
      }
      return a.localeCompare(b);
    });
    return orderedTableData;
  }
  return [];
}
