import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

function TextInput({
  placeholder,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  error = false,
  dataTestId,
  className='',
  name,
  ...rest
}) {
  const [currentValue, setCurrentValue] = React.useState(value);

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setCurrentValue(value);
    onChange(e);
  };

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <input
      name={name}
      data-testid={rest['data-testid']}
      value={currentValue}
      className={cx(styles.textInput,className, error ? styles.error : '')}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={onBlur}
    />
  );
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
};

export { TextInput };
