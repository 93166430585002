import React, { useState } from 'react';
import BasicTable from 'components/common/table/BasicTable';
import BasicTablePagination from 'components/common/table/BasicTablePagination';
import { BasicDropdown } from 'components/common/inputs';
import PropTypes from 'prop-types';
import { rowsPerPageOptions } from './constants';

const BeaconTable = ({
  rowsPerPage,
  headers,
  table,
  Row,
  className = '',
  id = '',
}) => {
  const [selectedRowsPerPage, setRowsPerPage] = useState(
    rowsPerPageOptions.find((e) => e.value === rowsPerPage) ||
      rowsPerPageOptions[1],
  );

  React.useEffect(() => {
    if (table) table.setPageSize(selectedRowsPerPage.value);
  }, [selectedRowsPerPage, table]);

  const changeClientsPerPageHandler = (option) => {
    setRowsPerPage(option);
  };

  return (
    <div data-testid="beaconTable">
      <BasicTable headers={headers} className={className} id={id}>
        {table.page.map(({ original, values, cells }, index) => (
          <Row
            key={`${original.tenantId}-${index}`}
            original={original}
            values={values}
            cells={cells}
            columns={table.columns}
          />
        ))}
      </BasicTable>
      <div className="position-relative center-children mt-24 minh-40 mobile-flex-vert">
        {table.pageCount > 1 && (
          <div className="align-self-center">
            <BasicTablePagination table={table} />
          </div>
        )}
        <div className="position-absolute pos-t-0 pos-r-0 mobile-not-absolute mobile-spacing-top">
          <BasicDropdown
            setSelectedOption={changeClientsPerPageHandler}
            selectedOption={selectedRowsPerPage}
            options={rowsPerPageOptions}
          />
        </div>
      </div>
    </div>
  );
};

BeaconTable.propTypes = {
  data: PropTypes.array,
  removeColumn: PropTypes.string,
  rowsPerPage: PropTypes.number,
  Row: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
};

export default BeaconTable;
