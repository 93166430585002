import React, { useMemo, useState, useEffect } from 'react';
import { format, subDays, parse } from 'date-fns';
import { groupAndOrderTableDataNames } from 'utils';
import { getPreviousPeriod, getPreviousYear } from 'utils/reporting';
import useApplicationStateValue from 'utils/useApplicationStateValue';
import { groupedByOptions, comparisonPeriodOptions } from '../constants';
import useCampaignsData from './useCampaignsData';
import CampaignsColumns from '../CampaignsTable/CampaignsColumns';

export default function useCampaignsHook({ location, history }) {
  const [showModal, setShowModal] = useState(false);
  const [groupedBy, setGroupedBy] = useState(groupedByOptions[0]);

  const [campaignGroupBy, setCampaignGroupBy] = useApplicationStateValue(
    'groupBy',
    'None',
  );

  const [tableNames, setTableNames] = useState([]);

  const [startDate, onSetStartDate] = useApplicationStateValue(
    'startDate',
    format(subDays(Date.now(), 30), 'yyyy-MM-dd'),
  );

  const [showPrevPeriod, onSetShowPrevPeriod] = useApplicationStateValue(
    'showPrevPeriod',
    true,
  );
  const [showPrevYear, onSetShowPrevYear] = useApplicationStateValue(
    'showPrevYear',
    true,
  );

  const [endDate, onSetEndDate] = useApplicationStateValue(
    'endDate',
    format(Date.now(), 'yyyy-MM-dd'),
  );

  const [comparisonPeriod, setComparisonPeriod] = useApplicationStateValue(
    'campaignsComparisonPeriod',
    comparisonPeriodOptions[0].value,
  );

  const [dates, setDates] = React.useState({
    startDate: parse(startDate, 'yyyy-MM-dd', new Date()),
    endDate: parse(endDate, 'yyyy-MM-dd', new Date()),
  });

  const [comparisonPeriodCheckboxValues, setComparisonPeriodCheckboxValues] = React.useState({
    showPrevPeriod,
    showPrevYear,
  });

  const [disabledApplyButton, setDisabledApplyButton] = React.useState();

  const previousYear = useMemo(
    () => getPreviousYear(startDate, endDate),
    [startDate, endDate],
  );
  const previousPeriod = useMemo(
    () => getPreviousPeriod(startDate, endDate),
    [startDate, endDate],
  );

  let isDataGrouped = false;
  if (groupedBy.value !== 'None') {
    isDataGrouped = true;
  }

  const [data, nonGroupedData, isLoading, error, graphData] = useCampaignsData({
    startDate,
    endDate,
    isDataGrouped,
    groupedBy: groupedBy.value === 'None' ? 'adNetwork' : groupedBy.value,
  });

  useEffect(() => {
    if (data.rows && !isLoading) setTableNames(groupAndOrderTableDataNames(groupedBy, data.rows));
  }, [groupedBy, data.rows, isLoading]);

  const handleDateChange = (newStartDate, newEndDate) => {
    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const handleCheckboxChange = (newCheckboxValues) => {
    setComparisonPeriodCheckboxValues(newCheckboxValues);
  };

  const onDatesApply = () => {
    setShowModal(false);
    onSetStartDate(format(dates.startDate, 'yyyy-MM-dd'));
    onSetEndDate(format(dates.endDate, 'yyyy-MM-dd'));
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    onSetShowPrevPeriod(comparisonPeriodCheckboxValues.showPrevPeriod);
    onSetShowPrevYear(comparisonPeriodCheckboxValues.showPrevYear);
    setComparisonPeriodCheckboxValues({
      showPrevPeriod: comparisonPeriodCheckboxValues.showPrevPeriod,
      showPrevYear: comparisonPeriodCheckboxValues.showPrevYear,
    });
  };

  useEffect(() => {
    const newValue = groupedByOptions.find((x) => x.value === campaignGroupBy);
    if (newValue) {
      setGroupedBy(newValue);
    }
  }, [campaignGroupBy]);

  const handleDateReset = () => {
    setDates({
      startDate: parse(startDate, 'yyyy-MM-dd', new Date()),
      endDate: parse(endDate, 'yyyy-MM-dd', new Date()),
    });
  };

  const handleDatePickerError = () => {
    setDisabledApplyButton(true);
  };

  const handleDatePickerErrorResolve = () => {
    setDisabledApplyButton(false);
  };

  const handleChangeGroupedBy = (e) => {
    let searchParams = location.search;

    if (e.value !== 'None') {
      if (!searchParams.includes('groupBy')) {
        searchParams = searchParams.includes('?')
          ? `${searchParams}&groupBy=${e.value}`
          : `?groupBy=${e.value}`;
      }
    } else if (searchParams.includes('groupBy')) {
      searchParams = searchParams
        .split('&')
        .filter((param) => !param.includes('groupBy'))
        .join('&');
    }

    const path = `${location.pathname}${
      searchParams.includes('?') ? searchParams : `?${searchParams}`
    }`;
    history.replace(path);
    setCampaignGroupBy(e.value);
    setGroupedBy(e);
  };

  const getDataArray = (dataArray, dataKey) => {
    let comparisonKey = dataKey;

    if (dataKey === `No ${groupedBy.label}`) {
      comparisonKey = null;
    }

    return {
      ...dataArray,
      rows: dataArray.rows.some((row) => row[groupedBy.value] === comparisonKey)
        ? dataArray.rows.filter((row) => row[groupedBy.value] === comparisonKey)
        : [],
    };
  };

  const handleClickInfoButton = () => setShowModal((prevShowModal) => !prevShowModal);

  const closeModal = () => setShowModal(false);

  const handlePeriodChange = (selectedComparisonPeriod) => {
    if (selectedComparisonPeriod.value !== null) {
      setComparisonPeriod(selectedComparisonPeriod.value);
    }
  };

  const removeColumn = '';

  const tableColumns = React.useMemo(
    () => CampaignsColumns(true, comparisonPeriod, removeColumn),
    [removeColumn, comparisonPeriod],
  );

  return {
    showModal,
    tableNames,
    disabledApplyButton,
    previousYear,
    previousPeriod,
    startDate,
    endDate,
    dates,
    comparisonPeriodCheckboxValues,
    groupedBy,
    data,
    isLoading,
    error,
    comparisonPeriod,
    graphData,
    tableColumns,
    nonGroupedData,
    getDataArray,
    handleDatePickerError,
    handleDatePickerErrorResolve,
    handleChangeGroupedBy,
    handleDateChange,
    handleCheckboxChange,
    onDatesApply,
    handleDateReset,
    handleClickInfoButton,
    closeModal,
    handlePeriodChange,
  };
}
