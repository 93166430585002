/**
 * Joins 2 objects on a given value
 *
 * @param {object} websiteTraffic Website traffic data
 * @param {object} campaigns Campaigns data
 * @param {string} key Key to join data on
 * @returns
 */
const joinDataOnKey = (websiteTraffic, campaigns, key) => {
  const result = [];

  websiteTraffic.rows.forEach((element) => {
    const campaignsRow = campaigns.rows.find(
      (row) => row[key] === element[key],
    );
    const newRow = element;
    if (campaignsRow) {
      campaigns.metrics.forEach((metric) => {
        [
          '',
          'PrevPeriod',
          'PrevYear',
          'RelativeChangePrevPeriod',
          'RelativeChangePrevYear',
          'AbsoluteChangePrevPeriod',
          'AbsoluteChangePrevYear',
        ].forEach((suffix) => {
          if (['conversions'].includes(metric)) return;

          const propertyName = metric + suffix;
          if (campaignsRow[propertyName] || campaignsRow[propertyName] === 0) {
            newRow[propertyName] = campaignsRow[propertyName];
          }
        });
      });
    }

    result.push(newRow);
  });

  return { rows: result };
};

export { joinDataOnKey };
