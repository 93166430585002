import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './common/Checkbox';
import './ComparisonPeriodCheckbox.css';

function ComparisonPeriodCheckbox({ onChange, isComparisonPeriodDisplayed }) {
  return (
    <label className="comparison_period_checkbox__row" htmlFor="periodSelectorCheckbox">
      Hide Comparison Period
      <Checkbox
        id="periodSelectorCheckbox"
        className="periodSelectorCheckbox"
        type="checkbox"
        defaultChecked={!isComparisonPeriodDisplayed}
        onChange={() => onChange()}
      />
    </label>
  );
}

ComparisonPeriodCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  isComparisonPeriodDisplayed: PropTypes.bool.isRequired,
};

export default ComparisonPeriodCheckbox;
