import React from 'react';
import {
  GoogleDisplay,
  GoogleSearch,
  YouTube,
  Facebook,
} from 'components/common/icons';
import PropTypes from 'prop-types';

const selectedIcon = {
  Facebook: Facebook,
  'Google Display': GoogleDisplay,
  'Google Search': GoogleSearch,
  'Google Video': YouTube,
  '': false,
};

function TableIcon({ value }) {
  const IconComponent = selectedIcon[value];
  return IconComponent ? <IconComponent /> : <></>;
}

TableIcon.propTypes = {
  value: PropTypes.oneOf(Object.keys(selectedIcon)),
};

export default TableIcon;
