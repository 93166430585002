import React from 'react';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import Select from 'components/common/Select';
import CheckboxWithLabel from '../CheckboxWithLabel';
import { TextInput } from '../inputs/TextInput';
import { useDatesValidations } from './hooks/useDatesValidations';
import { dateRangeOptions } from './constants';
import styles from './styles/styles.module.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles/DatePicker.css';

function DateRangePickerComponent({
  startDate,
  endDate,
  onChange,
  onError,
  onErrorResolve,
  showPrevPeriod,
  showPrevYear,
  onChangeCheckbox,
}) {
  const {
    dates,
    selectedFormat,
    handleInputTextChange,
    handleChange,
    errors,
    handleCheckboxChange,
    checkBoxValues,
    dateRangeSelectedOption,
    handleChangeRange,
  } = useDatesValidations(
    startDate,
    endDate,
    onChange,
    onError,
    onErrorResolve,
    showPrevPeriod,
    showPrevYear,
    onChangeCheckbox,
  );

  return (
    <div>
      <div className={styles.optionsContainer}>
        <div className={styles.selectContainer}>
          <span className={styles.label}>Range</span>
          <Select
            value={dateRangeSelectedOption}
            className={styles.rangeSelector}
            options={dateRangeOptions}
            onChange={handleChangeRange}
            data-testid="select-dates-range"
          />
        </div>
        <div className={styles.selectContainer}>
          <span className={styles.label}>Start Date</span>
          <TextInput
            name="startDate"
            value={format(dates[0].startDate, selectedFormat)}
            onChange={handleInputTextChange}
            data-testid="text-input-startDate"
            error={errors.startDate}
          />
        </div>
        <div className={styles.selectContainer}>
          <span className={styles.label}>End Date</span>
          <TextInput
            name="endDate"
            value={format(dates[0].endDate, selectedFormat)}
            onChange={handleInputTextChange}
            data-testid="text-input-endDate"
            error={errors.endDate}
          />
        </div>
      </div>
      <DateRange
        className={styles.datePicker}
        onChange={handleChange}
        showSelectionPreview
        monthDisplayFormat="LLLL u"
        moveRangeOnFirstSelection={false}
        months={3}
        ranges={dates}
        showDateDisplay={false}
        direction="horizontal"
      />
      <h2 className={styles.subTitle}>Comparisons</h2>
      <div className={styles.checkboxsWrapper}>
        <div className={styles.checkboxContainer}>
          <CheckboxWithLabel
            id="showPrevPeriod"
            isChecked={checkBoxValues.showPrevPeriod}
            label="Previous Period"
            onChange={handleCheckboxChange}
            data-testid="prev-period-checkbox"
          />
          <CheckboxWithLabel
            id="showPrevYear"
            isChecked={checkBoxValues.showPrevYear}
            label="Same Period Previous Year"
            onChange={handleCheckboxChange}
            data-testid="prev-year-checkbox"
          />
        </div>
      </div>
    </div>
  );
}

DateRangePickerComponent.propTypes = {
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onErrorResolve: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  showPrevPeriod: PropTypes.bool,
  showPrevYear: PropTypes.bool,
  onChangeCheckbox: PropTypes.func,
};

export default DateRangePickerComponent;
