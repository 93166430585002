/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './PeriodSelector.css';

export default function PeriodSelector({ onChange, selectedComparisonPeriod }) {
  const handleComparisonPeriodSelect = (selectedPeriod) => {
    onChange(selectedPeriod);
  };

  const comparisonPeriods = [
    {
      label: 'Previous Period',
      value: 'previousPeriod',
    },
    {
      label: 'Previous Year',
      value: 'previousYear',
    },
  ];

  return (
    <div className="periodSelector__row">
      <Select
        className="periodSelector--picker"
        data-testid="periodSelector--picker"
        options={comparisonPeriods}
        value={comparisonPeriods.filter(
          (period) => period.value === selectedComparisonPeriod,
        )}
        onChange={(comparisonPeriod) =>
          handleComparisonPeriodSelect(comparisonPeriod)
        }
      />
    </div>
  );
}

PeriodSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedComparisonPeriod: PropTypes.string.isRequired,
};
