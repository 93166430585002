import React from 'react';
import PropTypes from 'prop-types';
import BeaconTable from 'components/common/BeaconTable';
import ClientsListRow from './ClientsListRow';
import useClientsTable from './hooks/useClientsTable';

const ClientsTable = ({
  data, selectedColumns = '', rowsPerPage = 10, className = '', id = '',
}) => {
  const { headers, table } = useClientsTable({ data, selectedColumns, rowsPerPage });
  return (
    <BeaconTable
      Row={ClientsListRow}
      rowsPerPage={rowsPerPage}
      headers={headers}
      table={table}
      className={className}
      id={id}
    />
  );
};

ClientsTable.propTypes = {
  data: PropTypes.array,
  selectedColumns: PropTypes.string,
  rowsPerPage: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string,
};

export default ClientsTable;
