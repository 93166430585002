export const metricsKeysArray = [
  {
    title: 'Sessions',
    key: 'sessions',
    dataIsPercentage: false,
    changeInPercentage: true,
    dataIsTime: false,
    increaseIsPositive: true,
    monetary: false,
    dataIsDecimal: false,
  },
  {
    title: 'Conversions',
    key: 'conversions',
    dataIsPercentage: false,
    changeInPercentage: true,
    dataIsTime: false,
    increaseIsPositive: true,
    monetary: false,
    dataIsDecimal: false,
  },
  {
    title: 'New Users',
    key: 'newUsers',
    dataIsPercentage: false,
    changeInPercentage: true,
    dataIsTime: false,
    increaseIsPositive: true,
    monetary: false,
    dataIsDecimal: false,
  },
  {
    title: 'Bounce Rate',
    key: 'bounceRate',
    dataIsPercentage: true,
    changeInPercentage: true,
    dataIsTime: false,
    increaseIsPositive: false,
    monetary: false,
    dataIsDecimal: false,
  },
  {
    title: 'Pages Per Session',
    key: 'pageviewsPerSession',
    dataIsPercentage: false,
    changeInPercentage: true,
    dataIsTime: false,
    increaseIsPositive: true,
    monetary: false,
    dataIsDecimal: true,
  },
  {
    title: 'Pageviews',
    key: 'pageviews',
    dataIsPercentage: false,
    changeInPercentage: true,
    dataIsTime: false,
    increaseIsPositive: true,
    monetary: false,
    dataIsDecimal: false,
  },
  {
    title: 'Avg. Session Duration',
    key: 'avgSessionDuration',
    dataIsPercentage: false,
    changeInPercentage: true,
    dataIsTime: true,
    increaseIsPositive: true,
    monetary: false,
    dataIsDecimal: false,
  },
  {
    title: 'Conversion Rate',
    key: 'conversionRate',
    dataIsPercentage: true,
    changeInPercentage: true,
    dataIsTime: false,
    increaseIsPositive: true,
    monetary: false,
    dataIsDecimal: false,
  },
];

export const getValueFromWebTrafficData = (rows, metric, prevValue = '') => {
  if (metric === 'bounceRate') {
    return (
      getValueFromWebTrafficData(rows, `bounces${prevValue}`)
      / getValueFromWebTrafficData(rows, `sessions${prevValue}`)
    );
  }
  if (metric === 'conversionRate') {
    return (
      getValueFromWebTrafficData(rows, `conversions${prevValue}`)
      / getValueFromWebTrafficData(rows, `sessions${prevValue}`)
    );
  }
  if (metric === 'pageviewsPerSession') {
    return (
      getValueFromWebTrafficData(rows, `pageviews${prevValue}`)
      / getValueFromWebTrafficData(rows, `sessions${prevValue}`)
    );
  }
  if (metric === 'avgSessionDuration') {
    return (
      getValueFromWebTrafficData(rows, `sessionDuration${prevValue}`)
      / getValueFromWebTrafficData(rows, `sessions${prevValue}`)
    );
  }

  if (rows && metric) {
    const filteredRows = rows.filter((row) => metric + prevValue in row);

    if (filteredRows.length) {
      return filteredRows.reduce(
        (total, currentRow) => total + currentRow[metric + prevValue],
        0,
        0,
      );
    }
  }
  return null;
};

export const comparisonArray = (rows, key) => [
  {
    title: 'Prev Period',
    value: getValueFromWebTrafficData(rows, key, 'PrevPeriod'),
  },
  {
    title: 'Prev Year',
    value: getValueFromWebTrafficData(rows, key, 'PrevYear'),
  },
];

export const channelFilters = {
  'Paid Media': ['Paid Social', 'Paid Search', 'Display'],
  Organic: ['Organic Search'],
  All: [],
};

export const comparisonPeriods = {
  none: 'None',
  prevYear: 'PreviousYear',
  prevPeriod: 'PreviousPeriod',
};

export const comparisonPeriodOptions = [
  { value: comparisonPeriods.prevYear, label: 'Previous Year' },
  { value: comparisonPeriods.prevPeriod, label: 'Previous Period' },
];

export const graphComparisonPeriodOptions = [
  { value: comparisonPeriods.none, label: 'None' },
  { value: comparisonPeriods.prevYear, label: 'Previous Year', suffix: 'PrevYear' },
  { value: comparisonPeriods.prevPeriod, label: 'Previous Period', suffix: 'PrevPeriod' },
];

export const allMetrics = [
  {
    label: 'Sessions',
    name: 'sessions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Conversions',
    name: 'conversions',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'New Users',
    name: 'newUsers',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Pageviews / Session',
    name: 'pageviewsPerSession',
    isPercentage: false,
    isDecimal: true,
    isTime: false,
    isMoney: false,
    isCalculatedMetric: true,
    isMaxMetric: false,
    calculationMetricOne: 'pageviews',
    calculationMetricTwo: 'sessions',
  },
  {
    label: 'Pageviews',
    name: 'pageviews',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
  {
    label: 'Bounce Rate',
    name: 'bounceRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isCalculatedMetric: true,
    isMaxMetric: false,
    calculationMetricOne: 'bounces',
    calculationMetricTwo: 'sessions',
  },
  {
    label: 'Avg. Session Duration',
    name: 'avgSessionDuration',
    isPercentage: false,
    isDecimal: false,
    isTime: true,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
    calculationMetricOne: 'sessionDuration',
    calculationMetricTwo: 'sessions',
  },
  {
    label: 'Conversion Rate',
    name: 'conversionRate',
    isPercentage: true,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: false,
    isCalculatedMetric: true,
    calculationMetricOne: 'conversions',
    calculationMetricTwo: 'sessions',
  },
  {
    label: 'Active Campaigns',
    name: 'campaignCount',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: false,
    isMaxMetric: true,
    isCalculatedMetric: false,
  },
  {
    label: 'Avg. Spend / Day',
    name: 'avgDailyCost',
    isPercentage: false,
    isDecimal: false,
    isTime: false,
    isMoney: true,
    isMaxMetric: false,
    isCalculatedMetric: false,
  },
];
