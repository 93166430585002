const reportKey = {
  googleAdsPerformance: 'google-ads-campaign-performance',
};

const groupBy = {
  campaignName: 'campaignName',
};

export const googleAdsPerformanceReqTypes = {
  googleAdsPerformance: {
    base: reportKey.googleAdsPerformance,
  },
  googleAdsPerformanceByCampaignName: {
    base: reportKey.googleAdsPerformance,
    groupBy: groupBy.campaignName,
  },
};

export default googleAdsPerformanceReqTypes;
