import React from 'react';
import PropTypes from 'prop-types';
import MType from '../typography/MType';
import XXLType from '../typography/XXLType';

const BaseInfoCard = ({
  title, data, children, table,
}) => {
  const Wrapper = table ? 'table' : 'div';
  return (
    <div
      data-testid={`base-info-card-${title}`}
      className="my-3 px-3 pt-3 pb-2 basic-shadow border-rd-2 bg-white"
      style={{ maxWidth: 260 }}
    >
      <MType className="mb-3">{title}</MType>
      <XXLType light>{data}</XXLType>
      <Wrapper className={table ? 'mt-4' : undefined}>
        {children}
      </Wrapper>
    </div>
  );
};

BaseInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  children: PropTypes.node,
  table: PropTypes.bool,
};

BaseInfoCard.defaultProps = {
  children: undefined,
  table: false,
};

export default BaseInfoCard;
