import React from 'react';
import PropTypes from 'prop-types';

const LType = ({ children, className, light }) => (
  <div className={`txt-sz-l ${light ? 'avenir-medium' : 'avenir-heavy'} ${className}`}>
    {children}
  </div>
);

LType.defaultProps = {
  className: '',
  light: false,
};

LType.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  light: PropTypes.bool,
};

export default LType;
