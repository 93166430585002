/* eslint-disable */
import websitePerformanceReqTypes from './websitePerformanceReports';
import leadReqTypes from './leadsReports';
import googleAdsPerformanceReqTypes from './googleAdsPerformanceReports';
import dmConfigurationReq from './dmConfiguration';
import facebookAdReqTypes from './facebookAdsPerformanceReports';
import campaignsReqTypes from './campaignsReports';

const reportTypes = {
  websitePerformanceReports: websitePerformanceReqTypes,
  leadsReports: leadReqTypes,
  googleAdsPerformanceReports: googleAdsPerformanceReqTypes,
  dmConfiguration: dmConfigurationReq,
  facebookReports: facebookAdReqTypes,
  campaigns: campaignsReqTypes,
};

export default reportTypes;
