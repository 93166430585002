/* eslint-disable import/prefer-default-export */

import { getCampaignsReports } from '../api/getCampaignsReports';
import { campaignsActionTypes } from './ActionTypes';

const fetchCampaignsRequest = () => ({
  type: campaignsActionTypes.fetchCampaignsRequest,
  payload: null,
});

const fetchCampaignsError = (error) => ({
  type: campaignsActionTypes.fetchCampaignsError,
  payload: { error },
});

const fetchCampaignsSuccess = (campaignsData) => ({
  type: campaignsActionTypes.fetchCampaignsSuccess,
  payload: campaignsData,
});

export const fetchCampaignsAction = (
  tenantId,
  reportType,
  dates,
  dimensions,
) => async (dispatch) => {
  dispatch(fetchCampaignsRequest());
  try {
    const campaignsReport = await getCampaignsReports(tenantId, reportType, dates, dimensions);
    dispatch(
      fetchCampaignsSuccess(
        { data: campaignsReport.data, id: tenantId, dimensions },
      ),
    );
  } catch (error) {
    dispatch(fetchCampaignsError(error.message));
  }
};
