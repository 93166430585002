import React from 'react';
import BeaconTable from 'components/common/BeaconTable';
import PropTypes from 'prop-types';
import useChannelBreakdownTable from './hooks/useChannelBreakdownTable';
import ChannelBreakdownListRow from './ChannelBreakdownRowsList';
import styles from './styles.module.scss';

const ChannelBreakdownTable = ({
  data, removeColumn = '', rowsPerPage = 10, comparisonPeriod, id = '',
}) => {
  const { headers, table } = useChannelBreakdownTable({
    data,
    removeColumn,
    rowsPerPage,
    comparisonPeriod,
  });
  
  return (
    <BeaconTable
      Row={ChannelBreakdownListRow}
      rowsPerPage={rowsPerPage}
      headers={headers}
      table={table}
      className={styles.channelBreakdownTable}
      id={id}
    />
  );
};

ChannelBreakdownTable.propTypes = {
  data: PropTypes.object,
  removeColumn: PropTypes.string,
  rowsPerPage: PropTypes.number,
  comparisonPeriod: PropTypes.string,
  id: PropTypes.string,
};

export default ChannelBreakdownTable;
