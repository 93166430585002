import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default function SimpleBarChart({
  data, barData, metrics, xAxisKey, barKey,
}) {
  const xAxisFormatter = (value) => {
    const x = metrics.find((m) => m.name === xAxisKey);
    if (x.formatter) {
      return x.formatter(value);
    }
    return value;
  };

  const tooltipFormatter = (value, label) => {
    const metric = metrics.find((m) => m.name === barKey);
    if (metric) {
      return ['formatter' in metric ? metric.formatter(value) : value, `${label} ${metric.label}`];
    }
    return null;
  };

  const colors = ['#8884d8', '#82ca9d', '#B381C9'];
  const bars = barData.map((b, i) => <Bar name={b} dataKey={`leadCount${b}`} fill={colors[i]} />);

  return (
    <ResponsiveContainer id="simple-bar-chart" width="100%" height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} tickFormatter={xAxisFormatter} />
        <YAxis />
        <Tooltip labelFormatter={xAxisFormatter} formatter={tooltipFormatter} />
        <Legend />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
}

SimpleBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    dateGroup: PropTypes.string.isRequired,
  })).isRequired,
  metrics: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    aggregationMethod: PropTypes.string,
    formatter: PropTypes.function,
  })).isRequired,
  barData: PropTypes.arrayOf(PropTypes.string).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  barKey: PropTypes.string.isRequired,
};
