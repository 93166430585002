import React from 'react';

export function checkValidData(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: props.rawData,
      };
    }

    render() {
      return this.state.data.length ? (
        <WrappedComponent {...this.props} />
      ) : null;
    }
  };
}
