import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import './App.css';

import UnauthenticatedApp from './UnauthenticatedApp';
import AuthenticatedApp from './AuthenticatedApp';
import { getUserAuthenticationStatus } from './selectors/UserSelectors';
import { fetchUserAction } from './actions/UserActions';
import usingMouseHandler from './utils/usingMouseHandler';
import ErrorBoundary from 'utils/ErrorBoundary';

function App() {
  const isAuthenticated = useSelector(getUserAuthenticationStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    usingMouseHandler();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(fetchUserAction());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <ErrorBoundary>
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </ErrorBoundary>
  );
}

export default App;
