import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

function BeaconSelect({
  className,
  options,
  value,
  onChange = () => {},
  onClick = () => {},
  ...rest
}) {
  const [currentValue, setCurrentValue] = React.useState(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div
      onClick={onClick}
      className={className}
      data-testid={rest['data-testid']}
    >
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            boderColor: 'transparent',
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            '& svg': {
              height: '24px',
              width: '24px',
              position: 'absolute',
              top: '6px',
              right: '5px',
              fill: '#7e8488',
            },
          }),
          control: (provided) => ({
            ...provided,
            boxShadow: 'none',
            borderRadius: '2px',
            height: '40px',
            backgroundColor: 'transparent',
            border: 'solid 2px #dfdfdf',
            '&:active': {
              boxShadow: 'none',
              border: 'solid 2px #dfdfdf',
            },
            '&:focus': {
              border: 'solid 2px #dfdfdf',
            },
            '&:hover': {
              border: 'solid 2px #dfdfdf',
            },
          }),
          menuList: (provided) => ({
            ...provided,
            marginLeft: '.5rem',
            marginRight: '.5rem',
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected
              ? '#2978c2'
              : isFocused
              ? 'rgba(41, 120, 194, 0.1)'
              : null,
            color: isSelected ? '#fff' : '#000',
            borderRadius: '3px',
            ':active': {
              ...styles[':active'],
              backgroundColor: 'rgba(41, 120, 194, 0.1)',
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
            fontFamily: 'Avenir',
            fontSize: '15px',
            fontWeight: '900',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#1f2e43',
          }),
        }}
        options={options}
        value={currentValue}
        onChange={onChange}
      />
    </div>
  );
}

BeaconSelect.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  value: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default BeaconSelect;
export { BeaconSelect };
