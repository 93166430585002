import React from 'react';
import PropTypes from 'prop-types';
import XSType from '../../typography/XSType';
import Triangle from '../../ui-shapes/triangle';

const BasicSortableHeader = ({
  id,
  testkey,
  label,
  isSorted,
  isSortedDesc,
  sortBy,
}) => (
  <th
    className="horizontal-cell-padding text-dark text-uppercase h6 underline-hover pointer"
    data-testid={`basic-table-header-${testkey}`}
    onClick={() => (sortBy(id, isSorted ? !isSortedDesc : false, false))}
  >
    <XSType className={`mb-2 d-flex align-items-center ${!isSorted ? 'text-secondary' : 'text-black'}`}>
      {label}
      {(isSorted) && (
      <Triangle direction={isSortedDesc ? 'down' : 'up'} />
      )}
    </XSType>
  </th>
);

BasicSortableHeader.propTypes = {
  id: PropTypes.string.isRequired,
  testkey: PropTypes.string,
  label: PropTypes.string.isRequired,
  isSorted: PropTypes.bool.isRequired,
  isSortedDesc: PropTypes.bool,
  sortBy: PropTypes.func.isRequired,
};

BasicSortableHeader.defaultProps = {
  testkey: 'key',
  isSortedDesc: false,
};

export default BasicSortableHeader;
