import { tenantActionTypes } from '../actions/ActionTypes';

const initialState = {
  tenantsList: [],
  errorMessage: '',
};

const tenantReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case tenantActionTypes.fetchAllSuccess:
      return { ...state, tenantsList: payload, errorMessage: '' };
    case tenantActionTypes.fetchAllError:
      return { ...state, errorMessage: payload };
    case tenantActionTypes.fetchTenantSuccess:
      return { ...state, [payload.id]: payload };
    case tenantActionTypes.fetchTenantError:
      return { ...state, errorMessage: payload };
    case tenantActionTypes.fetchGroupedTenantsSuccess:
      return { ...state, [payload.id]: payload };

    default:
      return state;
  }
};

export default tenantReducer;
