import React, { useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import BasicSortableHeader from 'components/common/table/cells/BasicSortableHeader';
import ChannelBreakdownTableColumns, { SUPP_PREFIX } from '../ChannelBreakdownTableColumns';
import ChannelBreakdownSortMenuHeader from '../ChannelBreakdownSortMenuHeader';

const useChannelBreakdownTable = ({ data, removeColumn, comparisonPeriod }) => {
  const isPercentage = true;
  const columns = useMemo(
    () => ChannelBreakdownTableColumns(isPercentage, comparisonPeriod, removeColumn),
    [removeColumn, comparisonPeriod, isPercentage],
  );

  const table = useTable(
    {
      columns,
      data: data ? data.rows : [],
    },
    useSortBy,
    usePagination,
  );

  const headers = table.columns
    .map((col) => {
      if (col.supportColumn) return undefined;
      const Component = col.changeColumn
        ? ChannelBreakdownSortMenuHeader
        : BasicSortableHeader;
      return (
        <Component
          id={col.id}
          key={col.Header}
          testkey={col.Header}
          label={col.label}
          isSorted={col.isSorted}
          isSortedDesc={col.isSortedDesc}
          sortBy={table.toggleSortBy}
          changeColumn={
            col.changeColumn
            && table.columns.find((c) => c.id === `${SUPP_PREFIX}${col.id}`)
          }
        />
      );
    })
    .filter((val) => !!val);

  return {
    headers,
    table,
  };
};

export default useChannelBreakdownTable;
