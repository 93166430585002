import React from 'react';
import PropTypes from 'prop-types';
import BasicTableRow from 'components/common/table/BasicTableRow';
import { SUPP_PREFIX } from './ChannelBreakdownTableColumns';

const ChannelBreakdownListRow = ({
  original, values, columns,
}) => (
  <BasicTableRow
    className="basic-shadow"
    rowComponents={columns.map(({
      Header, Component, supportValues, formatting, supportColumn, changeColumn,
    }) => {
      if (supportColumn) return undefined;
      let changeColInfo;
      if (changeColumn) {
        changeColInfo = columns.find((col) => col.id === `${SUPP_PREFIX}${Header}`);
      }

      return (
        <Component
          key={Header}
          value={formatting ? formatting(values[Header]) : values[Header]}
          change={changeColumn && changeColInfo.accessor(original)}
          monetary={supportValues && supportValues.monetary}
          increaseIsPositive={supportValues && supportValues.increaseIsPositive}
          percentage
        />
      );
    }).filter((a) => !!a)}
  />
);

ChannelBreakdownListRow.propTypes = {
  original: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.node).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ChannelBreakdownListRow;
