import React from 'react';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import './DateDisplay.css';

function DateDisplay({ previousPeriod, previousYear }) {
  return (
    <div>
      <p className="overview_prev_period_p">
        Prev. Period:
        {format(parse(previousPeriod.startDate, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy')}
        {' '}
        -
        {' '}
        {format(parse(previousPeriod.endDate, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy')}
      </p>
      <p className="overview_prev_year_p">
        Prev. Year:
        {' '}
        {format(parse(previousYear.startDate, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy')}
        {' '}
        -
        {' '}
        {format(parse(previousYear.endDate, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy')}
      </p>
    </div>
  );
}

const periodPropType = PropTypes.shape({
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
});

DateDisplay.propTypes = {
  previousPeriod: periodPropType.isRequired,
  previousYear: periodPropType.isRequired,
};

export default DateDisplay;
