export const error = 'error';
export const loading = 'loading';
export const notStarted = 'notStarted';
export const success = 'success';

const statusObj = {
  Request: loading,
  Reset: notStarted,
  Error: error,
  Success: success,
};

const statusReducer = (state = {}, { type }) => {
  const matchTest = /(.*)(Request|Error|Reset|Success)/.exec(type);

  if (matchTest) {
    const [, key, operation] = matchTest;
    return { ...state, [key]: statusObj[operation] };
  }

  return state;
};

export default statusReducer;
