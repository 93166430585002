import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

const Checkbox = ({
  id, defaultChecked, onChange,className='', disabled, checked
}) => (
  <input
    data-testid="checkbox"
    id={id}
    type="checkbox"
    className={cx(styles.checkbox, className)}
    defaultChecked={defaultChecked}
    onChange={onChange}
    disabled={disabled}
    checked={checked}
    />
);

Checkbox.propTypes = {
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default Checkbox;
