import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

const BasicTable = ({ headers, children, className, id }) => (
  <table
    className={cx('basic-table-parent', styles.table, className)}
    data-testid="basic-table-parent"
    id={id}
  >
    {!!headers.length && (
      <thead>
        <tr>{headers}</tr>
      </thead>
    )}
    <tbody>{children}</tbody>
  </table>
);

BasicTable.defaultProps = {
  headers: [],
  className: undefined,
};

BasicTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      isSorted: PropTypes.bool,
    }),
  ),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default BasicTable;
