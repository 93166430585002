import React from 'react';
import PropTypes from 'prop-types';

import CalloutMetric from '../../components/CalloutMetric';

import {
  formatPercentage,
  formatSeconds,
  getRatioDataOverPeriodOfTime,
  getSumDataOverPeriodOfTime,
} from '../../utils/reporting';

export default function OrganicOverview({
  data, period,
}) {
  return (
    <div data-testid="OverviewCallouts" className="container">
      <div className="col-12">
        <div className="row card-deck">
          <CalloutMetric
            currentValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'sessions')}
            previousPeriodValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'sessionsPrevPeriod')}
            previousYearValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'sessionsPrevYear')}
            label="Sessions"
          />
          <CalloutMetric
            currentValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'newUsers')}
            previousPeriodValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'newUsersPrevPeriod')}
            previousYearValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'newUsersPrevYear')}
            label="New Users"
          />
          <CalloutMetric
            currentValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'sessionDuration', 'sessions')}
            previousPeriodValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'sessionDurationPrevPeriod', 'sessionsPrevPeriod')}
            previousYearValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'sessionDurationPrevYear', 'sessionsPrevYear')}
            formatValue={formatSeconds}
            label="Avg. Session Duration"
          />
          <CalloutMetric
            currentValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'pageviews', 'sessions')}
            previousPeriodValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'pageviewsPrevPeriod', 'sessionsPrevPeriod')}
            previousYearValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'pageviewsPrevYear', 'sessionsPrevYear')}
            formatValue={(value) => (value ? value.toFixed(1) : '-')}
            label="Pageviews / Session"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="row card-deck">
          <CalloutMetric
            currentValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'pageviews')}
            previousPeriodValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'pageviewsPrevPeriod')}
            previousYearValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'pageviewsPrevYear')}
            label="Pageviews"
          />
          <CalloutMetric
            currentValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'bounces', 'sessions')}
            previousPeriodValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'bouncesPrevPeriod', 'sessionsPrevPeriod')}
            previousYearValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'bouncesPrevYear', 'sessionsPrevYear')}
            formatValue={formatPercentage}
            inverseTrend
            label="Bounce Rate"
          />
          <CalloutMetric
            currentValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'conversions')}
            previousPeriodValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'conversionsPrevPeriod')}
            previousYearValue={getSumDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'conversionsPrevYear')}
            label="Conversions"
          />
          <CalloutMetric
            currentValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'conversions', 'sessions')}
            previousPeriodValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'conversionsPrevPeriod', 'sessionsPrevPeriod')}
            previousYearValue={getRatioDataOverPeriodOfTime(data.rows, period.startDate, period.endDate, 'conversionsPrevYear', 'sessionsPrevYear')}
            formatValue={formatPercentage}
            label="Conversion Rate"
          />
        </div>
      </div>
    </div>
  );
}

const periodPropType = PropTypes.shape({
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
});

OrganicOverview.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({ date: PropTypes.string })),
  }).isRequired,
  period: periodPropType.isRequired,
};
