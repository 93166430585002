/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import BTable from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination } from 'react-table';
import { checkValidData } from './HOCs/checkValidData';

function MetricTable({
  rawData,
  rawColumns,
  showSummary,
  defaultSort,
  isPaginationEnabled,
}) {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: rawColumns || [],
      data: rawData || [],
      initialState: {
        sortBy: defaultSort,
        pageIndex: 0,
        pageSize: isPaginationEnabled ? 10 : rawData.length,
      },
    },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <BTable
        id="metrics-table"
        responsive
        striped
        bordered
        hover
        size="sm"
        {...getTableProps()}
        height="120"
        scrollTop="Bottom"
        data-testid="metrics-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page
            .filter((row) => row.original.campaignId !== 'total_column')
            .map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
        </tbody>
        {showSummary && (
          <>
            <b>Totals</b>
            <tfoot>
              {page
                .filter((row) => row.original.campaignId === 'total_column')
                .map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
            </tfoot>
          </>
        )}
      </BTable>
      {isPaginationEnabled && (
        <div>
          <button
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          {' '}
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          {' '}
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
          {' '}
          <button
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
          {' '}
          <span>
            Page
            {' '}
            <strong>
              {pageIndex + 1}
              {' '}
              of
              {pageOptions.length}
            </strong>
            {' '}
          </span>
          <span>
            | Go to page:
            {' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const newPage = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(newPage);
              }}
              style={{ width: '100px' }}
            />
          </span>
          {' '}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Show
                {' '}
                {size}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}

MetricTable.propTypes = {
  rawData: PropTypes.arrayOf(
    PropTypes.shape({ channel: PropTypes.string }),
  ).isRequired,
  rawColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
    }),
  ).isRequired,
  showSummary: PropTypes.bool.isRequired,
  defaultSort: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  isPaginationEnabled: PropTypes.bool,
};

MetricTable.defaultProps = {
  defaultSort: [
    {
      id: 'sessions',
      desc: true,
    },
  ],
  isPaginationEnabled: false,
};

export default checkValidData(MetricTable);
