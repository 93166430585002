import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_USM_API_BASE_URL || 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },
});

function getUser(cognitoUser) {
  return {
    email: cognitoUser.attributes.email,
    groups: cognitoUser.signInUserSession.accessToken.payload['cognito:groups'],
    idToken: cognitoUser.signInUserSession.idToken.jwtToken,
    accessToken: cognitoUser.signInUserSession.accessToken.jwtToken,
  };
}

async function getAuthenticatedUser() {
  return Auth.currentAuthenticatedUser()
    .then(getUser);
}

async function signOut() {
  return Auth.signOut();
}

async function signIn(username, password) {
  return Auth.signIn(username, password).then(getUser).catch(() => (null));
}

async function federatedSignIn(path) {
  return Auth.federatedSignIn({ customState: path });
}

async function getAccessToken() {
  const { signInUserSession } = await Auth.currentAuthenticatedUser();
  return signInUserSession.accessToken.jwtToken;
}

async function getUserAttributes() {
  const { attributes } = await Auth.currentUserInfo();
  return attributes;
}

export {
  getAuthenticatedUser, signOut, signIn, federatedSignIn, getAccessToken, getUserAttributes,
};
