import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import qs from 'query-string';

const getQueryStringValue = (
  key,
  queryString = window.location.search,
) => {
  const values = qs.parse(queryString);
  return values[key] || null;
};

const parseValue = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  return value;
};
const useQueryStringValue = (key) => {
  const query = parseValue(getQueryStringValue(key));
  const [value, setValue] = useState(query);
  const history = useHistory();

  // WARNING: ... 'query'. Either include it or remove the dependency array
  useEffect(() => {
    if (history && value !== null && value !== query) {
      const values = qs.parse(history.location.search);
      const newQsValue = qs.stringify({
        ...values,
        [key]: value,
      });
      history.push(`${history.location.pathname}?${newQsValue}`);
    }
  }, [value, key, history]);   // eslint-disable-line

  // WARNING: ... 'value'. Either include it or remove the dependency array
  useEffect(() => {
    if (query && value !== query) setValue(query);
  }, [query, key]);   // eslint-disable-line

  return [value, setValue];
};

export default useQueryStringValue;
