import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { callFunctionOnBlur } from '../../../resources/helpers/browserCompatibility';
import DropdownFloatMenu from '../../../components/common/inputs/DropdownFloatMenu/DropdownFloatMenu';
import XSType from '../../../components/common/typography/XSType';
import Triangle from '../../../components/common/ui-shapes/triangle';

const ClientSortMenuHeader = ({
  id,
  testkey,
  label,
  isSorted,
  isSortedDesc,
  sortBy,
  changeColumn,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => callFunctionOnBlur(() => setPopupOpen(false), true), []);

  const options = [
    {
      text: 'Ascending',
      value: 'Ascending',
      onClick: () => sortBy(id, false),
      selected: isSorted && !isSortedDesc,
    },
    {
      text: 'Descending',
      value: 'Descending',
      onClick: () => sortBy(id, true),
      selected: isSorted && isSortedDesc,
    },
    {
      text: 'Change Ascending',
      value: 'ChangeAscending',
      onClick: () => sortBy(changeColumn.id, false),
      selected: changeColumn.isSorted && !changeColumn.isSortedDesc,
    },
    {
      text: 'Change Descending',
      value: 'ChangeDescending',
      onClick: () => sortBy(changeColumn.id, true),
      selected: changeColumn.isSorted && changeColumn.isSortedDesc,
    },
  ];

  return (
    <th
      className="horizontal-cell-padding text-dark text-uppercase h6 underline-hover pointer position-relative"
      data-testid={`submenu-table-header-${testkey}`}
      onClick={() => {
        setImmediate(() => {
          setPopupOpen((a) => !a);
        });
      }}
      onMouseDown={(e) => e.nativeEvent.preventDefault()}
    >
      <XSType className={`mb-2 d-flex align-items-center ${!isSorted ? 'text-secondary' : 'text-black'}`}>
        {label}
        {(isSorted) && (
        <Triangle direction={isSortedDesc ? 'down' : 'up'} />
        )}
        {(changeColumn.isSorted) && (
        <Triangle direction={changeColumn.isSortedDesc ? 'down' : 'up'} />
        )}
      </XSType>
      {popupOpen && (
      <div
        className="center-children"
        style={{ position: 'absolute', bottom: 0, width: '100%' }}
      >
        <DropdownFloatMenu
          options={options}
        />
      </div>
      )}
    </th>
  );
};

ClientSortMenuHeader.propTypes = {
  changeColumn: PropTypes.shape({
    id: PropTypes.string,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string.isRequired,
  testkey: PropTypes.string,
  label: PropTypes.string.isRequired,
  isSorted: PropTypes.bool.isRequired,
  isSortedDesc: PropTypes.bool,
  sortBy: PropTypes.func.isRequired,
};

ClientSortMenuHeader.defaultProps = {
  testkey: 'key',
  isSortedDesc: false,
};

export default ClientSortMenuHeader;
