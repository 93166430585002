import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import CalloutMetricArrowBox from './CalloutMetricArrowBox';
import CalloutMetricTotal from './CalloutMetricTotal';
import './CalloutMetric.css';
import { getPercentageChange } from '../utils/reporting';

export default function CalloutMetric({
  currentValue, label, previousPeriodValue, previousYearValue, formatValue, inverseTrend,
}) {
  const previousPeriodDelta = previousPeriodValue
    ? getPercentageChange(currentValue, previousPeriodValue).toFixed(1)
    : null;
  const previousYearDelta = previousYearValue
    ? getPercentageChange(currentValue, previousYearValue).toFixed(1)
    : null;

  return (
    <Card data-testid={`CalloutMetric-${label}`} className="calloutMetric border-dark">
      <div className="col callout">
        <div className="container">
          <div className="card-deck">
            <Card>
              <CalloutMetricArrowBox inverseTrend={inverseTrend} percentage={previousPeriodDelta} metricTimeFrame="Prev. Period" />
            </Card>
            <Card>
              <CalloutMetricArrowBox inverseTrend={inverseTrend} percentage={previousYearDelta} metricTimeFrame="Prev. Year" />
            </Card>
          </div>
        </div>
        <Card.Body className="text-center">
          <CalloutMetricTotal total={formatValue(currentValue)} metric={label} />
        </Card.Body>
      </div>
    </Card>
  );
}

CalloutMetric.propTypes = {
  currentValue: PropTypes.number,
  label: PropTypes.string.isRequired,
  previousPeriodValue: PropTypes.number,
  previousYearValue: PropTypes.number,
  formatValue: PropTypes.func,
  inverseTrend: PropTypes.bool,
};

CalloutMetric.defaultProps = {
  currentValue: 0,
  previousPeriodValue: 0,
  previousYearValue: 0,
  formatValue: (v) => (v ? v.toString() : '-'),
  inverseTrend: false,
};
