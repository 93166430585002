import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrowRight from '../../../resources/assets/chevron-right.svg';
import MType from '../typography/MType';

const pagesToShow = 5;

const BasicTablePagination = ({ table }) => {
  const {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = table;

  const [pagesToShowLocal, setPagesToShow] = useState([]);

  const calcPagesToShow = useCallback(() => {
    const arrayToReturn = [];
    const currentPage = pageIndex + 1;
    arrayToReturn.push(currentPage);

    let i = 0;
    while (arrayToReturn.length < pagesToShow && i < pagesToShow) {
      i += 1;
      if (currentPage + i <= pageCount) arrayToReturn.push(currentPage + i);
      if (currentPage - i > 0) arrayToReturn.unshift(currentPage - i);
    }

    return arrayToReturn;
  }, [pageCount, pageIndex]);

  useEffect(() => {
    setPagesToShow(calcPagesToShow());
  }, [pageIndex, calcPagesToShow]);

  return (
    <div className="center-children">
      <button
        className="small-square outline center-children low-opacity-primary-hover"
        type="button"
        disabled={!canPreviousPage}
        onClick={previousPage}
      >
        <img src={arrowRight} alt="right arrow" className="rotate-180" />
      </button>
      {pagesToShowLocal.map((no) => (
        <button
          key={no}
          className={`small-square outline center-children low-opacity-primary-hover ${
            no === pageIndex + 1 ? 'bg-primary text-white' : ''
          }`}
          disabled={no === pageIndex + 1}
          onClick={() => gotoPage(no - 1)}
          type="button"
        >
          <MType className="font-offset">{no}</MType>
        </button>
      ))}

      <button
        className="small-square outline center-children low-opacity-primary-hover"
        type="button"
        disabled={!canNextPage}
        onClick={nextPage}
      >
        <img src={arrowRight} alt="right arrow" />
      </button>
    </div>
  );
};

BasicTablePagination.propTypes = {
  table: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BasicTablePagination;
