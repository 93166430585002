import { userActionTypes } from './ActionTypes';
import { getAuthenticatedUser, signOut as signUserOut, federatedSignIn } from '../api/authentication';
import { setLocalStorageValue } from '../utils/localStorage';

const fetchUserRequest = () => ({
  type: userActionTypes.fetchUserRequest,
  payload: null,
});

const fetchUserError = (error) => ({
  type: userActionTypes.fetchUserError,
  payload: { error },
});

const fetchUserSuccess = (userData) => ({
  type: userActionTypes.fetchUserSuccess,
  payload: userData,
});

export const fetchUserAction = () => async (dispatch) => {
  dispatch(fetchUserRequest());
  try {
    const user = await getAuthenticatedUser();
    dispatch(fetchUserSuccess(user));
  } catch (error) {
    dispatch(fetchUserError(error.message));
  }
};

const signOutRequest = () => ({
  type: userActionTypes.signOutRequest,
  payload: null,
});

const signOutError = (error) => ({
  type: userActionTypes.signOutError,
  payload: { error },
});

const signOutSuccess = (userData) => ({
  type: userActionTypes.signOutSuccess,
  payload: userData,
});

export const signOutAction = () => async (dispatch) => {
  dispatch(signOutRequest());
  try {
    await signUserOut();
    dispatch(signOutSuccess());
  } catch (error) {
    dispatch(signOutError(error.message));
  }
};

const logInRequest = () => ({
  type: userActionTypes.loginUserRequest,
  payload: null,
});

const logInError = (error) => ({
  type: userActionTypes.loginUserError,
  payload: { error },
});

const logInSuccess = (userData) => ({
  type: userActionTypes.loginUserSuccess,
  payload: userData,
});

export const logInAction = (path) => async (dispatch) => {
  dispatch(logInRequest());
  try {
    const updatedUser = await federatedSignIn(path);
    setLocalStorageValue('signInState', { pathname: window.location.pathname, search: window.location.search });
    dispatch(logInSuccess(updatedUser));
  } catch (error) {
    dispatch(logInError(error.message));
  }
};
