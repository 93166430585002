import React from 'react';
import PropTypes from 'prop-types';
import getComparisonData from '../../../resources/helpers/getComparisonData';
import BaseInfoCard from './BaseInfoCard';
import ComparisonRow from './info-rows/ComparisonRow';
import { handleDataTreatment } from './constants';

const ComparisonCard = ({
  title,
  data,
  compareArray,
  increaseIsPositive,
  dataIsPercentage,
  dataIsTime,
  dataIsDecimal,
  changeInPercentage,
  monetary,
  displayPrevPeriod,
  displayPrevYear,
}) => {
  const comparisonData = compareArray.map((compareData) => {
    const [change, increase, positive] = getComparisonData(
      data,
      compareData.value,
      changeInPercentage,
      increaseIsPositive,
    );
    return {
      title: compareData.title,
      data: handleDataTreatment(
        compareData.value,
        dataIsPercentage,
        dataIsTime,
        dataIsDecimal,
        monetary,
      ),
      change,
      increase,
      positive,
      isDisplayed:
        compareData.title === 'Prev Period'
          ? displayPrevPeriod
          : displayPrevYear,
    };
  });

  return (
    <BaseInfoCard
      title={title}
      data={handleDataTreatment(
        data,
        dataIsPercentage,
        dataIsTime,
        dataIsDecimal,
        monetary,
      )}
      table
    >
      <tbody>
        {comparisonData.map((dataRow) => (
          <ComparisonRow
            key={dataRow.title + dataRow.data}
            title={dataRow.title}
            data={dataRow.data}
            change={dataRow.change}
            increase={dataRow.increase}
            positive={dataRow.positive}
            isDisplayed={dataRow.isDisplayed}
          />
        ))}
      </tbody>
    </BaseInfoCard>
  );
};

ComparisonCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  compareArray: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  increaseIsPositive: PropTypes.bool,
  dataIsPercentage: PropTypes.bool,
  changeInPercentage: PropTypes.bool,
  monetary: PropTypes.bool,
  displayPrevPeriod: PropTypes.bool,
  displayPrevYear: PropTypes.bool,
};

ComparisonCard.defaultProps = {
  increaseIsPositive: false,
  dataIsPercentage: false,
  changeInPercentage: false,
  monetary: false,
  displayPrevPeriod: true,
  displayPrevYear: true,
};

export default React.memo(ComparisonCard);
