import {
  formatPercentage,
  formatSeconds,
} from '../../utils/reporting';

const availableMetrics = [
  { name: 'sessions', label: 'Sessions' },
  { name: 'conversions', label: 'Conversions' },
  { name: 'users', label: 'Users' },
  { name: 'newUsers', label: 'New Users' },
  { name: 'pageviews', label: 'Pageviews' },
  { name: 'bounceRate', label: 'Bounce Rate', formatter: (v) => `${formatPercentage(v)}` },
  { name: 'pageviewsPerSession', label: 'Pageviews per Session', formatter: (v) => v.toFixed(2) },
  {
    name: 'avgSessionDuration',
    label: 'Avg. Session Duration',
    formatter: formatSeconds,
  },
];

export { availableMetrics };
