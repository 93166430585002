import { formatPercentage } from 'utils/reporting';
import { addRelevantSymbols } from '../../../resources/helpers/strings';

export const handleDataTreatment = (
  value,
  dataIsPercentage,
  dataIsTime,
  dataIsDecimal,
  monetary,
) => {
  if (dataIsPercentage) {
    return formatPercentage(value);
  } if (value) {
    return addRelevantSymbols(
      value,
      dataIsPercentage,
      dataIsTime,
      dataIsDecimal,
      monetary,
    );
  }
  return '-';
};
