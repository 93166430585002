import ComparisonCell from 'components/common/table/cells/ComparisonCell';
import { BoldMCellWithToolTip } from 'components/common/table/cells/MTypeCells';
import TableIcon from 'components/common/table/cells/TableIcon';
import { formatCurrency, formatLocalizedNumber } from 'utils/reporting';
import { comparisonPeriods } from '../constants';

export const SUPP_PREFIX = 'supportChange';
const PERCENTAGE_STRING = 'percentage';
const ABSOLUTE_STRING = 'absolute';
const PREV_YEAR_STRING = 'PrevYear';
const PREV_PERIOD_STRING = 'PrevPeriod';

const changesSuffix = {
  [PERCENTAGE_STRING + PREV_PERIOD_STRING]: 'RelativeChangePrevPeriod',
  [PERCENTAGE_STRING + PREV_YEAR_STRING]: 'RelativeChangePrevYear',
  [ABSOLUTE_STRING + PREV_PERIOD_STRING]: 'AbsoluteChangePrevPeriod',
  [ABSOLUTE_STRING + PREV_YEAR_STRING]: 'AbsoluteChangePrevYear',
};

const sortType = (key) => (rowA, rowB, _, desc) => {
  if (rowA.original.isPinned) {
    return desc ? 1 : -1;
  }

  if (rowB.original.isPinned) {
    return desc ? -1 : 1;
  }

  const a = rowA.original[key];

  const b = rowB.original[key];

  if (a > b) return 1;

  if (b > a) return -1;

  return 0;
};

/**
 * @param {boolean} isPercentage
 * @param {string} removeColumns it contents all the keys that we want to remove
 * @param {string} comparisonPeriod
 * @returns {array} relevant columns
 */
const CampaignsColumns = (isPercentage, comparisonPeriod, removeColumns) => {
  const baseColumns = [
    {
      Header: 'adNetwork',
      label: '',
      accessor: (a) => a.adNetwork,
      Component: TableIcon,
      sortType: sortType('campaignName'),
    },
    {
      Header: 'campaignName',
      label: 'Campaign',
      accessor: (a) => a.campaignName,
      Component: BoldMCellWithToolTip,
      sortType: sortType('campaignName'),
    },
    {
      Header: 'spend',
      label: 'Spend',
      accessor: (a) => a.spend,
      changeColumn: true,
      Component: ComparisonCell,
      sortType: sortType('spend'),
      formatting: (a) => formatCurrency(a),
    },
    {
      Header: 'impressions',
      label: 'Impressions',
      accessor: (a) => a.impressions,
      formatting: formatLocalizedNumber,
      sortType: sortType('impressions'),
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'avgCostPerClick',
      label: 'Avg. CPC',
      accessor: (a) => a.avgCostPerClick,
      formatting: (a) => formatCurrency(a),
      supportValues: {
        increaseIsPositive: true,
      },
      sortType: sortType('avgCostPerClick'),
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'clicks',
      label: 'Clicks',
      accessor: (a) => a.clicks,
      formatting: formatLocalizedNumber,
      sortType: sortType('clicks'),
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'clickThroughRate',
      label: 'CTR',
      accessor: (a) => `${((a.clickThroughRate ? a.clickThroughRate : 0) * 100).toFixed(2)} %`,
      supportValues: {
        increaseIsPositive: true,
        monetary: false,
      },
      sortType: sortType('clickThroughRate'),
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'conversions',
      label: 'Conversions',
      accessor: (a) => a.conversions,
      formatting: formatLocalizedNumber,
      changeColumn: true,
      sortType: sortType('conversions'),
      Component: ComparisonCell,
    },
    {
      Header: 'conversionRate',
      label: 'Conv. Rate',
      supportValues: {
        increaseIsPositive: true,
        monetary: false,
      },
      accessor: (a) => `${((a.conversionRate ? a.conversionRate : 0) * 100).toFixed(2)} %`,
      sortType: sortType('conversionRate'),
      changeColumn: true,
      Component: ComparisonCell,
    },
    {
      Header: 'calls',
      label: 'Calls',
      accessor: (a) => a.calls,
      formatting: formatLocalizedNumber,
      changeColumn: true,
      sortType: sortType('calls'),
      Component: ComparisonCell,
    },
  ].filter((column) => removeColumns !== column.Header);

  const changeColumns = baseColumns
    .map((column) => {
      if (!column.changeColumn) return undefined;

      return {
        Header: `${SUPP_PREFIX}${column.Header}`,
        label: `label${SUPP_PREFIX}${column.Header}`,
        accessor: (a) => a[
          `${column.Header}${
            changesSuffix[
              (isPercentage ? PERCENTAGE_STRING : ABSOLUTE_STRING)
                  + (
                    comparisonPeriod === comparisonPeriods.prevYear
                      ? PREV_YEAR_STRING : PREV_PERIOD_STRING
                  )
            ]
          }`
        ],
        supportColumn: true,
        sortType: 'basic',
      };
    })
    .filter((value) => !!value);

  return [...baseColumns, ...changeColumns];
};

export default CampaignsColumns;
