import axiosClient from './axiosClient';
import { addParams } from './reqHelpers';

const getReportLink = async (tenantId, reportName) => {
  let localReports = {};
  try {
    const cachedReports = window.localStorage.getItem('report-dictionary-cache');
    localReports = JSON.parse(cachedReports) || {};
  } catch {
    localReports = {};
  }
  if (localReports[tenantId] && localReports[tenantId][reportName]) {
    return localReports[tenantId][reportName].links.self;
  }

  const response = await axiosClient.get(`${process.env.REACT_APP_REPORTING_API_BASE_URL}/v1/tenants/${tenantId}/reports`);
  const freshLocalReports = localReports;
  response.data.reports.forEach((item) => {
    if (!freshLocalReports[tenantId]) {
      freshLocalReports[tenantId] = {};
    }
    freshLocalReports[tenantId][item.name] = item;
  });

  window.localStorage.setItem('report-dictionary-cache', JSON.stringify(freshLocalReports));
  return freshLocalReports[tenantId][reportName].links.self;
};

/**
 *
 * @param {string} tenantId The tenant ID
 * @param {object} requestType The type of request: { base: 'report-key' }
 *  or { base: 'report-key', groupBy: 'another-report-key'}
 * @param {object} dates The date range for the request:
 * { startDate: '2021-01-01', endDate: '2021-01-31' }
 * @returns A {Promise<AxiosResponse<any>>}, for the requested report type and date range
 */
export default async function getReportData(tenantId, requestType, dates = {}, dimensions = null) {
  if (!requestType.base) throw new Error('Type does not have the base key');

  const url = await getReportLink(tenantId, requestType.base);
  if (!url) throw new Error('Report link not found');

  const finalUrl = addParams(url, dates.startDate, dates.endDate, requestType.groupBy, dimensions);
  return axiosClient.get(finalUrl);
}
