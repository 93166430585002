import React, { useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import BasicSortableHeader from 'components/common/table/cells/BasicSortableHeader';
import clientsColumns, { SUPP_PREFIX } from '../clientColumns';
import ClientSortMenuHeader from '../ClientSortMenuHeader';

const useClientsTable = ({ data, selectedColumns }) => {
  const columns = useMemo(
    () => clientsColumns(true, true, selectedColumns),
    [selectedColumns],
  );

  const table = useTable(
    {
      columns,
      data: Boolean(data) ? data : [],
      initialState: {
          sortBy: [
              {
                  id: 'dmMrr',
                  desc: true
              }
          ]
      },
    },
    useSortBy,
    usePagination,
  );

  const headers = table.columns
    .map((col) => {
      if (col.supportColumn) return undefined;
      const Component = col.changeColumn
        ? ClientSortMenuHeader
        : BasicSortableHeader;
      return (
        <Component
          id={col.id}
          key={col.Header}
          testkey={col.Header}
          label={col.label}
          isSorted={col.isSorted}
          isSortedDesc={col.isSortedDesc}
          sortBy={table.toggleSortBy}
          changeColumn={
            col.changeColumn &&
            table.columns.find((c) => c.id === `${SUPP_PREFIX}${col.id}`)
          }
        />
      );
    })
    .filter((val) => !!val);

  return {
    headers,
    table,
  };
};

export default useClientsTable;
