import React from 'react';
import { useDispatch } from 'react-redux';

import './UnauthenticatedApp.css';
import { ReactComponent as UsmLogo } from './usmLogo.svg';
import { ReactComponent as BeaconLogo } from './logo.svg';
import { logInAction } from './actions/UserActions';

function UnauthenticatedApp() {
  const dispatch = useDispatch();

  return (
    <div data-testid="sign-in-page" className="signIn d-flex flex-column justify-content-center align-items-center">
      <BeaconLogo className="signIn__beaconLogo m-3" alt="Beacon" />
      <h3 className="m-3">Let&apos;s dive into some digital marketing data!</h3>
      <button className="signIn__button m-5" type="button" onClick={() => dispatch(logInAction(`${window.location.pathname}${window.location.search}`))}>Sign In/Register</button>
      <a className="signIn__usmLogo" href="https://www.unionstreetmedia.com" target="_blank" rel="noopener noreferrer">
        <UsmLogo />
      </a>
    </div>
  );
}

export default UnauthenticatedApp;
