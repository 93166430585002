import React from 'react';
import PropTypes from 'prop-types';
import {
  GoogleDisplay,
  GoogleSearch,
  YouTube,
  Facebook,
} from 'components/common/icons';
import styles from './styles.module.scss';

const icons = {
  Facebook: Facebook,
  'Google Display': GoogleDisplay,
  'Google Search': GoogleSearch,
  'Google Video': YouTube,
};

function TitleWithPhoto({ title, icon = '' }) {
  const IconComponent = icons[icon];

  return (
    <div className={styles.container} date-testid="title-with-photo">
      {IconComponent ? <IconComponent className={styles.icon} /> : null}
      <h2 className={styles.title}>{title}</h2>
    </div>
  );
}

TitleWithPhoto.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)),
};

export { TitleWithPhoto };
