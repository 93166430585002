import React from 'react';
import { CheckboxWithLabel } from 'components/common';
import Modal from 'components/common/Modal';
import styles from '../styles.module.scss';

export default function ChooseMetricsModal({
  showModal,
  closeModal,
  onChangeValue,
  onClickApply,
  onClickReset,
  selectedMetrics,
  metrics,
}) {
  return (
    showModal && (
      <Modal
        title="Choose Metrics"
        onClose={closeModal}
        bottomLeftButtons={[
          {
            label: 'Cancel',
            onClick: closeModal,
          },
          { label: 'Reset', onClick: onClickReset },
        ]}
        bottomRightButtons={[
          {
            label: 'Apply',
            onClick: onClickApply,
            disabled: selectedMetrics.length === 0,
          },
        ]}
      >
        <div className={styles.chooseMetricsContainer}>
          {metrics.map((m) => (
            <div key={`metricsContainer-${m.title}`}>
              <h3 className={styles.chooseMetricsTitle}>{m.title} </h3>
              <div className={styles.chooseMetrics}>
                {m.values.map((option) => {
                  return (
                    <CheckboxWithLabel
                      id={`${option.name}-checkbox`}
                      key={`${option.name}-checkbox`}
                      isChecked={false}
                      label={option.label}
                      disabled={
                        selectedMetrics.filter((x) => x.name).length >= 2 &&
                        !selectedMetrics.some((x) => x.name === option.name)
                      }
                      checked={selectedMetrics.some(
                        (x) => x.name === option.name,
                      )}
                      onChange={(e) => {
                        onChangeValue({
                          checked: e.target.checked,
                          optionSelected: option,
                        });
                      }}
                      data-testid={`${option.name}-checkbox`}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    )
  );
}
