/**
 * Checks whether the user has permission to access the
 * desired action type.
 * @param {object} user - The user to check access for
 */
function userHasAccess(user) {
  if (user && user.groups && user.groups.includes('admin')) {
    return true;
  }

  return false;
}

// eslint-disable-next-line import/prefer-default-export
export { userHasAccess };
