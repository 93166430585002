import axiosClient from './axiosClient';

/**
 *
 * @param {string} tenantId
 * @param {string} websiteTraffic
 * @param {object} dates | contains startDate and endDate
 * @param {string} dimensions | dimensions separated by coma
 * @returns an async api call
 */
export async function getReportsWebTraffic(tenantId, websiteTraffic, dates, dimensions) {
  const url = `${process.env.REACT_APP_REPORTING_API_BASE_URL}/v1/tenants/${tenantId}/reports/${websiteTraffic}?filter[date]=gt,${dates.startDate}&filter[date]=lt,${dates.endDate}&dimensions=${dimensions}`;

  return axiosClient.get(url);
}
