import React from 'react';
import BasicTableRow from 'components/common/table/BasicTableRow';
import { usePagination, useSortBy, useTable } from 'react-table';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

export default function TotalRow({
  data,
  index,
  groupedBy,
  tableColumns,
  comparisonPrefixes,
  nonTotalColumns = [],
}) {
  const [targetElement, setTargetElement] = React.useState(false);
  const [tableData, setTableData] = React.useState({
    values: null,
    original: null,
  });

  const table = useTable(
    {
      columns: tableColumns,
      data: data ? data.rows : [],
    },
    useSortBy,
    usePagination,
  );

  const key = index.replace(' ', '');

  React.useLayoutEffect(() => {
    if (document.querySelector(`#table-${key} thead`)) {
      setTargetElement(document.querySelector(`#table-${key} thead`));
    }
  }, [key]);

  React.useEffect(() => {
    let tableValues;

    if (groupedBy === 'None' && table.page.length > 0) {
      tableValues = [table.page[0]];
    } else {
      tableValues = table.page.filter(
        (row) => row.original[groupedBy] === index,
      );
    }

    if (tableValues.length) {
      const { values, original } = tableValues[0];
      setTableData({ values, original });
    }
  }, [groupedBy, table, index, data, tableColumns]);

  if (!targetElement || !tableData.original || !tableData.values) {
    return null;
  }

  const { columns } = table;

  return ReactDOM.createPortal(
    <BasicTableRow
      className={styles.totalValues}
      rowComponents={columns
        .map(
          ({
            Header,
            Component,
            supportValues,
            formatting,
            supportColumn,
            changeColumn,
          }) => {
            if (supportColumn) return undefined;
            let changeColInfo;
            if (changeColumn) {
              changeColInfo = columns.find(
                (col) => col.id === `${comparisonPrefixes}${Header}`,
              );
            }
            if (nonTotalColumns.includes(Header)) return <></>;

            return (
              <Component
                key={Header}
                value={
                  formatting
                    ? formatting(tableData.values[Header])
                    : tableData.values[Header]
                }
                change={
                  changeColumn && changeColInfo.accessor(tableData.original)
                }
                monetary={supportValues && supportValues.monetary}
                increaseIsPositive={
                  supportValues && supportValues.increaseIsPositive
                }
                percentage
              />
            );
          },
        )
        .filter((a) => !!a)}
    />,
    targetElement,
  );
}
