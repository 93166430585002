import React from 'react';
import PropTypes from 'prop-types';
import SType from '../../typography/SType';

const RegularTextCell = ({ value }) => (
  <SType light className="text-secondary">{value}</SType>
);

RegularTextCell.propTypes = {
  value: PropTypes.node.isRequired,
};

export default RegularTextCell;
