import {
  parse,
  sub,
  startOfMonth,
  lastDayOfMonth,
  startOfQuarter,
  lastDayOfQuarter,
  subQuarters,
  startOfYear,
  lastDayOfYear,
} from 'date-fns';

export const formatingDates = {
  HYPHEN: 'M-d-u',
  SLASH: 'M/d/u',
  FULLMONTH: 'MMMM d, u',
};

export const checkFormatingValue = (value) => {
  if (value.includes('/')) {
    return formatingDates.SLASH;
  }
  if (value.includes('-')) {
    return formatingDates.HYPHEN;
  }
  return formatingDates.FULLMONTH;
};

export const getValidDate = (literalValue, dateFormat) => {
  const newDate = parse(literalValue, dateFormat, new Date());

  if (newDate.toString() === 'Invalid Date') {
    return false;
  }

  if (newDate.getFullYear() < 1900) {
    return false;
  }

  if (literalValue.includes(',') && literalValue.split(',')[1].length !== 5) {
    return false;
  }

  if (
    literalValue.split(' ')[0].toLowerCase() !== 'may'
    && literalValue.split(' ')[0].length <= 3
  ) {
    return false;
  }

  return newDate;
};

export const dateRangeOptions = [
  { value: 'Past 7 Days', label: 'Past 7 Days' },
  { value: 'Past 30 Days', label: 'Past 30 Days' },
  { value: 'This Month', label: 'This Month' },
  { value: 'Last Month', label: 'Last Month' },
  { value: 'This Quarter', label: 'This Quarter' },
  { value: 'Last Quarter', label: 'Last Quarter' },
  { value: 'This Year', label: 'This Year' },
  { value: 'Last Year', label: 'Last Year' },
  { value: 'Year to Date', label: 'Year to Date' },
  { value: 'Custom', label: 'Custom' },
];

export const dateRangeFunctions = {
  'Past 7 Days': [
    sub(new Date(), {
      days: 7,
    }),
    sub(new Date(), {
      days: 1,
    }),
  ],
  'Past 30 Days': [
    sub(new Date(), {
      days: 30,
    }),
    sub(new Date(), {
      days: 1,
    }),
  ],
  'This Month': [startOfMonth(new Date()), lastDayOfMonth(new Date())],
  'Last Month': [
    startOfMonth(
      sub(new Date(), {
        months: 1,
      }),
    ),
    lastDayOfMonth(
      sub(new Date(), {
        months: 1,
      }),
    ),
  ],
  'This Quarter': [startOfQuarter(new Date()), lastDayOfQuarter(new Date())],
  'Last Quarter': [
    startOfQuarter(subQuarters(new Date(), 1)),
    lastDayOfQuarter(subQuarters(new Date(), 1)),
  ],
  'This Year': [startOfYear(new Date()), lastDayOfYear(new Date())],
  'Last Year': [
    startOfYear(
      sub(new Date(), {
        years: 1,
      }),
    ),
    lastDayOfYear(
      sub(new Date(), {
        years: 1,
      }),
    ),
  ],
  'Year to Date': [
    startOfYear(new Date()),
    new Date(),
  ],
};
