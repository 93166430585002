/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/common/Select';
import './MetricsComparisionPicker.css';

export default function MetricsComparisonPicker({
  availableMetrics,
  onChange,
  selectedMetrics,
}) {
  const [firstMetricByName, setFirstMetricByName] = useState(
    selectedMetrics[0] ? selectedMetrics[0].name : null,
  );
  const [secondMetricByName, setSecondMetricByName] = useState(
    selectedMetrics[1] ? selectedMetrics[1].name : null,
  );

  const findMetricByName = useCallback(
    (name) => availableMetrics.find((item) => item.name === name),
    [availableMetrics],
  );

  useEffect(() => {
    onChange(
      findMetricByName(firstMetricByName),
      findMetricByName(secondMetricByName),
    );
  }, [firstMetricByName, secondMetricByName, findMetricByName, onChange]);

  const options = availableMetrics.map((metric) => ({
    label: metric.label,
    value: metric.name,
  }));

  return (
    <div className="metricsComparisionPicker__row">
      <Select
        className="metricsComparisionPicker--picker"
        data-testid="firstMetricPicker"
        options={options}
        value={findMetricByName(firstMetricByName)}
        onChange={(option) => setFirstMetricByName(option.value)}
      />
      VS
      <Select
        className="metricsComparisionPicker--picker"
        data-testid="secondMetricPicker"
        options={options}
        value={findMetricByName(secondMetricByName)}
        onChange={(option) => setSecondMetricByName(option.value)}
      />
    </div>
  );
}

const metricShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
});

MetricsComparisonPicker.propTypes = {
  availableMetrics: PropTypes.arrayOf(metricShape).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedMetrics: PropTypes.arrayOf(metricShape).isRequired,
};
