import {
  formatCurrency,
  formatMonthAbbrev,
  formatYearMonth,
  formatDateAsQuarter,
} from '../../utils/reporting';

export const monthlyLeadsMetrics = [
  { name: 'cpl', label: 'CPL', formatter: formatCurrency },
  { name: 'leadCount', label: 'Leads' },
  { name: 'cost', label: 'Total Spend', formatter: formatCurrency },
  { name: 'gaSpent', label: 'Google Ads Spend', formatter: formatCurrency },
  { name: 'fbSpent', label: 'Facebook Spend', formatter: formatCurrency },
  { name: 'monthlyPackageCost', label: 'Monthly Package Spend', formatter: formatCurrency },
];

export const monthlyLeadsColumns = [
  { Header: 'Month', accessor: (row) => formatYearMonth(row.month) },
  ...monthlyLeadsMetrics.map((metric) => ({
    Header: metric.label,
    accessor: (row) => (metric.formatter ? metric.formatter(row[metric.name]) : row[metric.name]),
  })),
];

export const quarterlyLeadsMetrics = [
  { name: 'cpl', label: 'CPL', formatter: formatCurrency },
  { name: 'leadCount', label: 'Leads' },
  { name: 'cost', label: 'Total Spend', formatter: formatCurrency },
  { name: 'gaSpent', label: 'Google Ads Spend', formatter: formatCurrency },
  { name: 'fbSpent', label: 'Facebook Spend', formatter: formatCurrency },
  { name: 'monthlyPackageCost', label: 'Monthly Package Spend', formatter: formatCurrency },
];

export const quarterlyLeadsColumns = [
  { Header: 'Quarter', accessor: (row) => `${row.year}-Q${row.quarter}` },
  ...quarterlyLeadsMetrics.map((metric) => ({
    Header: metric.label,
    accessor: (row) => (metric.formatter ? metric.formatter(row[metric.name]) : row[metric.name]),
  })),
];

export const costPerLeadMetrics = [
  { name: 'cpl', label: 'CPL', formatter: formatCurrency },
  { name: 'leadCount', label: 'Leads' },
  { name: 'cost', label: 'Total Spend', formatter: formatCurrency },
  { name: 'gaSpend', label: 'Google Ads Spend', formatter: formatCurrency },
  { name: 'fbSpend', label: 'Facebook Spend', formatter: formatCurrency },
  { name: 'packageSpend', label: 'Package Spend', formatter: formatCurrency },
];

export const costPerLeadColumns = [
  { Header: 'Summary', accessor: (row) => row.period },
  ...costPerLeadMetrics.map((metric) => ({
    Header: metric.label,
    accessor: (row) => (metric.formatter ? metric.formatter(row[metric.name]) : row[metric.name]),
  })),
];

export const monthlyBarChartMetrics = [
  { name: 'dateGroup', label: 'Month', formatter: formatMonthAbbrev },
  { name: 'leadCount', label: 'Leads' },
];

export const quarterlyBarChartMetrics = [
  { name: 'dateGroup', label: 'Quarter', formatter: formatDateAsQuarter },
  { name: 'leadCount', label: 'Leads' },
];
