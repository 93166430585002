import { formatSeconds, formatDecimalValue } from 'utils/reporting';

export const limitStringLength = (string, length) => (string && string.length > length
  ? `${string.slice(0, length - 3).trim()}...`
  : string);

export const addRelevantSymbols = (
  value,
  percentage,
  isTime,
  isDecimal,
  monetary,
) => {
  let finalValue = '';

  if (monetary) {
    finalValue += '$';
  }

  if (isTime) {
    finalValue = formatSeconds(value);
  } else if (isDecimal) {
    finalValue = formatDecimalValue(value);
  } else if (typeof value === 'number') {
    finalValue += value.toFixed(0);
  } else if (value === null && monetary) {
    finalValue += 0;
  } else {
    finalValue += value;
  }

  if (percentage) {
    finalValue = `${value.toFixed(1)}%`;
  }

  return finalValue;
};
