import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTenantSelector } from '../../../selectors/TenantsSelector';
import { fetchTenantAction } from '../../../actions/TenantActions';
import { tenantPrimaryTabs } from '../../../resources/route-helpers/tenant-primary-tabs';
import HorizontalMenu from '../horizontal-menu/HorizontalMenu';
import BasicButton from '../Buttons/BasicButton/BasicButton';
import FullPageSpinner from '../../FullPageSpinner';
import pageBaseStrings from '../../../resources/strings/page-base';

const TenantPageBase = ({ children }) => {
  const { tenantid } = useParams();
  const tenant = useSelector(getTenantSelector(tenantid));
  const dispatch = useDispatch();
  const url = window.location.pathname;

  React.useEffect(() => {
    if (tenantid && (!tenant || !tenant.id)) {
      dispatch(fetchTenantAction(tenantid));
    }
  }, [tenantid, tenant, dispatch]);

  if (!tenant || !tenant.id) {
    return (
      <div className="p-5 bg-white">
        <header className="d-flex">
          <BasicButton to="/clients" data-testid="clients-back-button" secondary>
            Back
          </BasicButton>
        </header>
        <h5 className="mt-4">{pageBaseStrings.noTenantText}</h5>
        <FullPageSpinner />
      </div>
    );
  }

  const pageBaseTabs = tenantPrimaryTabs.map((tab) => ({
    name: tab.name,
    to: tab.path(tenant.id),
    active: url.includes(tab.path(tenant.id)),
    testId: `tenantpagebase-tab-${tab.pathName}`,
  }));

  return (
    <>
      <header className="pt-5 px-5 bg-white d-flex">
        <BasicButton to="/clients" data-testid="clients-back-button" secondary>
          Back
        </BasicButton>
      </header>
      <h1 className="bg-white m-0 py-3 px-5">{tenant.name}</h1>
      <HorizontalMenu tabs={pageBaseTabs} className="bg-white pb-3 px-5" />
      <div className="px-5 pt-2">
        {children}
      </div>
    </>
  );
};

TenantPageBase.defaultProps = {
  children: undefined,
};

TenantPageBase.propTypes = {
  children: PropTypes.node,
};

export default TenantPageBase;
