import React from 'react';
import PropTypes from 'prop-types';

function SettingsIcon(props) {
  const { onClick = () => {}, className = '', fill='white' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="2 0 24 24"
      onClick={onClick}
      data-testid="SettingsIcon"
      className={className}
    >
      <g fill="white" fillRule="evenodd">
        <g>
          <g transform="translate(-232.000000, -191.000000) translate(224.000000, 183.000000)">
            <g>
              <path fill={fill} d="M13 4v3.1l-.05-.01c.656.126 1.266.38 1.803.736l.004.002 2.193-2.192 1.414 1.414-2.192 2.193c.36.544.615 1.159.74 1.819l-.012-.063L20 11v2h-3.1l.01-.05c-.126.656-.38 1.266-.736 1.803l-.002.004 2.192 2.193-1.414 1.414-2.193-2.192-.004.002c-.537.355-1.147.61-1.803.736l.05-.01V20h-2l-.001-3.1.063.012c-.66-.125-1.275-.38-1.815-.738l-.004-.002-2.193 2.192-1.414-1.414 2.192-2.193c-.357-.541-.612-1.151-.738-1.807l.01.05H4v-2l3.1-.001-.012.063c.125-.66.38-1.275.738-1.815l.002-.004L5.636 7.05 7.05 5.636l2.193 2.192c.544-.36 1.159-.615 1.819-.74l-.063.012L11 4h2zm-1 5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z" transform="translate(8.000000, 8.000000)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

SettingsIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export { SettingsIcon };
