import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOutAction } from '../../actions/UserActions';
import { getUserAttributes } from '../../api/authentication';
import BeaconLogo from '../../resources/assets/logo-icon-white.svg';
import BasicButton from '../common/Buttons/BasicButton/BasicButton';
import PopUpMenu from '../common/pop-up-menu/PopUpMenu';
import LType from '../common/typography/LType';
import MType from '../common/typography/MType';
import UserTempLogo from '../../resources/assets/UserTempLogo.svg';
import clientPageUrls from '../../resources/routes/client';
import { callFunctionOnBlur } from '../../resources/helpers/browserCompatibility';
import { HorizontalTripleBars } from 'components/common/icons';
import Drawer from 'components/common/Drawer';
import cx from 'classnames';
import styles from './styles.module.scss';

const Header = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (!user.sub) {
      const getUser = async () => {
        const userAtt = await getUserAttributes();
        setUser(userAtt);
      };
      getUser();
    }
  }, [user]);

  useEffect(() => callFunctionOnBlur(() => setMenuOpen(false)), []);

  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <div data-testid="Header" className={cx('header-custom', styles.header)}>
      <HorizontalTripleBars
        className={styles.horizontalTripleBars}
        onClick={() => {
          setShowDrawer(true);
        }}
      />
      <Link
        data-testid="main-header-logo"
        to={clientPageUrls.clientList}
        className="txt-sz-16 avenir-black text-white no-underline position-relative"
      >
        <img className="logo mr-16" src={BeaconLogo} alt="Beacon" />
        <span className={styles.beaconTitle}>BEACON</span>
      </Link>
      <div className={cx('center-children', styles.userImage)}>
        <button
          data-testid="header-picture-button"
          type="button"
          className="center-children picture-sm"
          onClick={(e) => {
            e.nativeEvent.preventDefault();
            setMenuOpen((p) => !p);
          }}
          onBlur={() => setMenuOpen(false)}
        >
          <img
            className={`${user.picture ? '' : 'low-contrast bg-white'} w-100`}
            src={user.picture || UserTempLogo}
            alt="Logged in user"
          />
        </button>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          className="reference-point-bottom-center d-flex justify-content-center"
          onMouseDown={(e) => e.nativeEvent.preventDefault()}
          onClick={(e) => e.nativeEvent.preventDefault()}
        >
          <PopUpMenu open={menuOpen}>
            <LType>
              {user.given_name
                ? `${user.given_name} ${user.family_name}`
                : user.name || 'Beacon User'}
            </LType>
            <MType className="text-secondary">{user.email || ''}</MType>
            <BasicButton
              data-testid="sign-out-button"
              className="mt-3"
              onClick={() => {
                dispatch(signOutAction());
                setMenuOpen(false);
              }}
            >
              Logout
            </BasicButton>
          </PopUpMenu>
        </div>
      </div>
      <Drawer show={showDrawer} onClose={onCloseDrawer}>
        <div className={styles.drawerLinksContent}>
          <Link
            to="/clients"
            className={styles.drawerLink}
            onClick={onCloseDrawer}
          >
            Clients
          </Link>
          <Link
            to="/benchmarking"
            className={styles.drawerLink}
            onClick={onCloseDrawer}
          >
            Benchmarking
          </Link>
          <Link
            to="/leads"
            className={styles.drawerLink}
            onClick={onCloseDrawer}
          >
            Leads
          </Link>
          <Link to="/seo" className={styles.drawerLink} onClick={onCloseDrawer}>
            SEO
          </Link>
          <Link
            to="/health-check"
            className={styles.drawerLink}
            onClick={onCloseDrawer}
          >
            Health Check
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
