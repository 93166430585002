export const groupedByOptions = [
  { value: 'None', label: 'None' },
  { value: 'digitalSuccessSpecialist', label: 'DSS' },
  { value: 'contentStrategist', label: 'Content Strategist' },
  { value: 'dmPackage', label: 'Package' },
];

export const filtersOptions = [
  { value: 'None', label: 'None' },
  { value: 'PoorSpendPerformance', label: 'Poor Spend Performance' },
];

export const searchFilters = [
  {
    name: 'client',
    handler: (row) => row.accountName,
    getId: (row) => row.tenantId,
    isIndependent: true,
  },
  {
    name: 'dss',
    handler: (row) => row.digitalSuccessSpecialist,
    getId: (row) => row.digitalSuccessSpecialist,
  },
  {
    name: 'content strategist',
    handler: (row) => row.contentStrategist,
    getId: (row) => row.contentStrategist,
  },
  {
    name: 'package',
    handler: (row) => row.dmPackages,
    getId: (row) => row.dmPackages,
    list: true,
  },
  {
    name: 'dm status',
    handler: (row) => row.dmStatus,
    getId: (row) => row.dmStatus,
  },
  {
    name: 'active',
    handler: (row) => (row.isActive ? 'Active' : 'Inactive'),
    getId: (row) => row.isActive,
  },
];

export const metricOptions = [
  {
    name: 'clientName',
    label: 'Client',
    isChecked: true,
  },
  {
    name: 'packages',
    label: 'Packages',
    isChecked: true,
  },
  {
    name: 'activeStatus',
    label: 'Active',
    isChecked: true,
  },
  {
    name: 'dmStatus',
    label: 'Dm Status',
    isChecked: true,
  },
  {
    name: 'dmMrr',
    label: 'MRR',
    isChecked: true,
  },
  {
    name: 'contentStrategist',
    label: 'Content Strategist',
    isChecked: true,
  },
  {
    name: 'digitalSuccessSpecialist',
    label: 'DSS',
    isChecked: true,
  },
  {
    name: '30DayCpl',
    label: 'Cost Per Lead (30 days)',
    isChecked: true,
  },
  {
    name: '30DayLeads',
    label: 'Leads (30 days)',
    isChecked: true,
  },
  {
    name: '30DayCampaignCount',
    label: 'Campaigns (30 days)',
    isChecked: true,
  },
  {
    name: 'totalMediaSpend',
    label: 'Total media spend (30 days)',
    isChecked: true,
  },
  {
    name: 'facebookAdsMediaSpend',
    label: 'Facebook spend (30 days)',
    isChecked: true,
  },
  {
    name: 'googleAdsMediaSpend',
    label: 'Google spend (30 days)',
    isChecked: true,
  },
  {
    name: 'conversions',
    label: 'Paid Conversions (30 days)',
    isChecked: false,
  },
  {
    name: 'clickThroughRate',
    label: 'Paid CTR (30 days)',
    isChecked: false,
  },
  {
    name: 'conversionRate',
    label: 'Paid Conv. Rate (30 days)',
    isChecked: false,
  },
];
