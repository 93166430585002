const reportKey = {
  costPerLead: 'cost-per-lead',
  monthlyLeadsGenerated: 'monthly-leads-generated',
  quarterlyLeads: 'quarterly-leads',
};

const groupBy = {
  month: 'month',
  quarterly: 'quarter',
};

export const leadReqTypes = {
  costPerLead: {
    base: reportKey.costPerLead,
  },
  costPerLeadByMonth: {
    base: reportKey.costPerLead,
    groupBy: groupBy.month,
  },
  monthlyLeadsGenerated: {
    base: reportKey.monthlyLeadsGenerated,
  },
  quarterlyLeads: {
    base: reportKey.quarterlyLeads,
    groupBy: groupBy.quarterly,
  },
};

export default leadReqTypes;
