import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TitleWithPhoto, Loader } from 'components/common';
import GroupBy from 'components/common/GroupBy';
import { groupAndOrderTableDataNames } from 'utils';
import cx from 'classnames';
import { PrimaryButton } from 'components/common/Buttons';
import ChooseMetricsModal from 'components/common/ChooseMetricsModal';
import { SettingsIcon, FilterIcon } from 'components/common/icons';
import TotalRow from 'components/common/TotalRow';
import Select from 'components/common/Select';
import { differenceInDays } from 'date-fns';
import TextualizedFilters from 'components/common/TextualizedFilters';
import { getTenantsList, getGroupedTenantsSelector } from '../../selectors/TenantsSelector';
import { tenantActionTypes } from '../../actions/ActionTypes';
import { fetchAllTenantsAction } from '../../actions/TenantActions';
import { getFilteredTenantList } from '../../resources/helpers/search';
import SuggestionTextBox from '../../components/common/inputs/SuggestionTextBox';
import { isLoadingSelector } from '../../selectors/StatusSelectors';
import XXLType from '../../components/common/typography/XXLType';
import ClientsTable from './ClientsTable';
import useClientData from './hooks/useClientData';
import clientsColumns from './ClientsTable/clientColumns';

import {
  groupedByOptions,
  searchFilters,
  metricOptions,
  filtersOptions,
} from './constants';
import styles from './styles.module.scss';

const ClientsPage = (props) => {
  const { location, history } = props;
  const tenantsList = useSelector(getTenantsList);
  const isLoading = useSelector(
    isLoadingSelector([tenantActionTypes.fetchAll, tenantActionTypes.fetchGroupedTenants]),
  );
  const [localLoading, setLocalLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    daysActive: 30,
    cpl: 75,
    leads: 5,
  });
  const [dataFilters, setDataFilters] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [tableNames, setTableNames] = useState([]);
  const [groupedBy, setGroupedBy] = useState(groupedByOptions[0]);
  const [filteredBy, setFilteredBy] = useState(filtersOptions[0]);
  const dispatch = useDispatch();

  const [defaultTableMetrics, setDefaultTableMetrics] = React.useState(
    metricOptions.filter((option) => option.name !== 'clientName'),
  );

  const [selectedColumns, setSelectedColumns] = React.useState(
    metricOptions
      .filter((option) => option.isChecked)
      .map((a) => a.name)
      .join(','),
  );

  const [currentlySelectedColumns, setCurrentlySelectedColumns] = React.useState(selectedColumns);

  const handleChangeGroupedBy = (e) => {
    const path = e.value === 'None'
      ? location.pathname
      : `${location.pathname}?groupBy=${e.value}`;
    history.replace(path);
    setGroupedBy(e);
  };

  const handleChangeFilteredBy = (e) => {
    const path = e.value === 'None'
      ? location.pathname
      : `${location.pathname}?filteredBy=${e.value}`;
    history.replace(path);
    setFilteredBy(e);
  };

  useEffect(() => {
    if (!tenantsList || !tenantsList.length) dispatch(fetchAllTenantsAction());
  }, [tenantsList, dispatch]);

  useEffect(() => {
    const groupBy = new URLSearchParams(location.search).get('groupBy');
    const filteredBy = new URLSearchParams(location.search).get('filteredBy');

    const newFilteredBy = filtersOptions.find((x) => x.value === filteredBy);
    const newGroupBy = groupedByOptions.find((x) => x.value === groupBy);
    if (newGroupBy) {
      setGroupedBy(newGroupBy);
    }
    if (newFilteredBy) {
      setShowFilters(true);
      setFilteredBy(newFilteredBy);
    }
  }, [location.search]);

  useEffect(() => {
    let filteredResults = tenantsList;

    if (dataFilters.length > 0) {
      let independentResults = [];
      let independentFiltersExist = false;
      let dependentFiltersExist = false;
      searchFilters.forEach((filter) => {
        const dependentValues = dataFilters
          .map((filterObj) => (!filter.isIndependent && filterObj.name === filter.name
            ? filterObj.value
            : undefined))
          .filter((item) => !!item);

        if (dependentValues.length > 0) {
          dependentFiltersExist = true;
          filteredResults = filteredResults.filter((row) => getFilteredTenantList(dependentValues, filter.handler(row)));
        }
        const independentValues = dataFilters
          .map((filterObj) => (filter.isIndependent && filterObj.name === filter.name
            ? filterObj.value
            : undefined))
          .filter((item) => !!item);

        if (independentValues.length > 0) {
          independentFiltersExist = true;
          if (dependentFiltersExist) {
            filteredResults = filteredResults.filter((row) => getFilteredTenantList(independentValues, filter.handler(row)));
          } else {
            const independentResult = filteredResults.filter((row) => getFilteredTenantList(independentValues, filter.handler(row)));
            if (independentResult.length > 0) {
              independentResults = [...independentResult];
            }
          }
        }
      });

      if (dependentFiltersExist && independentFiltersExist) {
        filteredResults = [...independentResults, ...filteredResults];
      } else if (independentFiltersExist && !dependentFiltersExist) {
        filteredResults = independentResults;
      }

      filteredResults = [...new Set(filteredResults)];
    }

    if (filteredBy.value === 'PoorSpendPerformance') {
      filteredResults = filteredResults.filter(
        (row) => row['30DayLeads'] < filters.leads
          && row['30DayCpl'] > filters.cpl
          && differenceInDays(new Date(), new Date(row.dmStartDate)) > filters.daysActive,
      );
    }

    setFilteredData(filteredResults);
  }, [dataFilters, tenantsList, filteredBy, filters]);

  useEffect(() => {
    setTableNames(groupAndOrderTableDataNames(groupedBy, tenantsList));
  }, [groupedBy, tenantsList]);

  const nonGroupedTotalRowsData = useClientData(currentlySelectedColumns.split(','), groupedBy.value, filteredData);

  const getDataArray = (array, key) => {
    if (key === `No ${groupedBy.label}`) {
      key = null;
    }

    if (groupedBy.value === 'dmPackages') {
      const emptyArrayValidation = (arr, str) => (str === null ? arr.length === 0 : arr.includes(str));

      return array.some((data) => emptyArrayValidation(data.dmPackages, key))
        ? array.filter((data) => emptyArrayValidation(data.dmPackages, key))
        : [];
    }
    return array.some((data) => data[groupedBy.value] === key)
      ? array.filter((data) => data[groupedBy.value] === key)
      : [];
  };

  useEffect(() => {
    if (filteredData.length) setLocalLoading(false);
    else setLocalLoading(true);
  }, [filteredData]);

  const [showModal, setShowModal] = useState(false);

  const onChangeMetricValue = ({ checked, optionSelected }) => {
    setDefaultTableMetrics([
      ...defaultTableMetrics.map((metric) => (metric.name !== optionSelected.name
        ? metric
        : { ...metric, isChecked: checked })),
    ]);
    if (optionSelected.name && !checked) {
      const updatedMetrics = selectedColumns.split(',');
      const index = updatedMetrics.indexOf(optionSelected.name);
      if (index > -1) {
        updatedMetrics.splice(index, 1);
      }
      setSelectedColumns(updatedMetrics.join(','));
    }
    if (optionSelected.name && checked) {
      setSelectedColumns(`${selectedColumns},${optionSelected.name}`);
    }
  };

  const applyNewMetrics = () => {
    setCurrentlySelectedColumns(selectedColumns);
    setShowModal(false);
  };

  const resetMetrics = (optionSelected) => {
    setSelectedColumns(
      metricOptions
        .filter((option) => option.isChecked)
        .map((a) => a.name)
        .join(','),
    );
    setDefaultTableMetrics([
      ...metricOptions
        .filter((option) => option.name !== 'clientName')
        .map((metric) => (metric.name !== optionSelected.name
          ? metric
          : { ...metric, isChecked: true })),
    ]);
  };

  const tableColumns = clientsColumns(false, 'prevYear', currentlySelectedColumns);
  const groupedTotalData = useSelector(getGroupedTenantsSelector(groupedBy.value));
  const onFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <>
      <header className={cx('px-5 pb-4 bg-white', styles.clientsHeader)}>
        <div className={cx(styles.fullWidth, styles.clientsHeaderOptions)}>
          <XXLType className="pt-5 pb-4">Clients</XXLType>
          <div className={styles.headerFiltersContainer}>
            <SuggestionTextBox
              data={tenantsList}
              filters={searchFilters}
              activeFilters={dataFilters}
              setActiveFilters={setDataFilters}
            />
            <PrimaryButton
              label={showFilters ? 'Hide filters' : 'Show filters'}
              className={styles.showFilterButton}
              onClick={() => {
                setShowFilters((prevShowModal) => !prevShowModal);
              }}
              icon={<FilterIcon className={styles.filterIcon} />}
            />
            <div className={styles.groupByWrapper}>
              <GroupBy
                options={groupedByOptions}
                value={groupedBy}
                onChange={handleChangeGroupedBy}
              />
              <PrimaryButton
                label="Settings"
                className={styles.primaryButton}
                onClick={() => {
                  setShowModal((prevShowModal) => !prevShowModal);
                }}
                icon={<SettingsIcon />}
              />
            </div>
          </div>
          <div
            className={cx(
              styles.filtersInfoContainer,
              showFilters ? styles.showFilters : '',
            )}
          >
            <strong className={styles.strongFilterTitle}>filter</strong>
            <Select
              className={styles.filtersSelect}
              options={filtersOptions}
              value={filteredBy}
              data-testid="filters-select"
              onChange={handleChangeFilteredBy}
            />
            <TextualizedFilters
              filteredBy={filteredBy}
              clientsNumber={filteredData.length}
              onFiltersChange={onFiltersChange}
              oldFilters={filters}
            />
          </div>
        </div>
        {showModal && (
          <ChooseMetricsModal
            showModal={showModal}
            onClickApply={applyNewMetrics}
            onClickReset={resetMetrics}
            onChangeValue={onChangeMetricValue}
            closeModal={() => setShowModal((prevShowModal) => !prevShowModal)}
            metrics={defaultTableMetrics}
          />
        )}
      </header>
      <div className="p-5">
        <Loader
          isLoading={isLoading || localLoading}
          style={{ marginTop: '10rem' }}
        >
          {groupedBy.value !== 'None' && groupedTotalData && groupedTotalData[groupedBy.value] ? (
            tableNames.map((key) => (
              <div
                className={styles.tableContainer}
                key={`clientsTable-${key}`}
              >
                <TitleWithPhoto title={key} />
                <TotalRow
                  data={groupedTotalData[groupedBy.value].data}
                  index={key}
                  comparisonPeriod="prevYear"
                  groupedBy={groupedBy.value}
                  tableColumns={tableColumns}
                  comparisonPrefixes="supportChange"
                  nonTotalColumns={['clientName', 'activeStatus', 'contentStrategist', 'dmStatus', 'packages', 'digitalSuccessSpecialist']}
                />
                <ClientsTable
                  data={getDataArray(filteredData, key)}
                  selectedColumns={currentlySelectedColumns}
                  rowsPerPage={10}
                  className={styles.clientsTable}
                  id={`table-${key.replace(' ', '')}`}
                />
              </div>
            ))
          ) : (
            <>
              <TotalRow
                data={nonGroupedTotalRowsData.totalRowsData}
                index="Clients"
                comparisonPeriod="prevYear"
                groupedBy="None"
                tableColumns={tableColumns}
                comparisonPrefixes="supportChange"
                nonTotalColumns={['clientName', 'activeStatus', 'contentStrategist', 'dmStatus', 'packages', 'digitalSuccessSpecialist']}
              />
              <ClientsTable
                data={filteredData}
                rowsPerPage={50}
                selectedColumns={currentlySelectedColumns}
                className={styles.clientsTable}
                id="table-Clients"
              />

            </>
          )}
        </Loader>
      </div>
    </>
  );
};

export default ClientsPage;
