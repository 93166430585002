/** This method will track the event to google analytic
 *
 * @param {*} event example: 'customEvent'
 * @param {*} category example: 'Basic Dropdown'
 * @param {*} action example: 'Option Selected'
 * @param {*} label example: 'labelName'
 * @param {*} value example: 'value'
 */

export const trackGAEvent = ({
  event, category, action, label, value,
}) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event,
      category,
      action,
      label,
      value,
    });
  }
};
