import { userActionTypes } from '../actions/ActionTypes';

const initialState = {
  userInfo: {},
  errorMessage: '',
};

const userReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case userActionTypes.fetchUserSuccess:
      return { ...state, userInfo: payload, errorMessage: '' };
    case userActionTypes.fetchUserError:
      return { ...state, errorMessage: payload };

    case userActionTypes.signOutSuccess:
      return { ...initialState };
    case userActionTypes.signOutError:
      return { ...state, errorMessage: payload };

    case userActionTypes.loginUserSuccess:
      return { ...state, userInfo: payload, errorMessage: '' };
    case userActionTypes.loginUserRequest:
      return { ...state, errorMessage: payload };

    default:
      return state;
  }
};

export default userReducer;
