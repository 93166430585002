import React from 'react';
import {
  getReportsWebTraffic,
  getCampaignsByChannel,
  getCampaignsReports,
} from 'api';
import reportTypes from 'api/reportTypes';
import { useParams } from 'react-router-dom';
import { getUserInfo } from 'selectors/UserSelectors';
import { useSelector } from 'react-redux';
import { joinDataOnKey } from './utils';
import { getSumData, getRelativeChange } from '../../../utils/reporting';

const sumCalculationColumns = [
  'bounces',
  'campaignCount',
  'clicks',
  'conversions',
  'newUsers',
  'pageviews',
  'sessions',
  'sessionDuration',
  'spend',
];

function getFilteredData(data, item) {
  const column = item;
  const filteredArray = data.filter((element) => element[column] >= 0);
  return filteredArray;
}

function getComparisonColumns(columns) {
  const updatedColumns = [];
  columns.forEach((item) => {
    updatedColumns.push(item);
    updatedColumns.push(`${item}PrevPeriod`);
    updatedColumns.push(`${item}PrevYear`);
  });
  return updatedColumns;
}

function getDataTotals(data, diffDays) {
  const totals = {};
  const metricColumns = getComparisonColumns(sumCalculationColumns);
  if (data.rows.length > 0) {
    metricColumns.forEach((item) => {
      totals[item] = getSumData(getFilteredData(data.rows, item), item);
    });
  }

  if (totals.sessionsPrevPeriod && totals.sessions) {
    totals.sessionsRelativeChangePrevPeriod = getRelativeChange(
      totals.sessions,
      totals.sessionsPrevPeriod,
    );
  }

  if (totals.sessionsPrevYear && totals.sessions) {
    totals.sessionsRelativeChangePrevYear = getRelativeChange(
      totals.sessions,
      totals.sessionsPrevYear,
    );
  }

  if (totals.conversionsPrevPeriod && totals.conversions) {
    totals.conversionsRelativeChangePrevPeriod = getRelativeChange(
      totals.conversions,
      totals.conversionsPrevPeriod,
    );
  }

  if (totals.conversionsPrevYear && totals.conversions) {
    totals.conversionsRelativeChangePrevYear = getRelativeChange(
      totals.conversions,
      totals.conversionsPrevYear,
    );
  }

  if (totals.newUsersPrevPeriod && totals.newUsers) {
    totals.newUsersRelativeChangePrevPeriod = getRelativeChange(
      totals.newUsers,
      totals.newUsersPrevPeriod,
    );
  }

  if (totals.newUsersPrevYear && totals.newUsers) {
    totals.newUsersRelativeChangePrevYear = getRelativeChange(
      totals.newUsers,
      totals.newUsersPrevYear,
    );
  }

  if (totals.sessions && totals.pageviews) {
    totals.pageviewsPerSession = totals.pageviews / totals.sessions;
  }

  if (totals.sessionsPrevPeriod && totals.pageviewsPrevPeriod) {
    totals.pageviewsPerSessionPrevPeriod = totals.pageviewsPrevPeriod / totals.sessionsPrevPeriod;
  }

  if (totals.sessionsPrevYear && totals.pageviewsPrevYear) {
    totals.pageviewsPerSessionPrevYear = totals.pageviewsPrevYear / totals.sessionsPrevYear;
  }

  if (totals.pageviewsPerSession && totals.pageviewsPerSessionPrevPeriod) {
    totals.pageviewsPerSessionRelativeChangePrevPeriod = getRelativeChange(
      totals.pageviewsPerSession,
      totals.pageviewsPerSessionPrevPeriod,
    );
  }

  if (totals.pageviewsPerSession && totals.pageviewsPerSessionPrevYear) {
    totals.pageviewsPerSessionRelativeChangePrevYear = getRelativeChange(
      totals.pageviewsPerSession,
      totals.pageviewsPerSessionPrevYear,
    );
  }

  if (totals.pageviewsPrevPeriod && totals.pageviews) {
    totals.pageviewsRelativeChangePrevPeriod = getRelativeChange(
      totals.pageviews,
      totals.pageviewsPrevPeriod,
    );
  }

  if (totals.pageviewsPrevYear && totals.pageviews) {
    totals.pageviewsRelativeChangePrevYear = getRelativeChange(
      totals.pageviews,
      totals.pageviewsPrevYear,
    );
  }

  if (totals.bounces && totals.sessions) {
    totals.bounceRate = totals.bounces / totals.sessions;
  }

  if (totals.bouncesPrevPeriod && totals.sessionsPrevPeriod) {
    totals.bounceRatePrevPeriod = totals.bouncesPrevPeriod / totals.sessionsPrevPeriod;
  }

  if (totals.bouncesPrevYear && totals.sessionsPrevYear) {
    totals.bounceRatePrevYear = totals.bouncesPrevYear / totals.sessionsPrevYear;
  }

  if (totals.bounceRate && totals.bounceRatePrevPeriod) {
    totals.bounceRateRelativeChangePrevPeriod = getRelativeChange(
      totals.bounceRate,
      totals.bounceRatePrevPeriod,
    );
  }

  if (totals.bounceRate && totals.bounceRatePrevYear) {
    totals.bounceRateRelativeChangePrevYear = getRelativeChange(
      totals.bounceRate,
      totals.bounceRatePrevYear,
    );
  }

  if (totals.sessions && totals.sessionDuration) {
    totals.avgSessionDuration = totals.sessionDuration / totals.sessions;
  }

  if (totals.sessionsPrevPeriod && totals.sessionDurationPrevPeriod) {
    totals.avgSessionDurationPrevPeriod = totals.sessionDurationPrevPeriod / totals.sessionsPrevPeriod; // eslint-disable-line
  }

  if (totals.sessionsPrevYear && totals.sessionDurationPrevYear) {
    totals.avgSessionDurationPrevYear = totals.sessionDurationPrevYear / totals.sessionsPrevYear;
  }

  if (totals.avgSessionDuration && totals.avgSessionDurationPrevPeriod) {
    totals.avgSessionDurationRelativeChangePrevPeriod = getRelativeChange(
      totals.avgSessionDuration,
      totals.avgSessionDurationPrevPeriod,
    );
  }

  if (totals.avgSessionDuration && totals.sessionDurationPrevYear) {
    totals.avgSessionDurationRelativeChangePrevYear = getRelativeChange(
      totals.avgSessionDuration,
      totals.avgSessionDurationPrevYear,
    );
  }

  if (totals.conversions && totals.sessions) {
    totals.conversionRate = totals.conversions / totals.sessions;
  }

  if (totals.conversionsPrevPeriod && totals.sessionsPrevPeriod) {
    totals.conversionRatePrevPeriod = totals.conversionsPrevPeriod / totals.sessionsPrevPeriod;
  }

  if (totals.conversionsPrevYear && totals.sessionsPrevYear) {
    totals.conversionRatePrevYear = totals.conversionsPrevYear / totals.sessionsPrevYear;
  }

  if (totals.conversionRate && totals.conversionRatePrevPeriod) {
    totals.conversionRateRelativeChangePrevPeriod = getRelativeChange(
      totals.conversionRate,
      totals.conversionRatePrevPeriod,
    );
  }

  if (totals.conversionRate && totals.conversionRatePrevYear) {
    totals.conversionRateRelativeChangePrevYear = getRelativeChange(
      totals.conversionRate,
      totals.conversionRatePrevYear,
    );
  }

  if (totals.spend && diffDays) {
    totals.avgDailyCost = totals.spend / diffDays;
  }

  if (totals.spendPrevPeriod && diffDays) {
    totals.avgDailyCostPrevPeriod = totals.spendPrevPeriod / diffDays;
  }

  if (totals.spendPrevYear && diffDays) {
    totals.avgDailyCostPrevYear = totals.spendPrevYear / diffDays;
  }

  if (totals.avgDailyCost && totals.avgDailyCostPrevPeriod) {
    totals.avgDailyCostRelativeChangePrevPeriod = getRelativeChange(
      totals.avgDailyCost,
      totals.avgDailyCostPrevPeriod,
    );
  }

  if (totals.avgDailyCost && totals.avgDailyCostPrevYear) {
    totals.avgDailyCostRelativeChangePrevYear = getRelativeChange(
      totals.avgDailyCost,
      totals.avgDailyCostPrevYear,
    );
  }

  if (totals.campaignCount && totals.campaignCountPrevPeriod) {
    totals.campaignCountRelativeChangePrevPeriod = getRelativeChange(
      totals.campaignCount,
      totals.campaignCountPrevPeriod,
    );
  }

  if (totals.campaignCount && totals.campaignCountPrevYear) {
    totals.campaignCountRelativeChangePrevYear = getRelativeChange(
      totals.campaignCount,
      totals.campaignCountPrevYear,
    );
  }

  return totals;
}

const useWebTrafficData = ({ startDate, endDate }) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const user = useSelector(getUserInfo);
  const { tenantid } = useParams();
  const [data, setData] = React.useState({
    rows: [],
  });
  const [graphData, setGraphData] = React.useState({
    rows: [],
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const groupedBy = { value: 'table', label: 'Table' };

  React.useEffect(() => {
    if (tenantid && startDate && endDate) {
      setIsLoading(true);
      setError(null);
      Promise.all([
        getReportsWebTraffic(
          tenantid,
          reportTypes.websitePerformanceReports.websiteTraffic.base,
          { startDate, endDate },
          'channel',
        ),
        getCampaignsByChannel(tenantid, reportTypes.campaigns.base, {
          startDate,
          endDate,
        }),
        // get data for comparison lines graph
        getReportsWebTraffic(
          tenantid,
          reportTypes.websitePerformanceReports.websiteTraffic.base,
          { startDate, endDate },
          'date',
        ),
        getCampaignsReports(
          tenantid,
          reportTypes.campaigns.base,
          {
            startDate,
            endDate,
          },
          'date',
        ),
      ])
        .then((values) => {
          // join data on channel
          const joinedData = joinDataOnKey(
            values[0].data,
            values[1].data,
            'channel',
          );
          const graphJoinedData = joinDataOnKey(
            values[2].data,
            values[3].data,
            'date',
          );
          setData({
            ...joinedData,
            rows: joinedData.rows.sort((a, b) => (a.spend < b.spend ? 1 : -1)),
            totalRowsData: {
              ...joinedData,
              rows: [{ ...getDataTotals(joinedData, diffDays), table: 'Channel Breakdown' }],
            },
          });
          setGraphData({
            graphJoinedData,
            rows: graphJoinedData.rows
              .map((a, index) => ({
                ...a,
                date: new Date(`${a.date} 00:00:00`).getTime(),
                conversionRate: (
                  (a.conversionRate ? a.conversionRate : 0) * 100
                ).toFixed(2),
                conversionRateAbsoluteChangePrevPeriod: (
                  (a.conversionRateAbsoluteChangePrevPeriod
                    ? a.conversionRateAbsoluteChangePrevPeriod
                    : 0) * 100
                ).toFixed(2),
                conversionRateAbsoluteChangePrevYear: (
                  (a.conversionRateAbsoluteChangePrevYear
                    ? a.conversionRateAbsoluteChangePrevYear
                    : 0) * 100
                ).toFixed(2),
                conversionRatePrevPeriod: (
                  (a.conversionRatePrevPeriod
                    ? a.conversionRatePrevPeriod
                    : 0) * 100
                ).toFixed(2),
                conversionRatePrevYear: (
                  (a.conversionRatePrevYear ? a.conversionRatePrevYear : 0)
                  * 100
                ).toFixed(2),
                bounceRate: ((a.bounceRate ? a.bounceRate : 0) * 100).toFixed(
                  2,
                ),
                bounceRateAbsoluteChangePrevPeriod: (
                  (a.bounceRateAbsoluteChangePrevPeriod
                    ? a.bounceRateAbsoluteChangePrevPeriod
                    : 0) * 100
                ).toFixed(2),
                bounceRateAbsoluteChangePrevYear: (
                  (a.bounceRateAbsoluteChangePrevYear
                    ? a.bounceRateAbsoluteChangePrevYear
                    : 0) * 100
                ).toFixed(2),
                bounceRatePrevPeriod: (
                  (a.bounceRatePrevPeriod ? a.bounceRatePrevPeriod : 0) * 100
                ).toFixed(2),
                bounceRatePrevYear: (
                  (a.bounceRatePrevYear ? a.bounceRatePrevYear : 0) * 100
                ).toFixed(2),
                index,
              }))
              .sort((a, b) => a.date - b.date),
          });
        })
        .then(() => setIsLoading(false))
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
    return () => {
      // TODO: Cancel API request to prevent memory leak
      // https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect
    };
  }, [tenantid, user.accessToken, startDate, endDate, diffDays]);

  return {
    data,
    isLoading,
    error,
    graphData,
    groupedBy,
  };
};

export default useWebTrafficData;
