import { combineReducers } from 'redux';
import currentUser from './userReducer';
import status from './StatusReducer';
import tenants from './tenantReducer';
import campaigns from './campaignsReducer';

export default combineReducers({
  currentUser,
  status,
  tenants,
  campaigns,
});
