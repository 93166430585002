import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SType from '../../typography/SType';
import MType from '../../typography/MType';
import Triangle from '../../ui-shapes/triangle';
import { addRelevantSymbols } from '../../../../resources/helpers/strings';
import styles from './styles.module.scss';

const ComparisonCell = ({
  value, percentage, monetary, increaseIsPositive, increaseIsNeutral, change,
}) => {
  const isTrendingUp = change > 0;
  const isChangeZero = change === 0;
  const changeHasValue = change ? true : !!isChangeZero;
  const isPositive = increaseIsPositive === isTrendingUp;
  const difference = isChangeZero ? 0 : Math.abs((percentage ? 100 : 1) * change);
  const differenceCssClass = useMemo(() => () => {
    if (increaseIsNeutral || isChangeZero) {
      return `text-info ${styles.neutralText}`;
    }
    return isPositive ? 'text-success' : 'text-danger';
  }, [increaseIsNeutral, isPositive, isChangeZero]);

  return (
    <div
      data-testid="comparisson-cell-parent"
      className={
        `center-children justify-content-start ${styles.comparisonCell}`
      }
    >
      <MType className="text-secondary mr-1 first-value">
        {addRelevantSymbols(value, false, false, false, monetary)}
      </MType>
      {changeHasValue
        ? (
          <SType light className={`center-children text-secondary ${differenceCssClass()}`}>
            { isChangeZero === false && (
            <Triangle
              direction={isTrendingUp ? 'up' : 'down'}
              color="currentcolor"
            />
            )}
            {addRelevantSymbols(difference, percentage)}
          </SType>
        ) : ''}
    </div>
  );
};

ComparisonCell.defaultProps = {
  percentage: true,
  increaseIsPositive: true,
  increaseIsNeutral: false,
  monetary: false,
};

ComparisonCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.bool,
  monetary: PropTypes.bool,
  increaseIsPositive: PropTypes.bool,
  increaseIsNeutral: PropTypes.bool,
  change: PropTypes.number.isRequired,
};

export default ComparisonCell;
