const reportKey = {
  dmConfiguration: 'dm-configuration',
};

export const dmConfigurationReq = {
  dmConfiguration: {
    base: reportKey.dmConfiguration,
  },
};

export default dmConfigurationReq;
