import React from 'react';
import Triangle from '../../ui-shapes/triangle';
import styles from './styles.module.scss';
import SType from '../../typography/SType';
import PropTypes from 'prop-types';

function ChangeIndicator({ value, increase, positive }) {
  const handleColor = () => {
    if (value === 0) {
      return 'text-normal';
    }
    if (positive) {
      return 'text-success';
    }
    return 'text-danger';
  };

  return (
    <td className={handleColor()} data-testid={handleColor()}>
      <div className="d-flex">
        <div className={styles.changeIconContainer}>
          {value ? (
            <Triangle
              direction={increase ? 'up' : 'down'}
              color="currentcolor"
            />
          ) : null}
        </div>
        <SType light>{`${value.toFixed(1)}%`}</SType>
      </div>
    </td>
  );
}

ChangeIndicator.propTypes = {
  value: PropTypes.number,
  increase: PropTypes.bool,
  positive: PropTypes.bool,
};

export default ChangeIndicator;
