import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './SinglePill.css';

const SinglePill = ({
  children, to, active, datatestid,
}) => (
  <li className="nav-item">
    <Link
      to={to}
      className={`general-horizontal-pill-tab ${
        active ? 'horizontal-pill-tab-active' : ''
      }`}
      data-testid={datatestid}
    >
      {children}
    </Link>
  </li>
);

SinglePill.defaultProps = {
  active: false,
  datatestid: '',
};

SinglePill.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
  datatestid: PropTypes.string,
};

export default SinglePill;
