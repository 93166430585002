import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from './utils/localStorage';
import './AuthenticatedApp.css';
import Landing from './pages/landing';
import Organic from './pages/organic';
import Leads from './pages/leads';
import Campaigns from './pages/campaigns';
import clientPageUrls from './resources/routes/client';
import ClientsPage from './pages/clients';
import LeadsList from './pages/leadsList';
import Benchmarking from './pages/benchmarking';
import Seo from './pages/seo';
import HealthCheck from './pages/healthCheck';
import Header from './components/header/Header';
import Overview from './pages/overview';

function ContentArea() {
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname === '/')
      setImmediate(() => history.push(clientPageUrls.clientList));
  }, [history]);
  return (
    <div className="d-flex">
      <div className="w-100" data-testid="ContentArea">
        <Switch>
          <Route
            exact
            path={clientPageUrls.clientList}
            component={ClientsPage}
          />
          <Route path={clientPageUrls.organic} component={Organic} />
          <Route path={clientPageUrls.overview} component={Overview} />
          <Route path={clientPageUrls.leads} component={Leads} />
          <Route path={clientPageUrls.campaigns} component={Campaigns} />
          <Route path={clientPageUrls.landing} component={Landing} />
          <Route path={clientPageUrls.leadsList} component={LeadsList} />
          <Route path={clientPageUrls.benchmarking} component={Benchmarking} />
          <Route path={clientPageUrls.seo} component={Seo} />
          <Route path={clientPageUrls.healthCheck} component={HealthCheck} />
        </Switch>
      </div>
    </div>
  );
}

function ApplicationWrapper({ children }) {
  const signInState = getLocalStorageValue('signInState');
  const history = useHistory();

  useEffect(() => {
    if (history && signInState) {
      setLocalStorageValue('signInState', null);
      history.push(`${signInState.pathname}${signInState.search}`);
    }
  }, [history, signInState]);

  return <>{children}</>;
}

ApplicationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

function AuthenticatedApp() {
  return (
      <Router>
        <ApplicationWrapper>
          <Header />
          <ContentArea />
        </ApplicationWrapper>
      </Router>
  );
}

export default AuthenticatedApp;
