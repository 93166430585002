import { getPercentageChange } from '../../utils/reporting';

/**
 * @param {number} currentValue - the new value
 * @param {number} prevValue - the value to be compared with
 * @param {bool} percentage - should the change be a percentage
 * @param {bool} increaseIsPositive - is an increase in the value a positive outcome?
 * @returns {[number, boolean, boolean]} [absoluteDifference, isIncrease, isPositive]
 */
const getComparisonData = (
  currentValue,
  prevValue,
  percentage,
  increaseIsPositive,
) => {
  const relevantDifference = percentage
    ? getPercentageChange(currentValue, prevValue)
    : prevValue - currentValue;

  const difference = !prevValue
    || !currentValue
    || Number.isNaN(relevantDifference)
    || relevantDifference === 0
    ? 0
    : relevantDifference;

  const isIncrease = difference > 0;
  const isPositive = (increaseIsPositive && isIncrease) || (!increaseIsPositive && !isIncrease);

  const absoluteDifference = difference && Math.abs(difference);

  return [absoluteDifference, isIncrease, isPositive];
};

export default getComparisonData;
