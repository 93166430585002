import React from 'react';
import PropTypes from 'prop-types';
import MType from '../../typography/MType';
import { limitStringLength } from 'resources/helpers/strings';
import { Tooltip } from 'components/common';

export const BoldMCell = ({ value }) => <MType light>{value}</MType>;

BoldMCell.propTypes = {
  value: PropTypes.node,
};
export const BoldMCellWithToolTip = ({ value }) => {
  if (value && value.length > 20) {
    return (
      <Tooltip content={value}>
        <MType light>{limitStringLength(value, 20)}</MType>
      </Tooltip>
    );
  }
  return <BoldMCell value={value}/>;
};

BoldMCellWithToolTip.propTypes = {
  value: PropTypes.string,
};

export const LightMCell = ({ value }) => <MType light>{value}</MType>;

LightMCell.propTypes = {
  value: PropTypes.node.isRequired,
};
