import React from 'react';
import PropTypes from 'prop-types';

const PopUpMenu = ({ open, children }) => (!open ? null : (
  <div data-testid="popup-menu-parent" className="position-relative fit-content">
    <div className="sm-upwards-triangle" />
    <div
      className="basic-shadow high-blur-shadow bg-white border-rd-3 position-absolute d-flex flex-column align-items-start popup-menu"
    >
      {children}
    </div>
  </div>
));

PopUpMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default PopUpMenu;
