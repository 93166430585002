import { format, parse } from 'date-fns';
import React from 'react';
import TenantPageBase from 'components/common/page-base/TenantPageBase';
import DateDisplay from 'components/DateDisplay';
import { InfoButton } from 'components/common/Buttons';
import Modal from 'components/common/Modal';
import DateRangePickerSecond from 'components/common/DateRangePicker';
import GroupBy from 'components/common/GroupBy';
import { TitleWithPhoto, Loader } from 'components/common';
import ComparisonPeriodSelector from 'components/common/ComparisonPeriodSelector';
import TotalRow from 'components/common/TotalRow';
import CampaignsTable from './CampaignsTable';
import useCampaignsHook from './hooks/useCampaignsHook';
import { SUPP_PREFIX } from './CampaignsTable/CampaignsColumns';
import useApplicationStateValue from 'utils/useApplicationStateValue';
import ComparisonLinesGraph from 'components/common/ComparisonLinesGraph';
import {
  groupedByOptions,
  comparisonPeriodOptions,
  graphComparisonPeriodOptions,
  allMetrics,
  allFacebookMetrics,
  allGoogleMetrics,
} from './constants';
import styles from './styles.module.scss';

const Campaigns = (props) => {
  const { location, history } = props;
  const {
    showModal,
    tableNames,
    disabledApplyButton,
    previousYear,
    previousPeriod,
    startDate,
    endDate,
    dates,
    comparisonPeriodCheckboxValues,
    groupedBy,
    data,
    isLoading,
    error,
    comparisonPeriod,
    graphData,
    tableColumns,
    nonGroupedData,
    getDataArray,
    handleDatePickerError,
    handleDatePickerErrorResolve,
    handleChangeGroupedBy,
    handleDateChange,
    handleCheckboxChange,
    onDatesApply,
    handleDateReset,
    handleClickInfoButton,
    closeModal,
    handlePeriodChange,
  } = useCampaignsHook({ location, history });

  const [graphTopMetrics, onSetGraphTopMetrics] = useApplicationStateValue(
    'campaignsGraphTopMetrics',
    'clicks,conversions',
  );

  const [graphBottomMetrics, onSetGraphBottomMetrics] =
    useApplicationStateValue(
      'campaignsGraphBottomMetrics',
      'clicks,conversions',
    );

  const onMetricChange = ({ topMetrics, bottomMetrics }) => {
    const newTopMetrics = topMetrics.map((m) => m.name).join(',');
    const newBottomMetrics = bottomMetrics.map((m) => m.name).join(',');
    onSetGraphTopMetrics(newTopMetrics);
    onSetGraphBottomMetrics(newBottomMetrics);
  };

  const [graphComparisonPeriod, setGraphComparisonPeriod] =
    useApplicationStateValue(
      'campaignsGraphComparisonPeriod',
      graphComparisonPeriodOptions[0].value,
    );

  const handlePeriodGraphChange = (selectedComparisonPeriod) => {
    if (selectedComparisonPeriod.value !== null) {
      setGraphComparisonPeriod(selectedComparisonPeriod.value);
    }
  };

  return (
    <>
      <TenantPageBase>
        <div data-testid="Campaigns" className="main">
          <div className={styles.configOptions}>
            <div>
              <InfoButton
                label={`${format(
                  parse(startDate, 'yyyy-MM-dd', new Date()),
                  'MMM dd, yyyy',
                )} - ${format(
                  parse(endDate, 'yyyy-MM-dd', new Date()),
                  'MMM dd, yyyy',
                )}`}
                onClick={handleClickInfoButton}
              />
              <DateDisplay
                previousPeriod={previousPeriod}
                previousYear={previousYear}
              />
            </div>
            <GroupBy
              className={styles.groupedByCampaigns}
              options={groupedByOptions}
              value={groupedBy}
              onChange={handleChangeGroupedBy}
            />
          </div>

          <Loader isLoading={isLoading} style={{ marginTop: '10rem' }}>
            {error && (
              <p className="text-danger">
                There has been an error while loading the data. Please contact
                your nearest Bacio person and hold on while we fix it.
              </p>
            )}
            <h2>Campaigns</h2>
            <ComparisonPeriodSelector
              className={styles.comparisonPeriodSelector}
              options={comparisonPeriodOptions}
              value={comparisonPeriodOptions.find(
                (x) => x.value === comparisonPeriod,
              )}
              onChange={(selectedComparePeriod) =>
                handlePeriodChange(selectedComparePeriod)
              }
            />
            {!error &&
                    (groupedBy.value !== 'None' ? (
                tableNames.map((key) => (
                  <div
                    className={styles.tableContainer}
                    key={`campaignsTable-${key}`}
                  >
                    <TitleWithPhoto title={key} icon={key} />
                    <TotalRow
                      data={data.totalRowsData}
                      index={key}
                      comparisonPeriod={comparisonPeriod}
                      groupedBy={groupedBy.value}
                      tableColumns={tableColumns}
                      comparisonPrefixes={SUPP_PREFIX}
                      nonTotalColumns={['adNetwork']}
                    />
                    <CampaignsTable
                      id={`table-${key.replace(' ', '')}`}
                      className={styles.campaignsTable}
                      data={getDataArray(data, key)}
                      rowsPerPage={5}
                      comparisonPeriod={comparisonPeriod}
                    />
                  </div>
                ))
              ) : (
                <>
                  <TotalRow
                    data={nonGroupedData.totalRowsData}
                    index="Campaigns"
                    comparisonPeriod={comparisonPeriod}
                    groupedBy="None"
                    tableColumns={tableColumns}
                    comparisonPrefixes="supportChange"
                    nonTotalColumns={['adNetwork']}
                  />
                  <CampaignsTable
                    id="table-Campaigns"
                    className={styles.campaignsTable}
                    data={nonGroupedData}
                    rowsPerPage={5}
                    comparisonPeriod={comparisonPeriod}
                  />
                </>
              ))}
            <div className={styles.detailedMetricsTitleContainer}>
              <h3 className={styles.detailedMetricsTitle}>Detailed Metrics</h3>
              <ComparisonPeriodSelector
                options={graphComparisonPeriodOptions}
                value={graphComparisonPeriodOptions.find(
                  (opt) => opt.value === graphComparisonPeriod,
                )}
                onChange={(selectedComparePeriod) =>
                  handlePeriodGraphChange(selectedComparePeriod)
                }
              />
            </div>
            {graphData.data ? (
              <ComparisonLinesGraph
                data={graphData.data.rows}
                topMetrics={graphTopMetrics.split(',')}
                bottomMetrics={graphBottomMetrics.split(',')}
                onMetricChange={onMetricChange}
                chooseMetricsValues={[
                  { title: 'All Campaigns', values: allMetrics },
                  { title: 'Facebook', values: allFacebookMetrics },
                  { title: 'Google', values: allGoogleMetrics },
                ]}
                allMetrics={[
                  ...allMetrics,
                  ...allFacebookMetrics,
                  ...allGoogleMetrics,
                ]}
                prevValue={graphComparisonPeriodOptions.find(
                  (opt) => opt.value === graphComparisonPeriod,
                )}
              />
            ) : null}
          </Loader>
        </div>
      </TenantPageBase>

      {showModal && (
        <Modal
          title="Select Date Range"
          onClose={closeModal}
          bottomLeftButtons={[
            {
              label: 'Cancel',
              onClick: closeModal,
            },
            { label: 'Reset', onClick: handleDateReset },
          ]}
          bottomRightButtons={[
            {
              label: 'Apply',
              onClick: onDatesApply,
              disabled: disabledApplyButton,
            },
          ]}
        >
          <DateRangePickerSecond
            startDate={dates.startDate}
            endDate={dates.endDate}
            onChange={handleDateChange}
            onChangeCheckbox={handleCheckboxChange}
            onError={handleDatePickerError}
            onErrorResolve={handleDatePickerErrorResolve}
            showPrevPeriod={comparisonPeriodCheckboxValues.showPrevPeriod}
            showPrevYear={comparisonPeriodCheckboxValues.showPrevYear}
          />
        </Modal>
      )}
    </>
  );
};

export default Campaigns;
