const usingMouseHandler = () => {
  document.body.classList.add('using-mouse');

  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse');
  });

  document.body.addEventListener('keydown', (event) => {
    if (event.key === 'Tab') {
      document.body.classList.remove('using-mouse');
    }
  });
};

export default usingMouseHandler;
