import React from 'react';
import PropTypes from 'prop-types';
import SType from '../../typography/SType';
import XSType from '../../typography/XSType';
import ChangeIndicator from '../ChangeIndicator';

const ComparisonRow = ({
  title, data, change, increase, positive, isDisplayed,
}) => (
  isDisplayed && (
    <tr data-testid="comparison-row" className="comparison-row">
      <td className="w-100">
        <XSType className="text-capitalize">{title}</XSType>
      </td>
      <td className="text-secondary pr-2">
        <SType light>{data || '-'}</SType>
      </td>
      <ChangeIndicator
        value={change}
        increase={increase}
        positive={positive}
      />
    </tr>
  )
);

ComparisonRow.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  change: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  increase: PropTypes.bool,
  positive: PropTypes.bool,
  isDisplayed: PropTypes.bool,
};

ComparisonRow.defaultProps = {
  increase: false,
  positive: false,
  isDisplayed: true,
};

export default ComparisonRow;
