function getFilterSuggestionsFromList(
  lowercaseInput,
  handlerValue,
  activeFilters,
  filterObj,
  suggestionArray,
) {
  const foundFilterValueIndex = handlerValue
    .map((x) => x.toLowerCase())
    .findIndex((value) => value.includes(lowercaseInput));

  if (
    foundFilterValueIndex >= 0
    && !suggestionArray.find(
      (suggestion) => suggestion.value === handlerValue[foundFilterValueIndex],
    )
    && !activeFilters.find(
      (filter) => filter.value === handlerValue[foundFilterValueIndex]
        && filter.name === filterObj.name,
    )
  ) {
    suggestionArray.push({
      value: handlerValue[foundFilterValueIndex],
      filterObj,
      id: handlerValue[foundFilterValueIndex],
    });
  }
  return suggestionArray;
}

function getFilterSuggestionsFromString(
  lowercaseInput,
  handlerValue,
  activeFilters,
  filterObj,
  suggestionArray,
  row,
) {
  if (
    handlerValue
    && handlerValue.toLowerCase().includes(lowercaseInput)
    && !suggestionArray.find((suggestion) => suggestion.value === handlerValue
    && suggestion.filterObj.name === filterObj.name)
    && !activeFilters.find(
      (filter) => filter.value === handlerValue && filter.name === filterObj.name,
    )
  ) {
    suggestionArray.push({
      value: handlerValue,
      filterObj,
      id: filterObj.getId(row),
    });
  }
  return suggestionArray;
}

function getFilteredTenantList(relevantValues, filterHandlerRow) {
  let foundFilterValue = '';
  if (Array.isArray(filterHandlerRow)) {
    const arrayLength = filterHandlerRow.length;
    for (let i = 0; i < arrayLength; i += 1) {
      if (relevantValues.includes(filterHandlerRow[i])) {
        foundFilterValue = filterHandlerRow[i];
        break;
      }
    }
    return foundFilterValue;
  }
  return relevantValues.includes(filterHandlerRow);
}

export {
  getFilterSuggestionsFromList,
  getFilterSuggestionsFromString,
  getFilteredTenantList,
};
