import {
  formatYear,
  getPreviousYearDate,
  sortBy,
} from '../../utils/reporting';

export default function formatBarChartData(
  data,
  excludeColumns = [],
  groupBy,
) {
  const period = 'prevYear';
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  const periodKey = (periodType, key) => `${periodType}${capitalizeFirstLetter(key)}`;
  const comparisonData = [];
  const yearsProcessed = [];
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      const item = { ...data[i] };
      const curYear = formatYear(item[groupBy]);
      const prevYear = formatYear(getPreviousYearDate(item[groupBy]));
      const newItem = {};
      Object.keys(item).forEach((key) => {
        if (!excludeColumns.includes(key) && !key.includes('prev')) {
          newItem[groupBy] = item[groupBy];
          newItem[`${key}${curYear}`] = item[key];
          newItem[`${key}${prevYear}`] = item[periodKey(period, key)];
        }
      });
      if (!yearsProcessed.includes(curYear)) {
        yearsProcessed.push(curYear);
      }
      if (!yearsProcessed.includes(prevYear)) {
        yearsProcessed.push(prevYear);
      }
      comparisonData[item[groupBy]] = newItem;
    }
  }
  const finalData = Object.keys(comparisonData).map((key) => comparisonData[key]);
  finalData.sort(sortBy('dateGroup'));
  return { data: finalData, bars: yearsProcessed.sort() };
}
