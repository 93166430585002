import React from 'react';
import CampaignsListRow from './CampaignsListRow';
import BeaconTable from 'components/common/BeaconTable';
import useCampaignsTable from './hooks/useCampaignsTable.jsx';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const CampaignsTable = ({
  data,
  removeColumn = '',
  rowsPerPage = 10,
  id = '',
  comparisonPeriod,
}) => {
  const { headers, table } = useCampaignsTable({
    data,
    removeColumn,
    rowsPerPage,
    comparisonPeriod,
  });

  return (
    <BeaconTable
      Row={CampaignsListRow}
      rowsPerPage={rowsPerPage}
      headers={headers}
      table={table}
      className={styles.campaignsTable}
      id={id}
    />
  );
};

CampaignsTable.propTypes = {
  data: PropTypes.object,
  removeColumn: PropTypes.string,
  id: PropTypes.string,
  rowsPerPage: PropTypes.number,
  comparisonPeriod: PropTypes.string
};

export default CampaignsTable;
