import React from 'react';
import PropTypes from 'prop-types';

const Triangle = ({
  direction, height, width, color, className,
}) => {
  const up = direction === 'up';
  const style = {
    borderLeft: `${width / 2}px solid transparent`,
    borderRight: `${width / 2}px solid transparent`,
    borderTop: `${!up ? height : 0}px solid ${color}`,
    borderBottom: `${up ? height : 0}px solid ${color}`,
    margin: 'auto 3px',
  };

  return (
    <div
      data-testid={`triangle-${direction}`}
      className={className}
      style={style}
    />
  );
};

Triangle.defaultProps = {
  direction: 'up',
  color: '#777',
  className: undefined,
  height: 6,
  width: 8,
};

Triangle.propTypes = {
  direction: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Triangle;
