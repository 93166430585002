const getLocalStorageValue = (key) => {
  const value = localStorage.getItem(key);

  return value ? JSON.parse(value) : null;
};

const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export { getLocalStorageValue, setLocalStorageValue };
