import React from 'react';
import PropTypes from 'prop-types';
import SType from '../../typography/SType';
import smallX from '../../../../resources/assets/small-x.svg';
import XSType from '../../typography/XSType';

const CurrentFilterItem = ({
  filter, removeFromFilter,
}) => (
  <div
    data-testid="suggestion-active-filter"
    className="bg-primary text-white center-children pl-3 pr-1 txt-sz-13 sm-pill fit-content mr-2 mb-2"
  >
    <XSType
      className={'center-children pr-2 mr-2 \
              h-75 text-uppercase border-white-transparent \
              border-top-0 border-left-0 border-bottom-0'}
    >
      {filter.name}
    </XSType>
    <SType light>
      {filter.value}
    </SType>
    <button
      type="button"
      className="border-0 bg-primary pointer ml-1 p-0 rounded-circle"
      onClick={() => removeFromFilter(filter.value)}
    >
      <img src={smallX} alt="close button" className="rounded-circle" />
    </button>
  </div>
);

CurrentFilterItem.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  removeFromFilter: PropTypes.func.isRequired,
};

export default CurrentFilterItem;
