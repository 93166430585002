import React from 'react';
import PropTypes from 'prop-types';

function GoogleDisplay(props) {
  const { onClick = () => {}, className = '' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="23"
      viewBox="0 0 18 23"
      onClick={onClick}
      data-testid="GoogleDisplay"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#4285F4"
              fillRule="nonzero"
              d="M8.925 12.593L16.349 20.018 14.228 22.139 8.924 16.836 3.621 22.139 1.5 20.018z"
              transform="translate(-135.000000, -1298.000000) translate(135.000000, 1298.000000)"
            />
            <path
              fill="#EB4335"
              fillRule="nonzero"
              d="M18 7L15 7 15 3 3 3 0 0 18 0z"
              transform="translate(-135.000000, -1298.000000) translate(135.000000, 1298.000000)"
            />
            <path
              fill="#F4B603"
              d="M0 0L3 3 3 12 9 12 9 15 0 15z"
              transform="translate(-135.000000, -1298.000000) translate(135.000000, 1298.000000)"
            />
            <path
              fill="#32A450"
              d="M9 7L12 7 12 12 18 12 18 15 9 15z"
              transform="translate(-135.000000, -1298.000000) translate(135.000000, 1298.000000) translate(13.500000, 11.000000) scale(-1, 1) translate(-13.500000, -11.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

GoogleDisplay.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export { GoogleDisplay };
