import { campaignsActionTypes } from '../actions/ActionTypes';

const initialState = {
  campaignsReport: [],
  errorMessage: '',
};
// here
const campaignsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case campaignsActionTypes.fetchAllError:
      return { ...state, errorMessage: payload };
    case campaignsActionTypes.fetchCampaignsSuccess:
      return {
        ...state,
        campaignsReport: {
          ...state.campaignsReport,
          [payload.id]: {
            ...state.campaignsReport[payload.id],
            [payload.dimensions]: payload,
          },
        },
      };
    case campaignsActionTypes.fetchCampaign:
      return { ...state, [payload.id]: payload };
    case campaignsActionTypes.fetchCampaignsError:
      return { ...state, errorMessage: payload };

    default:
      return state;
  }
};

export default campaignsReducer;
