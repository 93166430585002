import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

function SecondaryButton({ onClick, label }) {
  return (
    <button
      className={styles.secondaryButton}
      onClick={onClick}
      data-testid="secondary-button"
    >
      {label}
    </button>
  );
}

SecondaryButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export { SecondaryButton };
