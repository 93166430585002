import React from 'react';
import { ReactComponent as BeaconLogo } from 'logo.svg';
import styles from './styles.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className={styles.erroBoundaryContainer}>
          <div className={styles.logoContainer}>
            <BeaconLogo />
          </div>
          <h2>Something went wrong.</h2>
          <h2>Please send a screenshot to Beacon's devs</h2>
          {this.state.error && this.state.error.toString()}
          <br />
          {this.state.errorInfo.componentStack}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
