import React from 'react';
import PropTypes from 'prop-types';

const SType = ({ children, className, light }) => (
  <div className={`txt-sz-s ${light ? 'avenir-medium' : 'avenir-heavy'} ${className}`}>
    {children}
  </div>
);

SType.defaultProps = {
  className: '',
  light: false,
};

SType.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  light: PropTypes.bool,
};

export default SType;
