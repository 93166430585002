import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Loader({ isLoading, style = {}, children }) {
  if (isLoading) {
    return (
      <div className={styles.spinnerContainer} style={style}>
        <Spinner animation="border" />
      </div>
    );
  }
  return children;
}

Loader.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default Loader;
