import {
  error, loading, notStarted, success,
} from '../reducers/StatusReducer';

export const statusSelector = (action) => (state) => state.status[action];

export const hasStatusSelector = (action, status) => (state) => state.status[action] === status;

export const notStartedSelector = (actions) => (state) => actions.reduce((prevState, value) => {
  const status = state.status[value] || notStarted;
  return prevState && status === notStarted;
}, true);

export const isLoadingSelector = (actions) => (state) => actions.reduce((prevState, value) => {
  const status = state.status[value] || notStarted;
  return prevState || status === loading;
}, false);

export const hasErrorsSelector = (actions) => (state) => actions.reduce((prevState, value) => {
  const status = state.status[value] || notStarted;
  return prevState || status === error;
}, false);

export const successSelector = (actions) => (state) => actions.reduce((prevState, value) => {
  const status = state.status[value] || notStarted;
  return prevState && status === success;
}, true);

export const fullStatusSelector = (action) => (state) => {
  const status = state.status[action];
  const errorLoc = state.error[action];
  const isLoading = status === loading;
  return { status, isLoading, error: errorLoc };
};
