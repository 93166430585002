import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const BasicTableRow = ({ rowComponents, to, className }) => {
  const history = useHistory();

  const handleClick = () => {
    if (to) {
      window.scrollTo(0, 0);
      history.push(to);
    }
  };

  return (
    <tr
      className={className}
      data-testid="basic-row-parent"
      onClick={handleClick}
    >
      {rowComponents.map((column) => (
        <td
          key={`BasicTableRow${column ? column.key : 'undefined'}`}
          data-testid={`basic-row-column-${column ? column.key : 'undefined'}`}
        >
          {column}
        </td>
      ))}
    </tr>
  );
};

BasicTableRow.defaultProps = {
  to: undefined,
  className: undefined,
};

BasicTableRow.propTypes = {
  rowComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
};

export default BasicTableRow;
