import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const MType = ({ children, className, light }) => (
  <div className={`txt-sz-m ${light ? 'avenir-medium' : 'avenir-heavy'} ${className} ${styles.mtype}`}>
    {children}
  </div>
);

MType.defaultProps = {
  className: '',
  light: false,
};

MType.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  light: PropTypes.bool,
};

export default MType;
