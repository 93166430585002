import React from 'react';
import PropTypes from 'prop-types';

function CloseIcon(props) {
  const { onClick = () => {}, stroke = '#606060', className = '' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke={stroke}
      onClick={onClick}
      data-testid={props['data-testid']}
      className={className}
    >
      <g fillRule="evenodd">
        <g transform="translate(-989 -155)">
          <g stroke="inherit" strokeWidth="2">
            <path d="M6 6L18 18" transform="translate(989 155)" />
            <path
              d="M6 6L18 18"
              transform="translate(989 155) matrix(-1 0 0 1 24 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

CloseIcon.propTypes = {
  onClick: PropTypes.func,
  'data-testid': PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
};

export { CloseIcon };
