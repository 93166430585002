import React from 'react';
import PropTypes from 'prop-types';

const XXLType = ({ children, className, light }) => (
  <h1 className={`txt-sz-xxl ${light ? 'avenir-light' : 'avenir-heavy'} ${className}`}>{children}</h1>
);

XXLType.defaultProps = {
  className: '',
  light: false,
};

XXLType.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  light: PropTypes.bool,
};

export default XXLType;
