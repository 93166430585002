import React from 'react';
import Select from 'components/common/Select';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function GroupBy({ className = '', options, value, onChange }) {
  return (
    <div className={cx(styles.groupByContainer, className)}>
      <span>Group By:</span>
      <Select
        className={styles.groupBySelect}
        options={options}
        value={value}
        data-testid="groupBy-select"
        onChange={onChange}
      />
    </div>
  );
}

GroupBy.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default GroupBy;
