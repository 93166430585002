import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/Checkbox';
import styles from './styles.module.scss';

function CheckboxWithLabel({ onChange, isChecked, label, id, disabled, checked, ...rest }) {
  return (
    <label
      className={styles.container}
      htmlFor={id}
      data-testid={rest['data-testid']}
    >
      <Checkbox
        id={id}
        defaultChecked={isChecked}
        onChange={(e) => onChange(e)}
        className={styles.checkbox}
        disabled={disabled}
        checked={checked}
      />
      <span className={styles.label}>{label}</span>
    </label>
  );
}

CheckboxWithLabel.propTypes = {
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default CheckboxWithLabel;
