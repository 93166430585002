const reportKey = {
  facebookAdsCampaignPerformance: 'facebook-ads-campaign-performance',
};

export const facebookAdReqTypes = {
  facebookAdsCampaignPerformance: {
    base: reportKey.facebookAdsCampaignPerformance,
  },
};

export default facebookAdReqTypes;
