import React from 'react';
import PropTypes from 'prop-types';

function HorizontalTripleBars(props) {
  const { onClick = () => {}, className = '', fill = 'white' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 448 512"
      onClick={onClick}
      data-testid="HorizontalTripleBars"
      className={className}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="bars"
      role="img"
    >
      <path
        fill={fill}
        d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
      ></path>
    </svg>
  );
}

HorizontalTripleBars.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export { HorizontalTripleBars };
